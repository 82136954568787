import React, { useState, useEffect , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import useForm from '../../utils/useForm';
import { Errors } from '../../App.js';
import ConfirmationDialog from '../../utils/ConfirmationDialog';

const initialFormData = {
  password: '',
  newPassword: '',
  verifyPassword: ''
};

const useStyles = makeStyles(_ => ({
  delete: {
    backgroundColor: '#f74969 !important',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191 !important'
    },
  },
}));

const DeleteAccountCard = ({ userType }) => {
  const classes = useStyles();
  const { setErrors } = useContext(Errors);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData, setFormDataInit] = useForm(initialFormData);

  useEffect(() => {
    const userT = (userType === 'super-admin') ? 'admin' : userType;

    return effectFetch(
      `/api/${userT}/profile`,
      json => setFormDataInit(pr => ({
        ...(json.data || pr),
        ...initialFormData
      })),
      err => console.log(err)
    );

  }, [ userType ]);

  const handleSubmit = () => {
    const userT = (userType === 'super-admin') ? 'admin' : userType;

    authService.authFetch(`/api/${userT}/profile`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: formData.password,
      })
    })
      .then(_ => {
        window.location.reload();
        setFormDataInit(initialFormData);
      })
      .catch(err => setErrors([err]))
  };

  return (
    <div>
      <ConfirmationDialog
        text={ "Deleting your account is permanent." }
        handleSubmit={ handleSubmit }
        isOpen={ isDialogOpen }
        handleClose={ _ => setIsDialogOpen(false) }
      />

      <div>
        <h3>Delete Account</h3>

        Delete your account PERMANENTLY. If you are the super admin everything
        related to your franchise will be deleted. Only proceed if you are 100%
        sure you want to do this.
      </div>

      <Card>
        <CardContent>
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            value={ formData.password }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />

          <Button
            fullWidth
            className={ classes.delete }
            variant="contained"
            onClick={ _ => setIsDialogOpen(true) }
          >
            Delete Account
          </Button>
        </CardContent>
      </Card>
    </div>
  )
};

export default DeleteAccountCard;
