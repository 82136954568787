import jwt_decode from 'jwt-decode';
import { changeWeekStart } from './helpers';

const SILENT_REFRESH_OFFSET = 8000;
let inMemoryToken;

const silentRefresh = async () => {
  try {
    const url = ((process.env.NODE_ENV === 'production') ? '' : 'http://localhost:5000')
      + '/api/refresh/access-token';

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include'
    });

    const json = await response.json();

    if(json.success) {
      inMemoryToken = json.accessToken;

      const decoded = jwt_decode(inMemoryToken);

      // After how many miliseconds the token expires - offset
      const expiresIn = decoded.exp * 1000 - Date.now() - SILENT_REFRESH_OFFSET;
      setTimeout(silentRefresh, expiresIn);

      return;
    } else {
      throw json;
    }
  } catch (err) {
    console.log(err);
  }
};

const logout = async () => {
  try {
    const response = await authFetch('/api/logout', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });

    const json = await response.json();
    inMemoryToken = null;

    window.localStorage.setItem('logout', Date.now());

    if(json.success) {
      return;
    } else {
      throw json;
    }
  } catch(err) {
    throw new Error(err);
  }
};

// Fetch with token in headers
const authFetch = async (url, options) => {
  try {
    let fetchOptions = {
      headers: {
        ...(options?.headers),
        'Authorization': inMemoryToken,
      }
    };

    if(options) {
      fetchOptions = {
        ...options,
        ...fetchOptions,
      };
    }

    const prefix = (process.env.NODE_ENV === 'production') ?
      '' : 'http://localhost:5000';
    const response = await fetch(prefix + url, fetchOptions);

    // Logout if unauthorized
    if(response.status === 401) {
      throw new Error("Unauthorized");
    }

    if(!response.ok && !response.success) {
      throw await response.json();
    }

    return response;
  } catch (err) {
    throw new Error(err);
  }
};

const getTokenAfterRequest = async (path, userData, userEntity) => {
  try {
    const response = await fetch(`/api/${userEntity}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData)
    });

    const json = await response.json();

    if(json.success) {
      inMemoryToken = json.accessToken;

      const decoded = jwt_decode(inMemoryToken);

      // After how many miliseconds the token expires - 8 seconds
      const expiresIn = decoded.exp * 1000 - Date.now() - SILENT_REFRESH_OFFSET;
      setTimeout(silentRefresh, expiresIn);

      return;
    } else {
      throw json;
    }
  } catch (err) {
    throw new Error(err);
  }
};

const authService = {
  silentRefresh,
  authFetch,
  logout,

  loginUser: async (userData, userEntity) =>
    getTokenAfterRequest('login', userData, userEntity),

  signupUser: async (userData) =>
    getTokenAfterRequest('register', userData, 'super-admin'),

  getToken: () => {
    try {
      return jwt_decode(inMemoryToken);
    } catch (err) {
      return null;
    }
  },

  setToken: (token) => {
    inMemoryToken = token;

    const decoded = jwt_decode(token);
    changeWeekStart(decoded.weekStart);

    // After how many miliseconds the token expires - offset
    const expiresIn = decoded.exp * 1000 - Date.now() - SILENT_REFRESH_OFFSET;
    setTimeout(silentRefresh, expiresIn);
  },

  isTokenExpired: (token) => {
    try {
      const decoded = jwt_decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      }
      else
        return false;
    }
    catch (err) {
      return false;
    }
  },

  isAuthenticated: async (userType) => {
    const isTokenValid = (token) => {
      // Check if token exists
      if(!token) {
        return false;
      }

      // Check if token is a valid token
      try {
        const decoded = jwt_decode(token);

        // If expecting super-admin and get not super admin or if expecting not
        // super admin but we get super admin return false
        if((userType === 'super-admin') !== decoded.isSuperAdmin) {
          return false;
        }

        // If userType not null and not super-admin check if user is of userType
        if(!decoded.isSuperAdmin && userType && decoded.type !== userType) {
          return false;
        }

        return !authService.isTokenExpired(token);
      } catch (err) {
        return false;
      }
    };

    const token = inMemoryToken;

    // Check access token
    if(isTokenValid(token)) {
      return true;
    }

    // Try to get new access token
    try {
      await silentRefresh();
      return isTokenValid(inMemoryToken);
    } catch(err) {
      return false;
    }

  }
};

export default authService;
