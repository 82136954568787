import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemIcon, Drawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    background: '#1a263a',
    padding: '1rem',
    width: '12rem'
  },
  drawerOpen: {
    width: '13rem',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '3.5rem'
  },
  drawerHeader: {
    color: '#7c838e',
    '&:hover': {
      color: 'white'
    },
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    color: 'white',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#3f4c60',
    },
  }
}));

const MyDrawer = ({ links }) => {
  const classes = useStyles();
  const [open, setOpen] = useState((localStorage.getItem('isDrawerOpen') === "true") || false);

  useEffect(() => {
    if(localStorage.getItem('isDrawerOpen') === null) {
      localStorage.setItem('isDrawerOpen', false);
      return;
    }
  }, [])

  const handleDrawerToggle = () => {
    localStorage.setItem('isDrawerOpen', !open);
    setOpen(!open);
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })
      }}
    >
      <div
        className={ classes.drawerHeader }
        onClick={ handleDrawerToggle }
      >
        <IconButton color="inherit">
          { open ? <MenuOpenIcon fontSize="large" /> : <MenuIcon fontSize="large" /> }
        </IconButton>
      </div>
      <List>
        {
          links.map((a, i) => (!a.hide &&
            <Link
              className={ classes.link }
              key={ i }
              to={ a.path }
            >
              <ListItem className={ classes.button } button key={ i }>
                <ListItemIcon>
                  { a.icon }
                </ListItemIcon>
                <ListItemText>{ a.name }</ListItemText>
              </ListItem>
            </Link>
          ))
        }
      </List>
    </Drawer>
  )
};

export default MyDrawer;
