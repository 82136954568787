import React, { useState , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import authService from '../../utils/authService';
import currencyFormatter from 'currency-formatter';
import { Errors } from '../../App.js';

const buttonStyle = {
  padding: '.5rem 2rem',
};

const useStyles = makeStyles(() => ({
  input: {
    margin: '1rem',
    width: '90%',
  },
  label: {
    margin: '0 1rem -.75rem 1rem',
    fontSize: '.80rem',
    width: '90%',
  },
  button: {
    backgroundColor: 'white',
    ...buttonStyle
  },
  primaryButton: {
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
    ...buttonStyle
  }
}));

const availableReasons = ['New Stock', 'Return', 'Transfer', 'Adjustment', 'Other'];

const ChangeStockForm = ({
  selectedSaloon,
  userType,
  product,
  actionStr,
  handleClose,
  updateHandler,
  toBaseCurrency
}) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [quantity, setQuantity] = useState(0);
  const [supplyPrice, setSupplyPrice] = useState(0);
  const [reason, setReason] = useState(availableReasons[3]);

  const handleSubmit = () => {
    authService.authFetch(`/api/${userType}/products/stock`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        product,
        saloon: selectedSaloon.id,
        quantity: ((actionStr === 'Increase') ? 1 : -1) * Math.abs(quantity),
        supplyPrice: toBaseCurrency(supplyPrice),
        reason: reason
      })
    }).then(() => {
      handleClose();
      updateHandler();
    }).catch(err => setErrors([err]));
  };

  return (
    <>
      <DialogContent>
        {selectedSaloon.stock} units currently in stock at {selectedSaloon.name}.
        <br />

        <TextField
          className={ classes.input }
          label={ actionStr + ' qty.' }
          value={ quantity }
          variant="outlined"
          type="number"
          onChange={ e => setQuantity(e.target.value) }
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />

        { (actionStr === 'Increase') &&
          <TextField
            className={ classes.input }
            label="Supply price"
            value={ supplyPrice }
            variant="outlined"
            type="number"
            onChange={ e => setSupplyPrice(e.target.value) }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: currencyFormatter.findCurrency(authService.getToken().currency).symbol
            }}
          />
        }

        <InputLabel className={ classes.label } htmlFor="reason">Reason</InputLabel>
        <Select
          variant="outlined"
          native
          className={ classes.input }
          inputProps={{
            name: 'reason',
            id: 'reason',
          }}
          value={ reason.replace(/:.*/, '') }
          onChange={ e => setReason(e.target.value)}
        >
          {
            availableReasons.map((a, i) => (
              <option key={i} value={ a }>{ a }</option>
            ))
          }
        </Select>

        { reason.search('Other') === 0 &&
          <TextField
            className={ classes.input }
            label="Reason"
            placeholder="Specify reason"
            value={ reason.replace(/^Other(: |)/, '') }
            variant="outlined"
            onChange={ e => setReason('Other: ' + e.target.value) }
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
        <br />
      </DialogContent>

      <DialogActions>
        <Button
          className={ classes.button }
          onClick={ handleClose }
        >
          Cancel
        </Button>

        <Button
          className={ classes.primaryButton }
          variant="contained"
          onClick={ handleSubmit }
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
};

export default ChangeStockForm;
