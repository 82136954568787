import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { capitalizeFirst } from '../../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '16rem',
    textAlign: 'center',
    padding: '1rem',
    '& > * + *': {
      marginTop: '1rem',
      textAlign: 'left',
    }
  },
  select: {
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > button': {
      width: '45%',
      padding: '.5rem',
      backgroundColor: 'white',
      '& + button': {
        backgroundColor: '#1a263a',
        color: 'white',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.6)'
        }
      }
    }
  }
}));

const getInitialObj = (obj) => {
  return Object.keys(obj).reduce((pr, cur) => {
    pr[cur] = obj[cur][0]
    return pr;
  }, {})
};

const FilterDrawer = ({ userType, open, handleChange, availableOptions }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(getInitialObj(availableOptions));

  useEffect(() => {
    setFilters(getInitialObj(availableOptions));
    // Only update when number of items changes
  }, [ Object.keys(availableOptions).reduce((pr, cur) => pr + availableOptions[cur].length, 0) ])

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      anchor="right"
      open={ open }
      onClose={ _ => {
        handleChange(null);
      }}
    >
      <div>
        <Typography variant="h5">Filters</Typography>
      </div>
      <Divider />

      { Object.keys(filters).map(filter => {
        // Don't show saloon filter for non super-admins
        if(filter === 'saloon' && userType !== 'super-admin') {
          return null;
        }

        return (
          <div key={filter}>
            <InputLabel htmlFor={ filter }>
              { capitalizeFirst(filter) }
            </InputLabel>
            <Select
              className={ classes.select }
              margin="dense"
              variant="outlined"
              native
              value={ filters[filter]?.index }
              onChange={ e => {
                setFilters({
                  ...filters,
                  [filter]: availableOptions[filter][e.target.value]
                });
              }}
              inputProps={{
                name: filter,
                id: filter,
              }}
            >
              {
                availableOptions[filter].map((a, i) => (
                  <option key={i} value={ i }>{ a.name }</option>
                ))
              }
            </Select>
          </div>
        );
      })}

      <div className={ classes.buttonContainer }>
        <Button
          variant="contained"
          onClick={ _ => {
            // Reset values
            const newFilters = getInitialObj(availableOptions);
            setFilters(newFilters);

            // Get only the id of the filters
            const proccessedFilters = Object.keys(newFilters).reduce((pr, cur) => {
              pr[cur] = newFilters[cur].id;
              return pr;
            }, {});

            handleChange(proccessedFilters);
          }}
        >
          Clear All
        </Button>
        <Button
          variant="contained"
          onClick={ _ => {
            // Get only the id of the filters
            const proccessedFilters = Object.keys(filters).reduce((pr, cur) => {
              pr[cur] = filters[cur].id;
              return pr;
            }, {});

            handleChange(proccessedFilters);
          }}
        >
          Apply
        </Button>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
