import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import Select from '@material-ui/core/Select';
import DatePicker from '../calendar/DatePicker';
import Grid from './staffWorkingHoursTab/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  select: {
    background: 'white',
    margin: '1rem'
  },
  wrapper: {
    padding: '1rem'
  }
}));

const StaffWorkingHoursTab = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [selectedSaloon, setSelectedSaloon] = useState({ id: 0, name: '', index: 0 });
  const [date, setDate] = useState(moment());

  // Fetch availableSaloons
  useEffect(() => {
    if(userType !== 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        const avSaloons = (json.data || [])
          .map((a, i) => ({
            name: a.name,
            id: a.saloon_id,
            index: i
          }));

        setAvailableSaloons(avSaloons);

        if(avSaloons.length > 0) {
          setSelectedSaloon(avSaloons[0]);
        }
      },
      err => console.log(err)
    );
  }, [ userType ]);

  return (
    <div className={ classes.wrapper }>
      { (userType === 'super-admin' && availableSaloons.length > 0) &&
        <>
          <Select
            className={ classes.select }
            margin="dense"
            variant="outlined"
            native
            value={ selectedSaloon.index }
            onChange={ e => setSelectedSaloon(availableSaloons[e.target.value]) }
            inputProps={{
              name: 'saloon',
              id: 'saloon',
            }}
          >
            {
              availableSaloons.map((a, i) => (
                <option key={i} value={ i }>{ a.name }</option>
              ))
            }
          </Select>
        </>
      }

      <DatePicker
        type="week"
        dates={{ start: date, end: moment(date).endOf('week') }}
        onChange={ dates => setDate(dates.start) }
      />

      <Grid
        userType={ userType }
        saloon={ selectedSaloon }
        date={ date }
      />
    </div>
  );
};

export default StaffWorkingHoursTab;
