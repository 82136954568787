import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import CardAction from './CardAction';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { daysBetween, getShortDay, capitalizeFirst, effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

const CustomTooltip = (props) => {
  const ulStyle = {
    listStyle: 'none',
    paddingLeft: 0
  };

  if(props.active) {
    return (
      <div style={ props.contentStyle }>
        <ul style={ ulStyle }>
          <li>{ props.labelFormatter(props.label) }</li>
          <li>Completed Appointments: { props.payload?.[0].payload.num }</li>
          <li>Appointment Sale Total: { props.formatCurrency(props.payload?.[0].value) }</li>
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

const graphHeight = 315;
const useStyles = makeStyles(() => ({
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '560px',
    overflow: 'auto'
  },
  p: {
    fontSize: '.9rem',
    '& > span': {
      '&:nth-child(1)': {
        color: '#7c7c7c'
      },
      '&:nth-child(2)': {
        fontWeight: 500,
        marginLeft: '1rem'
      }
    }
  }
}));

const processData = (data, period) => {
  let appointmentNum = 0, totalAppointments = 0;

  data = data.map(a => {
    appointmentNum += a.num;
    totalAppointments += a.total;

    return { ...a, appointments: a.total };
  })

  return { data, appointmentNum, totalAppointments };
};

const RecentSalesCard = ({ userType, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [actionState, setActionState] = useState({
    open: false,
    period: 7,
    location: { name: 'All locations', id: -1, index: -1 }
  });
  const [graphData, setGraphData] = useState({
    data: [],
    totalAppointments: 0,
    totalSales: 0,
    appointmentNum: 0
  });

  const setOpen = (open) => {
    setActionState({
      ...actionState,
      open
    });
  };

  const handleActionChange = (period, location) => {
    setActionState({
      open: false,
      period,
      location
    });
  };

  // Fetch data
  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const target = moment().subtract(actionState.period - 1, 'days').format('YYYY-MM-DD');
    const allLocations = actionState.location.id === -1;
    const saloonQuery = allLocations ? '' : ('&saloon=' + actionState.location.id);

    const url = `/api/${userType}/appointments/graph/recent-sales?startDate=${target}&endDate=${today}${saloonQuery}`;

    return effectFetch(
      url,
      json => setGraphData(processData(json.data, actionState.period) || []),
      err => console.log(err)
    );
  }, [ actionState.period, actionState.location, userType ]);

  return (
    <Card className={ classes.card }>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            ref={ anchorEl }
            onClick={ _ => setOpen(!actionState.open) }
          >
            <MoreHorizIcon />
          </IconButton>
        }
        title="Recent Sales"
        subheader={ `${actionState.location.name}, last ${actionState.period} days` }
      />
      <CardContent>

        <CardAction
          userType={ userType }
          anchorEl={ anchorEl.current }
          state={ actionState }
          handleChange={ handleActionChange }
          handleClose={ _ => setOpen(false) }
          isForPast={ true }
          hasPeriodSelect
        />

        <Typography variant="h4">{ formatCurrency(graphData.totalAppointments) }</Typography>
        <p className={ classes.p }><span>Appointments</span> <span>{ graphData.appointmentNum }</span></p>

        <ResponsiveContainer height={graphHeight} width="95%">
          <LineChart
            data={ graphData.data }
            margin={{ top: 0, right: 0, left: 40, bottom: 0 }}
          >
            <XAxis
              tickFormatter={ (tick) => {
                const date = new Date(tick);
                return `${getShortDay(date)} ${date.getDate()}`
              } }
              stroke="#777"
              dataKey="date"
              axisLine={false}
              interval={ actionState.period === 7 ? 0 : 2 }
              angle={ -45 }
              textAnchor="end"
            />
            <YAxis
              tickFormatter={ (tick) => formatCurrency(tick) }
              stroke="#777"
              axisLine={false}
            />
            <CartesianGrid stroke="#eef0f2"/>
            <Tooltip
              labelFormatter={ (label) => moment(label).format('DD/MM/YYYY') }
              formatter={ (value, name) => [ formatCurrency(value), capitalizeFirst(name) ] }
              contentStyle={{
                backgroundColor: 'rgba(57, 67, 74, .7)',
                color: 'white',
                padding: '1px 20px',
              }}
              itemStyle={{ color: 'white' }}
              content={ <CustomTooltip formatCurrency={ formatCurrency } /> }
            />
            <Legend
              wrapperStyle={{ top: graphHeight }}
              formatter={ (value) => capitalizeFirst(value) }
            />
            <Line
              dataKey="appointments"
              stroke="#0bc2b0"
              strokeWidth="2"
              dot={{ fill: "#0bc2b0", stroke: "#0bc2b0" }}
              activeDot={{ fill: "white", stroke: "#0bc2b0" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
};

export default RecentSalesCard;
