import React, { useEffect , useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import useForm from '../../utils/useForm';
import { Errors, Success } from '../../App.js';

const useStyles = makeStyles(() => ({
  image: {
    height: 96,
    width: 96,
    margin: 'auto'
  },
}));

const PersonalDetailsCard = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const classes = useStyles();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [formData, setFormData, setFormDataInit] = useForm({
    name: '',
    photo: '',
    photoUrl: '',
    automaticAppointmentConfirmation: false
  });

  const userT = (userType === 'super-admin') ? 'admin' : userType;

  useEffect(() => {

    return effectFetch(
      `/api/${userT}/profile`,
      json => setFormDataInit(pr => ({...json.data, photoUrl: `data:${json.data.avatar.image_type};base64, ${json.data.avatar.image_data}`} || pr)),
      err => console.log(err)
    );

  }, [ userType, updateTrigger ]);

  const handleSubmit = () => {
    authService.authFetch(`/api/${userT}/profile/`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: formData.name,
        automaticAppointmentConfirmation: formData.automaticAppointmentConfirmation
      })
    })
      .then(() => {
        setUpdateTrigger(pr => !pr);
        setSuccess({ message: 'Update successful' });
      })
      .catch(err => setErrors([err]));

    const photoForm = new FormData()
    const types = ['image/png', 'image/jpeg', 'image/jpg']
    let errs = [];

    if (formData.photo && types.every(type => formData?.photo?.type !== type)) {
      errs.push({ message: 'Invalid file, only png, jpeg and jpg is supported.' });
    }

    if (formData.photo && formData?.photo?.size > 150000) {
      errs.push({ message: 'File is too large, pick something smaller than 150KB' });
    }

    setErrors(errs);
    if(errs.length > 0 || !formData.photo) {
      return;
    }

    photoForm.append('photo', formData.photo)

    authService.authFetch(`/api/${userT}/profile/new-avatar`, {
      method: 'POST',
      body: photoForm
    })
      .then(() => window.location.reload())
      .catch(err => setErrors([err]));
  };

  const handleImageChange = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setFormDataInit(pr => ({ ...pr, photo: file, photoUrl: URL.createObjectURL(file) }));
    }
  }

  return (
    <div>
      <div>
        <h3>Personal Details</h3>

        Set your name and photo.
      </div>

      <Card>
        <CardContent>
          <Avatar
            className={ classes.image }
            alt='profile-picture'
            src={ formData.photoUrl }
          />

          <Button
            fullWidth
            color="primary"
            variant="contained"
            component="label"
          >
            Upload File
            <input
              type="file"
              onChange={handleImageChange}
              hidden
            />
          </Button>

          <br />
          <TextField
            label="Name"
            id="name"
            name="name"
            type="text"
            value={ formData.name }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />

          {
            userType === 'barber' &&
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ formData.automaticAppointmentConfirmation }
                      style={{ color: 'black' }}
                      onChange={
                        e => setFormData({
                          target: {
                            name: 'automaticAppointmentConfirmation',
                            value: e.target.checked
                          }
                        })
                      }
                    />
                  }
                  label="Automatic appointment confirmation"
                />
              </>
          }

          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={ handleSubmit }
          >
            Save
          </Button>
        </CardContent>
      </Card>
    </div>
  )
};

export default PersonalDetailsCard;
