import React, { useState, useEffect , useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import authService from '../../../utils/authService';
import { effectFetch } from '../../../utils/helpers';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { Errors } from '../../../App.js';

const primaryButton = {
  backgroundColor: '#1a263a',
  color: 'white',
  '&:hover': {
    backgroundColor: '#3c485c'
  },
};

const useStyles = makeStyles(_ => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    '& > *': {
      padding: '.25rem 2rem',
      '& > *:nth-child(2)': {
        position: 'absolute',
        right: 0,
        left: 0,
        textAlign: 'center',
        zIndex: -1
      }
    }
  },
  container: {
    paddingTop: '4.5rem',
    backgroundColor: '#f0f0f2',
    height: 'calc(100vh - 4.5rem)',
    display: 'flex',
    alignItems: 'stretch',
  },
  rightContainer: {
    borderLeft: 'solid 1px #ddd',
    backgroundColor: 'white',
    flex: '4 4',
    display: 'flex',
    flexDirection: 'column',

    // Divs inside
    '& > div': {
      flex: '1 1',
      padding: '0 1.5rem',

      // First div's h3
      '&:first-child > h3': {
        margin: '2rem 0',
      },

      // Second div
      '&:nth-child(2)': {
        flex: '8 8',
        textAlign: 'center',
      },

      // Borders
      '& + div': {
        borderTop: '1px solid #ddd'
      },
    },
  },
  leftContainer: {
    padding: '3rem',
    flex: '8 8',
    overflow: 'auto',
  },
  grey: {
    color: 'rgba(0, 0, 0, .54)',
  },
  bold: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  card: {
    marginBottom: '1rem',
  },
  cardContent: {
    padding: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',

    // Divs
    '& > div': {

      // First div(quantity)
      '&:first-child': {
        verticalAlign: 'top',
        marginRight: '1rem',
      },

      // Second div(everything else)
      '&:last-child': {
        width: '90%',

        // Divs
        '& > div': {
          marginBottom: '.5rem',

          // Price + x button in first div
          '&:first-child > div': {
            float: 'right',

            '& > div': {
              marginRight: '1rem',
              display: 'inline-block',
            },
            '& > button': {
              padding: 0,
            },
          },

          // Last div(text fields)
          '&:last-child > div': {
            marginRight: '1rem',
          },
        },
      },
    },
  },
  menu: {
    width: '11.75rem'
  },
  bottom: {
    marginTop: '2rem',
    padding: '0 2rem',

    '& > div': {
      float: 'right',
      width: '45%',

      '& > div': {
        fontSize: '1.3rem',
        fontWeight: 700,
        // float: 'right',
        borderBottom: '1px solid #bbb',
        paddingBottom: '1rem',
        marginBottom: '1rem',

        '& > span': {
          float: 'right',
        },
      },
    },
  },
  paymentSelection: {
    textAlign: 'center',

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',

      '& > button': {
        ...primaryButton,
        backgroundColor: '#f74969',
        fontSize: '1rem',
        padding: '1rem 0',
        margin: '.5rem',
        flexBasis: 'calc(50% - 1rem)',
        flexGrow: 1,
        '&:hover': {
          backgroundColor: '#ff7191'
        },
      },
    },
  },
  paymentConfirmation: {
    marginTop: '4rem',

    '& > *': {
      marginBottom: '2rem',
    },

    '& > button': {
      marginTop: '4rem',
      padding: '1rem',
      width: '100%',
      backgroundColor: '#f74969',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ff7191'
      },
    },

    '& > a': {
      fontSize: '1.1rem',
      color: '#368dff',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
  },
  subtotal: {
    fontSize: '1rem !important',
    fontWeight: 'initial !important'
  }
}));

const customParseInt = num => -Math.abs(parseInt(num || 0) || 0);

const RefundDialog = ({ handleClose, invoice, userType, handleSubmit, open, formatCurrency }) => {
  const { errors, setErrors } = useContext(Errors);
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  const total =
    items.reduce((pr, cur, i) => pr + cur.gross * quantities[i], 0);

  useEffect(() => {
    setItems(invoice?.items.filter(a => !a.service) || []);
    setQuantities(invoice?.items.filter(a => !a.service).map(a => -a.quantity));
    setSelectedPaymentType(null);
  }, [invoice?.items, open]);

  useEffect(() => {
    return effectFetch(
      `/api/${userType}/payments/types?limit=100`,
      json => setPaymentTypes(json.data || []),
      err => console.log(err)
    );
  }, [ invoice, open ]);

  const submitHandler = () => {
    const obj = {
      status: 'refund',
      originalTransaction: invoice.transaction_id,
      paymentType: selectedPaymentType.payment_type_id,
      products: items.map((item, i) => ({
        product: item.id,
        quantity: quantities[i]
      }))
    };

    handleSubmit(obj);
  };

  return (
    <Dialog
      fullScreen
      open={ open }
      onClose={ handleClose }
    >
      <AppBar className={ classes.appBar }>
        <Toolbar>
          <IconButton edge="start" onClick={ handleClose }>
            <CloseIcon />
          </IconButton>

          <h2>
            Refund
          </h2>
        </Toolbar>
      </AppBar>

      <div className={ classes.container }>
        <div className={ classes.leftContainer }>
          <div>
            { items.map((item, i) => (
              <Card className={ classes.card }>
                <CardContent className={ classes.cardContent }>
                  <div className={ classes.bold }>{ customParseInt(quantities[i]) }</div>
                  <div>
                    <div className={ classes.bold }>
                      { item.name }

                      <div>
                        <div>
                          {
                            formatCurrency(item.gross * customParseInt(quantities[i]))
                          }
                        </div>
                        <IconButton
                          edge="start"
                          onClick={ _ => {
                            setItems(items.filter((_, aI) => aI !== i));
                            setQuantities(quantities.filter((_, aI) => aI !== i));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>

                    <div className={ classes.grey }>
                      { item.barcode }, { item.brand }, { item.saloon }
                    </div>

                    <div>
                      <TextField
                        label="Quantity"
                        margin="dense"
                        variant="outlined"
                        type="number"
                        value={ quantities[i] }
                        onChange={
                          e => setQuantities(quantities.map(
                            (q, qI) => qI === i ? e.target.value : q
                          ))
                        }
                        onBlur={
                          _ => setQuantities(quantities.map(
                            (q, qI) => qI === i ? customParseInt(q) : q
                          ))
                        }
                      />

                      <TextField
                        label="Price"
                        margin="dense"
                        variant="outlined"
                        disabled
                        value={ formatCurrency(item.gross) }
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>

          <div className={ classes.bottom }>
            <div>
                <div className={ classes.subtotal }>
                  Subtotal: 
                  <span>
                    { formatCurrency(items.reduce((pr, cur, i) => pr + (quantities[i] * (cur.net || 0)), 0)) }
                  </span>
                  {
                    items.map((a, i) => ({
                      taxName: a.taxName,
                      taxRate: a.taxRate,
                      value: quantities[i] * (a.gross - a.net)
                    })).filter(a => a.taxName)
                      .reduce((pr, cur) => {
                        const index = pr.findIndex(b => b.taxName === cur.taxName && b.taxRate === cur.taxRate);

                        if(index !== -1) {
                          pr[index].value += cur.value;
                        } else {
                          pr.push({ ...cur });
                        }

                        return pr;
                      }, []).map(a => (
                        <><br/>{a.taxName} {a.taxRate}% <span>{ formatCurrency(a.value) }</span></>
                      ))
                  }
                </div>
                <div>Total: <span>{ formatCurrency(total) }</span></div>
              </div>
          </div>
        </div>

        <div className={ classes.rightContainer }>
          <div>
            <h3>{ invoice?.customer_name || 'Walk In' }</h3>
          </div>

          <div>
            <div>
              { selectedPaymentType ? (
                <div className={ classes.paymentConfirmation }>
                  <div>Your invoice is prepared to be refunded</div>
                  <Button
                    variant="contained"
                    onClick={ submitHandler }
                  >
                    Refund Now
                  </Button>
                  <a onClick={ () => setSelectedPaymentType(null) }>
                    {'<'} Back to payments
                  </a>
                </div>
              ) : (
                <div className={ classes.paymentSelection }>
                  <h4>Pay</h4>
                  <h1>
                    { formatCurrency(total) }
                  </h1>

                  <div>
                    { paymentTypes.map(a => (
                      <Button
                        variant="contained"
                        onClick={ () => setSelectedPaymentType(a) }
                      >
                        { a.name }
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
};

export default RefundDialog;
