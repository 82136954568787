import React, { useEffect, useState , useContext } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import NewPaymentTypeDialog from './NewPaymentTypeDialog';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  search: {
    margin: '0'
  },
  table: {
    margin: '1rem auto 3rem auto',
    maxWidth: '1024px',
    border: '1px solid #e2e6ea'
  },
  tableRow: {
    background: 'white',
    borderLeft: '5px #dddddd solid',
    cursor: 'pointer'
  },
  cell: {
    fontSize: '1rem',
    fontWeight: '500'
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    borderRadius: '4px',
    padding: '.5rem 1.5rem',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  toolbar: {
    margin: '0 auto',
    marginTop: '2rem',
    maxWidth: '1024px',
    textAlign: 'right'
  },
}));

const PaymentTypes = () => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchPaymentTypes = () => {
    return effectFetch(
      '/api/super-admin/payments/types?limit=100',
      json => setPaymentTypes((json.data || [])),
      err => console.log(err)
    );
  }

  // Fetch paymentTypes
  useEffect(fetchPaymentTypes, [ updateTrigger ]);

  const submitHandler = (hasNewPaymentType, paymentType) => {
    if(hasNewPaymentType) {
      if(selectedPaymentType) {
        // Edit paymentType
        authService.authFetch('/api/super-admin/payments/types', {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: selectedPaymentType.payment_type_id, name: paymentType.name })
        }).then(_ => {
          setUpdateTrigger(!updateTrigger);
        }).catch(err => setErrors([err]));
      } else {
        // Post new paymentType
        authService.authFetch('/api/super-admin/payments/types/new', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(paymentType)
        }).then(_ => {
          setUpdateTrigger(!updateTrigger);
        }).catch(err => setErrors([err]));
      }
    }

    setIsDialogOpen(false);
    setSelectedPaymentType(null);
  };

  const deleteHandler = () => {
    // Delete paymentType
    authService.authFetch('/api/super-admin/payments/types', {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: selectedPaymentType.payment_type_id })
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
      setSelectedPaymentType(null);
    }).catch(err => setErrors([err]));

    setIsDialogOpen(false);
  };

  return (
    <div>
      <div className={ classes.toolbar }>
        <Button
          className={ classes.primary }
          onClick={ _ => setIsDialogOpen(true) }
          variant="contained"
          color="primary"
        >
          New Payment Type
        </Button>

        <NewPaymentTypeDialog
          paymentType={ selectedPaymentType }
          isDialogOpen={ isDialogOpen || selectedPaymentType }
          handleSubmit={ submitHandler }
          handleDelete={ deleteHandler }
        />
      </div>

      <Table className={ classes.table }>
        <TableBody>
          {
            paymentTypes.map((a, i) => (
              <TableRow
                key={i}
                className={ classes.tableRow }i
                onClick={ _ => setSelectedPaymentType(a) }
                hover
              >
                <TableCell className={ classes.cell }>
                  { a.name }
                </TableCell>
              </TableRow>
            ))
          }

          { paymentTypes.length <= 0 && <h3>None</h3> }
        </TableBody>
      </Table>
    </div>
  )
};

export default PaymentTypes;
