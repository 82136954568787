import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from "moment";
import authService from '../../utils/authService';
import { formatDuration, effectFetch } from '../../utils/helpers';
import { DateTimePicker } from "@material-ui/pickers";
import { Errors } from '../../App.js';

const useStyles = makeStyles(_ => ({
  title: {
    borderBottom: '1px solid #ddd',
    marginBottom: '2rem'
  },
  action: {
    borderTop: '1px solid #ddd',
    marginTop: '2rem'
  },
  dialog: {
    // height: '80%',
    width: '30%',
    textAlign: 'center',
    fontSize: '2rem',
    '& > * > *': {
      width: '100%'
    }
  },
  // input: {
  //   textAlign: 'center',
  //   padding: '.6rem .5rem',
  //   borderLeft: '1px solid #ced4da',
  //   borderRight: '1px solid #ced4da',
  //   '&:hover': {
  //     cursor: 'pointer'
  //   }
  // },
  button: {
    margin: '.5rem',
    padding: '.5rem',
    fontSize: '1rem',
    backgroundColor: '#1a263a',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  select: {
    margin: '0 0 1rem 0',
    borderRadius: '4px',
    padding: '.6rem 1.6rem',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: 'white'
    }
  },
  label: {
    textAlign: 'left',
    fontSize: '.8rem',
  },
  input: {
    margin: '0 0 1rem 0',
    borderRadius: '4px',
    padding: '0',
    border: '1px solid #ced4da',
    padding: '.3rem 1.6rem',
  }
}));

const NewAppointmentDialog = ({ defaultTime, handleSubmit, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [date, setDate] = useState(new Date(defaultTime));

  const [availableServices, setAvailableServices] = useState([]);
  const [service, setService] = useState({ id: 0, title: '', index: 0 });

  // Update date using defaultTime
  useEffect(() => {
    setDate(new Date(defaultTime));
    setService(availableServices[0]);
  }, [defaultTime]);

  // Fetch services
  useEffect(() => {
    return effectFetch(
    '/api/barber/services/self?limit=100',
      json => {
        const avServices = (json.data || []).map((a, i) => ({
          title: a.title,
          duration: a.duration + a.extra_duration,
          price: a.price,
          id: a.service_id,
          index: i
        }));

        setAvailableServices(avServices);

        if(avServices.length > 0) {
          setService(avServices[0]);
        }
      },
      err => console.log(err)
    );
  }, []);

  const handleClose = () => handleSubmit(false);

  const submitHandler = () => {
    handleSubmit(true, {
      time: moment(date),
      services: [ service?.id ]
    });
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={ Boolean(defaultTime) }
        onClose={ handleClose }
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          className={ classes.title }
        >
          New Appointment
        </DialogTitle>

        <DialogContent>
          <DateTimePicker
            label="Date and Time"
            value={ date }
            onChange={ setDate }
            InputProps={{
              className: classes.input,
              disableUnderline: true
            }}
          />

          <InputLabel
            htmlFor="service"
            className={ classes.label }
          >Service</InputLabel>
          <Select
            native
            value={ service?.index }
            onChange={ e => setService(availableServices[e.target.value]) }
            classes={{ root: classes.select }}
            disableUnderline
            InputProps={{
              name: 'service',
              id: 'service',
            }}
          >
            {
              availableServices.map((a, i) => (
                <option key={i} value={ i }>{ a.title }</option>
              ))
            }
          </Select>
          <br />

          <TextField
            InputProps={{
              className: classes.input,
              disableUnderline: true
            }}
            disabled
            label="Price"
            value={ service?.price && (formatCurrency(service?.price)) }
          />
          <br />

          <TextField
            InputProps={{
              className: classes.input,
              disableUnderline: true
            }}
            disabled
            classes={{ label: classes.label }}
            label="Duration"
            value={ formatDuration(service?.duration) }
          />
        </DialogContent>
        <DialogActions className={ classes.action }>
          <Button
            className={ classes.button }
            onClick={ submitHandler }
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewAppointmentDialog;
