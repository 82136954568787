import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '430px',
    overflow: 'auto'
  },
  tableHeader: {
    color: '#939dad'
  },
  table: {
    color: 'blue',
    '& > *:last-child > *': {
      fontWeight: 'bold'
    }
  }
}));

const CashMovementSummaryCard = ({ data, formatCurrency }) => {
  const classes = useStyles();

  return (
    <Card className={ classes.card }>
      <CardHeader
        title="Cash Movement Summary"
      />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={ classes.tableHeader }
              >
                Payment Type
              </TableCell>
              {
                [ 'Payments collected', 'Refunds Paid' ].map(cell => (
                  <TableCell
                    className={ classes.tableHeader }
                    align="right"
                  >
                    { cell }
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody className={ classes.table }>
            {
              data.map((a, i) => (
                <TableRow key={i}>
                  <TableCell>
                    { a.type }
                  </TableCell>
                  <TableCell align="right">
                    { formatCurrency(a.payments_collected) }
                  </TableCell>
                  <TableCell align="right">
                    { formatCurrency(a.refunds_paid) }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
};

export default CashMovementSummaryCard;

