import React from 'react';
import MyDrawer from './MyDrawer';
import AppBar from './AppBar';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { capitalizeFirst } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  content: {
    marginLeft: '2rem',
    flexGrow: 1,
    backgroundColor: '#f7f7f8',
    minHeight: '100%',
    height: 'fit-content'
  },
  icon: {
    color: 'white'
  }
}));

const Layout = ({ userType, children }) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const location = useLocation();

  const links = [
    { name: "Home",         path: `${path}/home`,         icon: <HomeOutlinedIcon           className={ classes.icon } /> },
    { name: "Calendar",     path: `${path}/calendar`,     icon: <CalendarTodayOutlinedIcon  className={ classes.icon } /> },
    { name: "Appointments", path: `${path}/appointments`, icon: <CollectionsBookmarkIcon    className={ classes.icon } /> },
    { name: "Sales",        path: `${path}/sales`,        icon: <ReceiptOutlinedIcon        className={ classes.icon } /> },
    { name: "Clients",      path: `${path}/clients`,      icon: <PersonOutlineOutlinedIcon  className={ classes.icon } /> },
    { name: "Staff",        path: `${path}/staff`,        icon: <BusinessCenterOutlinedIcon className={ classes.icon } /> },
    { name: "Services",     path: `${path}/services`,     icon: <AssignmentOutlinedIcon     className={ classes.icon } /> },
    { name: "Inventory",    path: `${path}/inventory`,    icon: <LocalShippingOutlinedIcon  className={ classes.icon } />, hide: userType === 'barber' },
    { name: "Analytics",    path: `${path}/analytics`,    icon: <PieChartOutlinedIcon       className={ classes.icon } />, hide: userType === 'barber' },
    { name: "Setup",        path: `${path}/setup`,        icon: <SettingsOutlinedIcon       className={ classes.icon } /> },
  ];

  return (
    <div className={ classes.root }>
      <MyDrawer links={ links } />

      <div className={ classes.content }>
        <AppBar userType={ userType } heading={ capitalizeFirst(location.pathname.split('/')[2] ?? '') } />
        { children }
      </div>
    </div>
  )
};

export default Layout;
