import React, { useState, useEffect, useContext, useCallback } from 'react';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ComplicatedDatePicker from './ComplicatedDatePicker';
import Export from '../../sales/Export';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Loadable from '../../../utils/Loadable';
import authService from '../../../utils/authService';
import { splitNCapitalize, setFontPDF, effectFetch } from '../../../utils/helpers';
import FilterDrawer from '../../inventory/FilterDrawer';
import { Errors } from '../../../App.js';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
applyPlugin(jsPDF);

const useStyles = makeStyles(() => ({
  link: {
    color: '#368dff',
    cursor: 'pointer',
    fontSize: '1.1rem',
    '&:hover': {
      textDecoration: 'underline'
    }

  },
  wrapper: {
    padding: '1rem'
  },
  container: {
    padding: '.75rem'
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '.75rem',
    marginTop: 0,
    '& > div + div > button': {
      backgroundColor: 'white',
      padding: '.5rem 1.5rem',
      '& + button': {
        marginLeft: '.5rem',
      }
    }
  },
}));

const generatePDF = (path, data, fields, dates, selectedSaloon, formatValue) => {
  const title = splitNCapitalize(path);
  const doc = new jsPDF();
  setFontPDF(doc);

  doc.text(title, 10, 10);
  doc.setFontSize(7);
  doc.text(
    `${
      dates.start.format('dddd, D MMM YYYY')
    } to ${
      dates.end.format('dddd, D MMM YYYY')
    }, ${
      selectedSaloon || 'All locations'
    }, generated ${
      moment().format('DD MMM YYYY [at] HH:mm')
    }`,
    10,
    15
  );

  doc.autoTable({
    startY: 30,
    head: [ fields.map(a => splitNCapitalize(a)) ],
    body: data.map(a => Object.values(a).map(b => formatValue(b).toString())),
    styles: { font: 'Arial' }
  })

  doc.save(path + moment().format('_YYYY-MM-DD') + '.pdf');
};

const generateExcelOrCSV = (path, data, fields, isCSV, formatValue) => {
  const title = splitNCapitalize(path);

  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    CreatedDate: new Date()
  };

  const dataAOA = data.map(a => Object.values(a).map(b => formatValue(b)));

  dataAOA.unshift(fields.map(field => splitNCapitalize(field)));

  wb.SheetNames.push(title);

  wb.Sheets[title] = XLSX.utils.aoa_to_sheet(dataAOA);

  XLSX.writeFile(wb, path + moment().format('_YYYY-MM-DD') + ((isCSV) ? '.csv' : '.xlsx'));
};

const ReportPage = ({ userType, path, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({ start: moment(), end: moment() });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [selectedSaloon, setSelectedSaloon] = useState(0);
  const [availableSaloons, setAvailableSaloons] = useState([]);

  const formatValue = (value) => {
    // If currency value
    if(typeof value === 'string' && value[0] === '€') {
      return formatCurrency(parseFloat(value.slice(1)));
    }

    return value;
  };


  // Fetch availableSaloons
  useEffect(() => {
    let toReturn;

    if(userType === 'super-admin') {
      toReturn = effectFetch(
        `/api/super-admin/saloons/all?limit=100`,
        json => {
          const avSaloons = (json.data || [])
            .map((a, i) => ({
              name: a.name,
              id: a.saloon_id,
              index: i + 1
            }));

          avSaloons.unshift({ name: 'All', id: 0, index: 0 });
          setAvailableSaloons(avSaloons);

          if(avSaloons.length > 0) {
            setSelectedSaloon(avSaloons[0].id);
          }
        },
        err => console.log(err)
      );
    }

    return toReturn;
  }, [ userType ]);

  const getDataUrl = (pageIn) => {
    const saloonQuery = (selectedSaloon === 0) ? '' : 'saloon=' + selectedSaloon;
    const start = dates.start.format('YYYY-MM-DD');
    const end = dates.end.format('YYYY-MM-DD');

    return `/api/${userType}/analytics/reports/${path}?${saloonQuery}&startDate=${start}&endDate=${end}&limit=20&page=${pageIn + 1}`;
  }

  const getAllData = async () => {
    const allData = [];
    let i = 0;

    do {
      const res = await authService.authFetch(getDataUrl(i));
      allData.push(...(await res.json()).data);

      if(allData[allData.length - 1][data?.fields[0]] === 'Total')
        allData.pop();

      i ++;
    } while(allData.length === i * 20)

    return allData;
  }

  const fetchData = useCallback(() => {
    setLoading(true);

    return effectFetch(
      getDataUrl(page),
      json => {
        setData((json || []));
        setLoading(false);
      },
      err => console.log(err)
    );
  }, [dates, selectedSaloon, page]);

  // Fetch data
  useEffect(fetchData, [ userType, page, dates, selectedSaloon ]);

  // Reset page when selectedSaloon or date is changed
  useEffect(() => setPage(0), [ selectedSaloon, dates ]);

  const handleChangePage = (_, newPage) => {
    if(data?.data.length === 21 || data?.data.length === 20 || newPage < page) {
      setPage(newPage);
    }
  }

  return (
    <div className={ classes.wrapper }>
      <FilterDrawer
        userType={ userType }
        availableOptions={{ saloon: availableSaloons }}
        handleChange={ filters => {
          if(filters !== null) {
            setSelectedSaloon(filters.saloon);
          }

          setIsDrawerOpen(false);
        }}
        open={ isDrawerOpen }
      />

      <a
        href="#"
        className={ classes.link }
        onClick={ _ => history.push('/' + userType + '/analytics') }
      >
        { '< Reports' }
      </a>

      <h2>{ splitNCapitalize(path) }</h2>
      <div className={ classes.toolbar }>
        <ComplicatedDatePicker
          type="year_to_date"
          hasPopup={ true }
          dates={ dates }
          onChange={ setDates }
        />

        <div>
          <Export
            generatePDF={ async _ => generatePDF(path, await getAllData(), data.fields, dates, availableSaloons.find(a => a.id === selectedSaloon)?.name, formatValue) }
            generateExcel={ async _ => generateExcelOrCSV(path, await getAllData(), data.fields, false, formatValue) }
            generateCSV={ async _ => generateExcelOrCSV(path, await getAllData(), data.fields, true, formatValue) }
          />

          { userType === 'super-admin' &&
          <Button
            onClick={ _ => setIsDrawerOpen(true) }
            align="right"
            variant="contained"
            endIcon={ <FilterListIcon /> }
          >
            Filters
          </Button>
          }
        </div>
      </div>

      <div className={ classes.container }>
        <Loadable isLoading={ loading }>
          <Card>
            <CardContent>
              <TableContainer component="paper">
                <Table>
                  <TableHead>
                    <TableRow>
                      {
                        data?.fields.map(key => (
                          <TableCell
                            key={ key }
                          >
                            { splitNCapitalize(key) }
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data?.data.map((item, i) => (
                        <TableRow
                          className={ classes.row }
                          key={i}
                        >
                          {
                            Object.keys(item).map(key => (
                              // Make total row bold
                              <TableCell style={{ fontWeight: i === data?.data.length - 1 && item[data?.fields[0]] === 'Total' ? 800 : 0 }} key={key}>{ formatValue(item[key]) }</TableCell>
                            ))
                          }
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[ 20 ]}
                  component="div"
                  count={
                    (data?.data.length === 20 || data?.data.length === 21) ? (-1) : (20 * page + data?.data.length)
                  }
                  rowsPerPage={20}
                  page={ page }
                  onChangePage={ handleChangePage }
                  nextIconButtonProps={{ disabled: data?.data.length < 20 }}
                />
              </TableContainer>
            </CardContent>
          </Card>
        </Loadable>
      </div>
    </div>
  );
};

export default ReportPage;
