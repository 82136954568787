import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopStaffCard from './home/TopStaffCard';
import TopServicesCard from './home/TopServicesCard';
import NextAppointmentsCard from './home/NextAppointmentsCard';
import AppointmentActivityCard from './home/AppointmentActivityCard';
import RecentSalesCard from './home/RecentSalesCard';
import UpcomingAppointmentsCard from './home/UpcomingAppointmentsCard';
import useCurrency from '../utils/useCurrency';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1.5rem'
  },
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '560px',
    overflow: 'auto'
  },
  tableHeader: {
    color: '#939dad'
  },
  lightText: {
    color: '#8595ac'
  },
}));

const Home = ({ userType }) => {
  const { formatCurrency } = useCurrency();
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <RecentSalesCard userType={ userType } formatCurrency={ formatCurrency } />

      <UpcomingAppointmentsCard userType={ userType } />

      <AppointmentActivityCard userType={ userType } formatCurrency={ formatCurrency } />

      <NextAppointmentsCard userType={ userType } formatCurrency={ formatCurrency } />

      <TopServicesCard userType={ userType } formatCurrency={ formatCurrency } />

      {
        // Only display this card to admins
        userType !== 'barber' && <TopStaffCard userType={ userType } formatCurrency={ formatCurrency } />
      }
    </div>
  )
};

export default Home;
