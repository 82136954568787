import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '1rem',
    '& div span': {
      marginLeft: '.5rem',
      height: '.75rem',
      width: '.75rem',
      display: 'inline-block'
    }
  }
}));

const statusColors = {
  confirmed: '#2883d2',
  cancelled: '#f33155',
  pending: '#f5a623',
  completed: '#0bc2b0',
  rescheduled: '#b028d2'
};

const StatusKeys = ({ confirmed, cancelled, completed, pending, rescheduled }) => {
  const classes = useStyles();

  return (
    <div className={ classes.container }>
      { confirmed &&
        <div>
          Confirmed:
          <span style={{ backgroundColor: statusColors.confirmed }}></span>
        </div>
      }

      { cancelled &&
        <div>
          Cancelled:
          <span style={{ backgroundColor: statusColors.cancelled }}></span>
        </div>
      }

      { completed &&
        <div>
          Completed:
          <span style={{ backgroundColor: statusColors.completed }}></span>
        </div>
      }

      { pending &&
        <div>
          Pending:
          <span style={{ backgroundColor: statusColors.pending }}></span>
        </div>
      }

      { rescheduled &&
        <div>
          Rescheduled:
          <span style={{ backgroundColor: statusColors.rescheduled }}></span>
        </div>
      }
    </div>
  );
};

export default StatusKeys;
