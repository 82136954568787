import React, { useEffect, useRef, useState } from 'react';
import clsx from "clsx";
import moment from 'moment';
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { changeWeekStart } from '../../../utils/helpers';
import authService from '../../../utils/authService';

const INDEX_OF_CUSTOM = 12;

const useStyles = makeStyles(() => ({
  popover: {
    padding: '1rem',
    width: '23rem',

    // The two buttons at the end
    '& > .buttons': {
      textAlign: 'right',

      // The actual buttons
      '& > button': {
        marginLeft: '1rem',
        padding: '.5rem 1.5rem',
      }
    }
  },
  input: {
    textAlign: 'center',
    padding: '.6rem .5rem',
    borderLeft: '1px solid #ced4da',
    borderRight: '1px solid #ced4da',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapper: {
    borderRadius: '4px',
    backgroundColor: 'white',
    display: 'inline-block',
  },
  chevron: {
    padding: '.4rem .5rem',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: '1rem',
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: 'white',
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: '#101928',
    color: 'white',
  },
  firstHighlight: {
    background: '#101928',
    color: 'white',
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    background: '#101928',
    color: 'white',
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  inputContainer: {
    marginTop: '1rem',
    width: '45%',
    display: 'inline-block',

    '& + div': {
      marginLeft: '10%'
    }
  },
  white: {
    padding: '.5rem 1.5rem',
    backgroundColor: 'white'
  },
  datePicker: {
    margin: '1rem 6%'
  }
}));

const getLastDaysNum = (type) => parseInt(type.split('_')[1]);

const getStartAndEndDate = (startDate, endDate, type) => {
  let dates = {
    start: null,
    end: null
  };

  let unit;
  switch(type) {
    case 'custom':
      dates = {
        start: startDate.clone(),
        end: endDate.clone()
      }
      break;

    case 'week_to_date':
    case 'month_to_date':
    case 'quarter_to_date':
    case 'year_to_date':
      unit = type.split('_')[0];
      dates.end = startDate.clone();
      dates.start = startDate.clone().startOf(unit);
      break;

    case 'last_year':
    case 'last_month':
      unit = type.split('_')[1];
      dates.end = startDate.clone().startOf(unit).subtract(1, 'day');
      dates.start = dates.end.clone().startOf(unit);
      break;

    case 'last_90_days':
    case 'last_30_days':
    case 'last_7_days':
      dates.end = startDate.clone();
      dates.start = startDate.clone().subtract(getLastDaysNum(type) - 1, 'day');
      break;

    case 'week':
      dates.start = startDate.clone().startOf('week');
      dates.end = startDate.clone().endOf('week');
      break;

    case '3day':
    case '1day':
      dates.start = startDate.clone();
      dates.end = startDate.clone().add(type.charAt(0) - 1, 'day');
      break;

    default:
      dates.start = startDate.clone();
      dates.end = startDate.clone();
      break;
  }

  return dates;
};

const getTxtStates = (dates) => {
  return {
    start: { txt: dates.start.format('YYYY-MM-DD'), invalid: false },
    end: { txt: dates.end.format('YYYY-MM-DD'), invalid: false }
  }
};

const ComplicatedDatePicker = ({ dates, typeProp, onChange }) => {
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(typeProp || '1day');
  const [selectedDates, setSelectedDates] = useState({ ...dates  });
  const [textInputs, setTextInputs] = useState(getTxtStates(dates));
  // When manually setting dates you first have to select the start and then the
  // end date. This hook tracks which date comes next
  const [nextDateSpecifier, setNextDateSpecifier] = useState('start');

  // Update moment locale
  useEffect(() => {
    const { weekStart } = authService.getToken();
    changeWeekStart(weekStart);
  }, []);

  // Manage text input change
  const manageTexInput = (value, dateSpecifier) => {
    const dateObj = moment(value, 'YYYY-MM-DD', true);

    if(dateObj._isValid) {
      if(
        (dateSpecifier === 'start' && dateObj.isAfter(selectedDates.end, 'day')) ||
        (dateSpecifier === 'end' && dateObj.isBefore(selectedDates.start, 'day'))
      ) {
        setSelectedDates({ start: dateObj, end: dateObj });
      } else {
        setSelectedDates({ ...selectedDates, [dateSpecifier]: dateObj });
      }

      // Set selectedType to cutom
      setSelectedType(availableDateTypes[INDEX_OF_CUSTOM]);
    } else {
      setTextInputs({ ...textInputs, [dateSpecifier]: { txt: value, invalid: true } });
    }
  }

  const handleChange = (startDate, endDate) => {
    // If this is called from the date picker
    if(!endDate) {
      setSelectedType(availableDateTypes[INDEX_OF_CUSTOM]);

      if(nextDateSpecifier === 'start') {
        setSelectedDates({ start: startDate, end: startDate });
        setNextDateSpecifier('end');
      } else {
        setSelectedDates({
          ...selectedDates,
          // If end date is after current start date make it start date instead
          [selectedDates.start.isAfter(startDate, 'day') ? 'start' : 'end']: startDate
        });
        setNextDateSpecifier('start');
      }
    } else {
      const theDates = getStartAndEndDate(startDate, endDate, type);
      setSelectedDates({ start: theDates.start, end: theDates.end });
    }
  };

  // Everytime the type changes recalculate start and end dates
  useEffect(() => {
    handleChange(selectedDates.start || moment(), selectedDates.end);
  }, [ type ]);

  // Update text fields when dates change
  useEffect(() => {
    setTextInputs(getTxtStates(selectedDates));
  }, [ selectedDates ]);

  const formatSelectLabel = (date, invalidLabel) => {
    let firstDateFormat = '';

    if(!date.isSame(selectedDates.end, 'day')) {
      firstDateFormat += 'D';
    }

    if(!date.isSame(selectedDates.end, 'month')) {
      firstDateFormat += ' MMM';
    }

    if(!date.isSame(selectedDates.end, 'year')) {
      firstDateFormat += ', yyyy';
    }

    return date && date.isValid()
      ? `${firstDateFormat ? date.format(firstDateFormat) + ' - ' : ''}${selectedDates.end.format('D MMM, yyyy')}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date, _, dayInCurrentMonth) => {
    const dayIsBetween = date.clone().isBetween(selectedDates.start, selectedDates.end);
    const isFirstDay = date.clone().isSame(selectedDates.start, 'day');
    const isLastDay = date.clone().isSame(selectedDates.end, 'day');

    const wrapperClassName = clsx({
      [classes.highlight]: dayInCurrentMonth && (dayIsBetween || isFirstDay || isLastDay),
      [classes.firstHighlight]: dayInCurrentMonth && isFirstDay,
      [classes.endHighlight]: dayInCurrentMonth && isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> { date.format('D') } </span>
        </IconButton>
      </div>
    );
  };

  const availableDateTypes = [
    {
      index: 0,
      value: 'Today',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('1day');
      }
    },
    {
      index: 1,
      value: 'Yesterday',
      function: () => {
        setSelectedDates({ start: moment().subtract(1, 'day'), end: moment().subtract(1, 'day') });
        setType('1day');
      }
    },
    {
      index: 2,
      value: 'Last 7 days',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('last_7_days');
      }
    },
    {
      index: 3,
      value: 'Last 30 days',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('last_30_days');
      }
    },
    {
      index: 4,
      value: 'Last 90 days',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('last_90_days');
      }
    },
    {
      index: 5,
      value: 'Last Month',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('last_month');
      }
    },
    {
      index: 6,
      value: 'Last Year',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('last_year');
      }
    },
    {
      index: 7,
      value: 'Week to Date',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('week_to_date');
      }
    },
    {
      index: 8,
      value: 'Month to Date',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('month_to_date');
      }
    },
    {
      index: 9,
      value: 'Quarter to Date',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('quarter_to_date');
      }
    },
    {
      index: 10,
      value: 'Year to Date',
      function: () => {
        setSelectedDates({ start: moment(), end: moment() });
        setType('year_to_date');
      }
    },
    {
      index: 11,
      value: 'All Time',
      function: () => {
        setSelectedDates({ start: moment().subtract(3, 'years').startOf('year'), end: moment() });
        setType('custom');
      }
    },
    {
      index: 12,
      value: 'Custom',
      function: () => null
    },
  ];

  // Can't declare these above availableDateTypes
  const [selectedType, setSelectedType] = useState(availableDateTypes[0]);
  const [typeToShow, setTypeToShow] = useState(availableDateTypes[0]);

  // Change dates when type is selected
  useEffect(_ => {
    selectedType.function();

    if(selectedType.index !== INDEX_OF_CUSTOM) {
      setNextDateSpecifier('start');
    }
  }, [ selectedType ]);

  return (
    <div className={ classes.wrapper }>
      <Popover
        classes={{ paper: classes.popover }}
        open={ isOpen }
        anchorEl={ anchorEl.current }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <InputLabel htmlFor='type'>
          Date range
        </InputLabel>
        <Select
          fullWidth
          variant="outlined"
          native
          value={ selectedType.index }
          onChange={ e => setSelectedType(availableDateTypes[e.target.value]) }
          inputProps={{
            name: 'type',
            id: 'type',
          }}
        >
          {
            // Custom is hidden. Only show it when selecting dates manually
            availableDateTypes
              .filter(a => a.index !== INDEX_OF_CUSTOM || selectedType.index === INDEX_OF_CUSTOM)
              .map((a, i) => (
                <option key={i} value={ i }>{ a.value }</option>
              ))
          }
        </Select>
        <br />

        <div className={ classes.inputContainer }>
          <InputLabel htmlFor='start-date'>
            Starting
          </InputLabel>
          <TextField
            variant="outlined"
            value={ textInputs.start.txt }
            onChange={e => manageTexInput(e.target.value, 'start')}
            helperText={ (textInputs.start.invalid) ? 'Invalid start date' : '' }
            error={ textInputs.start.invalid }
            inputProps={{
              name: 'start-date',
              id: 'start-date',
            }}
          />
        </div>

        <div className={ classes.inputContainer }>
          <InputLabel htmlFor='end-date'>
            Ending
          </InputLabel>
          <TextField
            variant="outlined"
            value={ textInputs.end.txt }
            onChange={e => manageTexInput(e.target.value, 'end')}
            helperText={ (textInputs.end.invalid) ? 'Invalid end date' : '' }
            error={ textInputs.end.invalid }
            inputProps={{
              name: 'end-date',
              id: 'end-date',
            }}
          />
        </div>
        <br />


        <div className={ classes.datePicker }>
          <DatePicker
            disableToolbar
            variant="static"
            value={selectedDates.end}
            onChange={handleChange}
            renderDay={renderWrappedWeekDay}
            labelFunc={formatSelectLabel}
            InputProps={{
              disableUnderline: true,
              margin: 'dense',
              classes: { input: classes.input }
            }}
          />
        </div>

        <div className="buttons">
          <Button
            variant="contained"
            className={ classes.white }
            onClick={ _ => {
              setIsOpen(false);
              setSelectedType(typeToShow);
              setSelectedDates(dates);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={ classes.highlight }
            onClick={ _ => {
              onChange({ ...selectedDates });
              setTypeToShow(selectedType);
              setIsOpen(false);
            }}
            disabled={ textInputs.start.invalid || textInputs.end.invalid }
          >
            Apply
          </Button>
        </div>
      </Popover>


      <Button
        variant="contained"
        className={ classes.white }
        onClick={ _ => setIsOpen(true) }
        endIcon={ <EventNoteIcon /> }
        ref={ anchorEl }
      >
        { typeToShow.value }
      </Button>
    </div>
  );
};

export default ComplicatedDatePicker;

