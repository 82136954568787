import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import useForm from '../../../utils/useForm';
import authService from '../../../utils/authService';
import { Success, Errors } from '../../../App.js';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '1rem'
  },
  dialog: {
    width: '30%',
  },
  content: {
    '& > *:nth-child(even)': {
      marginBottom: '1rem'
    }
  },
  white: {
    background: 'white'
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
  primary: {
    padding: '1rem auto 1rem auto',
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
}));

const availableUserTypes = [
  { name: 'Barber', index: 0 },
  { name: 'Admin', index: 1 }
];

const getInitialState = (staff, saloons) => staff ? {
  ...staff,
  userType: (staff.user_type === 'barber') ? 0 : 1,
  saloon: saloons.findIndex(a => staff.saloon_id === a.id)
} : {
  name: '',
  phone_number: '',
  email: '',
  userType: 0,
  saloon: saloons[0]?.index
};

const NewStaffDialog = ({
  open,
  handleClose,
  handleSubmit,
  userType,
  availableSaloons,
  isNew,
  staff
}) => {
  const { setSuccess } = useContext(Success);
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [form, handleChange, setForm] = useForm(getInitialState(staff, availableSaloons));

  useEffect(() => {
    setForm(getInitialState(staff, availableSaloons));
  }, [ open, availableSaloons ]);

  const isStaffEditable = (staff?.user_type === 'super-admin') ||
    (staff?.user_type === 'admin' && userType !== 'super-admin');

  const handleUserTypeChange = () => {
    if (availableUserTypes[form.userType].name.toLowerCase() !== staff.user_type) {
      authService.authFetch(`/api/super-admin/users/staff/user-type`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: staff.id, isFromBarber: staff.user_type === 'barber' })
      }).then(_ => {
        handleClose(true);
        setSuccess({ message: 'User Type updated.'})
      }).catch(err => setErrors([err]));
    }
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={ open }
        onClose={ handleClose }
      >
        <DialogTitle>
          { staff ? 'Edit' : 'New' } Staff Member
        </DialogTitle>

        <DialogContent className={ classes.content }>
          {
            staff &&
              <>
                <InputLabel htmlFor="user-type">User Type</InputLabel>
                <Select
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  disableUnderline
                  disabled={ userType !== 'super-admin' || isStaffEditable }
                  native
                  value={ form.userType }
                  onChange={ handleChange }
                  inputProps={{
                    name: 'userType',
                    id: 'userType',
                  }}
                >
                  {
                    availableUserTypes.map((a, i) => (
                      <option key={i} value={i}>{ a.name }</option>
                    ))
                  }
                </Select>

                <Button
                  className={ classes.primary }
                  onClick={ handleUserTypeChange }
                  variant="contained"
                  disabled={ isStaffEditable }
                >
                  Save
                </Button>

                <Divider className={ classes.divider } />
              </>
          }

          <InputLabel htmlFor="name">Name</InputLabel>
          <TextField
            fullWidth
            id="name"
            name="name"
            margin="dense"
            variant="outlined"
            value={ form.name }
            onChange={ handleChange }
            disabled={ isStaffEditable }
          />

          <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
          <TextField
            fullWidth
            id="phone_number"
            name="phone_number"
            margin="dense"
            variant="outlined"
            value={ form.phone_number }
            onChange={ handleChange }
            disabled={ isStaffEditable }
          />

          <InputLabel htmlFor="email">Email</InputLabel>
          <TextField
            fullWidth
            margin="dense"
            id="email"
            name="email"
            variant="outlined"
            type="email"
            value={ form.email }
            onChange={ handleChange }
            disabled={ isStaffEditable }
          />

          {
            !staff &&
              <>
                <InputLabel htmlFor="user-type">User Type</InputLabel>
                <Select
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  disableUnderline
                  disabled={ userType !== 'super-admin' || isStaffEditable }
                  native
                  value={ form.userType }
                  onChange={ handleChange }
                  inputProps={{
                    name: 'userType',
                    id: 'userType',
                  }}
                >
                  {
                    availableUserTypes.map((a, i) => (
                      <option key={i} value={i}>{ a.name }</option>
                    ))
                  }
                </Select>
              </>
          }

          { userType === 'super-admin' &&
              (
                <>
                  <InputLabel htmlFor="saloon">Saloon</InputLabel>
                  <Select
                    fullWidth
                    disableUnderline
                    margin="dense"
                    variant="outlined"
                    native
                    value={ form.saloon }
                    onChange={ handleChange }
                    inputProps={{
                      name: 'saloon',
                      id: 'saloon',
                    }}
                    disabled={ isStaffEditable }
                  >
                    {
                      availableSaloons.map((a, i) => (
                        <option key={i} value={i}>{ a.name }</option>
                      ))
                    }
                  </Select>
                </>
              )
          }
        </DialogContent>
        <DialogActions>
          <Button
            className={ classes.white }
            onClick={ handleClose }
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={ classes.primary }
            onClick={ _ => handleSubmit({
              ...form,
              userType: staff ? staff.user_type : availableUserTypes[form.userType].name,
              saloon: availableSaloons[form.saloon]?.id,
              id: staff?.id
            }) }
            variant="contained"
            disabled={ isStaffEditable }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewStaffDialog;
