import React, { useState } from 'react';
import ListView from './inventory/ListView';
import ProductView from './inventory/ProductView';
import useCurrency from '../utils/useCurrency';

const Inventory = ({ userType }) => {
  const { formatCurrency, toBaseCurrency, toTargetCurrency } = useCurrency();
  const [selectedProduct, setSelectedProduct] = useState(null);

  if(!selectedProduct) {
    return (
      <ListView
        userType={ userType }
        formatCurrency={ formatCurrency }
        onSelectProduct={ prod => setSelectedProduct(prod.product_id) }
        toBaseCurrency={ toBaseCurrency }
      />
    );
  } else {
    return (
      <ProductView
        userType={ userType }
        selectedProduct={ selectedProduct }
        onBack={ _ => setSelectedProduct(null) }
        formatCurrency={ formatCurrency }
        toBaseCurrency={ toBaseCurrency }
        toTargetCurrency={ toTargetCurrency }
      />
    );
  }
};

export default Inventory;
