import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import CardAction from './CardAction';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { daysBetween, getShortDay, capitalizeFirst, effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

const graphHeight = 285;
const useStyles = makeStyles(() => ({
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '560px',
    overflow: 'auto'
  },
  p: {
    fontSize: '.9rem',
    '& > span': {
      '&:nth-child(1)': {
        color: '#7c7c7c'
      },
      '&:nth-child(2)': {
        fontWeight: 500,
        marginLeft: '1rem'
      }
    }
  }
}));

const processData = (data, period) => {
  let booked = 0, cancelled = 0, confirmed = 0;

  data.forEach(a => {
    booked += a.booked;
    cancelled += a.cancelled;
    confirmed += a.confirmed;
  })

  return {
    processedData: data,
    bookedNum: booked,
    cancelledNum: cancelled,
    confirmedNum: confirmed
  };
};

const UpcomingAppointmentsCard = ({ userType, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [actionState, setActionState] = useState({
    open: false,
    period: 7,
    location: { name: 'All locations', id: -1, index: -1 }
  });
  const [graphData, setGraphData] = useState({
    processedData: [],
    confirmedNum: 0,
    cancelledNum: 0,
    bookedNum: 0
  });

  const setOpen = (open) => {
    setActionState({
      ...actionState,
      open
    });
  };

  const handleActionChange = (period, location) => {
    setActionState({
      open: false,
      period,
      location
    });
  };

  // Fetch data
  useEffect(() => {
    const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
    const target = moment().add(actionState.period, 'days').format('YYYY-MM-DD');
    const allLocations = actionState.location.id === -1;
    const saloonQuery = allLocations ? '' : ('&saloon=' + actionState.location.id);

    const url = `/api/${userType}/appointments/graph/upcoming-appointments?dateType=date-range&startDate=${tomorrow}&endDate=${target}${saloonQuery}`;

    return effectFetch(
      url,
      json => setGraphData(processData(json.data, actionState.period) || []),
      err => console.log(err)
    );
  }, [ actionState.period, actionState.location, userType ]);

  return (
    <Card className={ classes.card }>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            ref={ anchorEl }
            onClick={ _ => setOpen(!actionState.open) }
          >
            <MoreHorizIcon />
          </IconButton>
        }
        title="Upcoming appointments"
        subheader={ `${actionState.location.name}, next ${actionState.period} days` }
      />
      <CardContent>
        <CardAction
          userType={ userType }
          anchorEl={ anchorEl.current }
          state={ actionState }
          handleChange={ handleActionChange }
          handleClose={ _ => setOpen(false) }
          isForPast={ false }
          hasPeriodSelect
        />

        <Typography variant="h4">{ graphData.bookedNum } booked</Typography>
        <p className={ classes.p }><span>Confirmed appointments</span> <span>{ graphData.confirmedNum }</span></p>
        <p className={ classes.p }><span>Cancelled appointments</span> <span>{ graphData.cancelledNum }</span></p>

        <ResponsiveContainer height={graphHeight} width="95%">
          <BarChart data={ graphData.processedData }>
            <XAxis
              stroke="#777"
              dataKey="date"
              interval={ actionState.period === 7 ? 0 : 2 }
              angle={ -45 }
              textAnchor="end"
              axisLine={false}
              tickFormatter={ (tick) => {
                const date = new Date(tick);
                return `${getShortDay(date)} ${date.getDate()}`
              } }
            />
            <YAxis stroke="#777" axisLine={false} allowDecimals={false} />
            <CartesianGrid stroke="#eef0f2"/>
            <Tooltip
              cursor={false}
              formatter={ (value, name) => [ value, capitalizeFirst(name) ] }
              labelFormatter={ (label) => moment(label).format('DD/MM/YYYY') }
              contentStyle={{
                backgroundColor: 'rgba(57, 67, 74, .7)',
                color: 'white',
                padding: '1px 20px',
              }}
              itemStyle={{ color: 'white' }}
            />
            <Legend
              wrapperStyle={{ top: graphHeight }}
              formatter={ (value) => capitalizeFirst(value) }
            />
            <Bar
              dataKey="confirmed"
              fill="#2883d2"
              stackId="a"
            />
            <Bar
              dataKey="cancelled"
              fill="#f74969"
              stackId="a"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
};

export default UpcomingAppointmentsCard;
