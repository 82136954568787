import React, { useState, useEffect , useContext } from 'react';
import authService from '../utils/authService';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import { capitalizeFirst, effectFetch } from '../utils/helpers';
import { Errors } from '../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '1.5rem'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    '& > div > *': {
      backgroundColor: 'white',
      marginTop: 0,
      marginBottom: 0
    },
    '& > * > * + *': {
      marginLeft: '1rem',
    }
  },
  filterButton: {
    backgroundColor: 'white'
  },
  blacklistButton: {
    backgroundColor: 'black',
    color: 'white'
  },
  checkbox: {
    color: 'green',
    '&$checked': {
      color: 'green'
    }
  },
  drawerPaper: {
    width: '13rem',
    textAlign: 'center',
    padding: '1rem',
    '& > * + *': {
      marginTop: '1rem'
    }
  },
}));

const availableGenders = [
  'All', 'Male', 'Female', 'Other'
];

const Clients = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [availableBarbers, setAvailableBarbers] = useState([]);

  const [selectedSaloon, setSelectedSaloon] = useState({ id: 0, name: 'All', index: 0 });
  const [selectedBarber, setSelectedBarber] = useState({ id: 0, name: 'All', index: 0 });
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [gender, setGender] = useState(availableGenders[0]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [updateTrigger, setUpdateTrigger] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const [customers, setCustomers] = useState([]);

  // Fetch availableSaloons
  useEffect(() => {
    let toReturn;
    if(userType === 'super-admin') {
      toReturn = effectFetch(
        `/api/super-admin/saloons/all?limit=100`,
        json => {
          const avSaloons = (json.data || [])
            .map((a, i) => ({
              name: a.name,
              id: a.saloon_id,
              index: i + 1
            }));

          setAvailableSaloons([ { index: 0, id: 0, name: 'All' }, ...avSaloons ]);
        },
        err => console.log(err)
      );
    }

    return toReturn;
  }, [ userType ]);

  // Fetch availableBarbers
  useEffect(() => {
    if(userType !== 'barber') {
      setSelectedBarber({ id: 0, name: 'All', index: 0 });
      return effectFetch(
        `/api/${userType}/barbers/all?saloon=${selectedSaloon.id}&limit=100`,
        json => {
          const avBarbers = (json.data || []).map((a, i) => ({
            name: a.name,
            id: a.id,
            index: i + 1
          }));

          setAvailableBarbers([ { index: 0, id: 0, name: 'All' }, ...avBarbers ]);
        },
        err => console.log(err)
      );
    }
  }, [ selectedSaloon, userType ]);

  const fetchCustomers = () => {
    const barberQuery = (selectedBarber.name !== 'All') ? `&barber=${selectedBarber.id}` : '';
    const saloonQuery = (selectedSaloon.name !== 'All') ? `&saloon=${selectedSaloon.id}` : '';
    const genderQuery = (gender !== 'All') ? `&gender=${gender}` : '';
    const search = (searchValue !== '') ? `&searchTerm=${searchValue}` : '';
    const url = `/api/${userType}/customers?limit=20&page=${page + 1}`
      + barberQuery
      + saloonQuery
      + search
      + genderQuery.toLowerCase();

    return effectFetch(
      url,
      json => setCustomers(json.data || []),
      err => console.log(err)
    );
  };

  // Fetch customers
  useEffect(fetchCustomers, [ selectedBarber, selectedSaloon, page, updateTrigger, gender ]);

  // Reset page number
  useEffect(() => setPage(0), [ selectedBarber, selectedSaloon, searchValue, gender ]);

  const handleChangePage = (event, newPage) => {
    if(customers.length === 20 || newPage < page) {
      setPage(newPage);
    }
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Enter') {
      fetchCustomers();
    }
  };

  const handleClose = () => setSelectedCustomer(null);

  const handleToggleBlacklist = () => {
    authService.authFetch(`/api/${userType}/customers/toggle-blacklist`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customer: selectedCustomer.id })
    }).then(() => {
      setUpdateTrigger(!updateTrigger);
    }).catch(err => setErrors([err]));

    handleClose();
  };

  return (
    <div className={ classes.root }>
      <Dialog
        open={ Boolean(selectedCustomer) }
        onClose={handleClose}
      >
        <DialogTitle>
          Are you sure you want to 
          { (selectedCustomer?.is_blacklisted) ? ' whitelist ' : ' blacklist ' }
          this client?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={ handleClose }
          >
            Cancel
          </Button>
          <Button
            className={ classes.blacklistButton }
            onClick={ handleToggleBlacklist }
            variant="contained"
          >
            { (selectedCustomer?.is_blacklisted) ? 'Whitelist' : 'Blacklist' }
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
        open={ isDrawerOpen }
        onClose={ _ => setIsDrawerOpen(false) }
      >
        <div>
          <Typography variant="h5">Filters</Typography>
        </div>
        <Divider />

        <Select
          margin="dense"
          variant="outlined"
          native
          value={ gender }
          onChange={ e => {
            setGender(e.target.value);
            setIsDrawerOpen(false);
          }}
        >
          {
            availableGenders.map((a, i) => (
              <option key={i} value={ a }>{ a }</option>
            ))
          }
        </Select>
      </Drawer>

      <div className={ classes.toolbar }>
        <div>
          <TextField
            label="Search"
            margin="dense"
            variant="outlined"
            onChange={ e => setSearchValue(e.target.value) }
            onKeyDown={ handleKeyDown }
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="search-icon"
                  onClick={ _ => {
                    setPage(0);
                    fetchCustomers();
                  }}
                  edge="end"
                  style={{ padding: 0 }}
                >
                  <SearchIcon />
                </IconButton>
              )
            }}
          />

          { userType === 'super-admin' && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                disableUnderline
                native
                value={ selectedSaloon.index }
                onChange={ e => setSelectedSaloon(availableSaloons[e.target.value]) }
                inputProps={{
                  name: 'saloon',
                  id: 'saloon',
                }}
              >
                {
                  availableSaloons.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}

          { userType !== 'barber' && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                disableUnderline
                native
                value={ selectedBarber.index }
                onChange={ e => setSelectedBarber(availableBarbers[e.target.value]) }
                inputProps={{
                  name: 'barber',
                  id: 'barber',
                }}
              >
                {
                  availableBarbers.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}
        </div>

        <Button
          className={ classes.filterButton }
          onClick={ _ => setIsDrawerOpen(true) }
          align="right"
          variant="contained"
          endIcon={ <FilterListIcon /> }
        >
          Filters
        </Button>
      </div>

      <Card>
        <CardContent>
          <TableContainer component="paper">
            <Table>
              <TableHead>
                <TableRow>
                  {
                    [
                      'Name',
                      'Mobile number',
                      'Email',
                      'Gender',
                      'Blacklisted',
                    ].map(a => (
                      <TableCell
                        key={a}
                      >
                        { a }
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  customers.map((a, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        { a.name }
                      </TableCell>
                      <TableCell>
                        { a.phone_number }
                      </TableCell>
                      <TableCell>
                        { a.email }
                      </TableCell>
                      <TableCell>
                        { capitalizeFirst(a.gender) }
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={ a.is_blacklisted }
                          disabled={ userType === 'barber' }
                          style={{ color: userType !== 'barber' && 'black' }}
                          onChange={ _ => setSelectedCustomer(a) }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[ 20 ]}
              component="div"
              count={ (customers.length === 20) ? (-1) : (20 * page + customers.length) }
              rowsPerPage={20}
              page={ page }
              onChangePage={ handleChangePage }
              nextIconButtonProps={{ disabled: customers.length < 20 }}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  )
};

export default Clients;
