import React, { useState, useEffect, useContext } from 'react';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Errors } from '../../App.js';

const button = {
  width: '45%',
  borderRadius: '2px',
  margin: '.5rem',
  padding: '.5rem'
};

const useStyles = makeStyles(() => ({
  popover: {
    padding: '1rem',
    width: '23rem',
  },
  select: {
    width: '100%',
    margin: '1rem 0',
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
    ...button
  },
  secondary: {
    backgroundColor: 'white',
    color: 'black',
    ...button
  }
}));

const availableTimePeriods = [ 7, 30 ];
const placeholderLocationName = 'N/A';

const CardAction = (props) => {
  const { setErrors } = useContext(Errors);
  const {
    anchorEl,
    handleClose,
    handleChange,
    state,
    userType,
    isForPast,
    hasPeriodSelect
  } = props;
  const classes = useStyles();
  const [availableLocations, setAvailableLocations] = useState([]);
  const [location, setLocation] = useState(state.location);
  const [period, setPeriod] = useState(state.period);

  useEffect(() => {
    if(userType !== 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        json.data = (json.data || []);
        const arr = [];
        let i = 0;

        if(location.name !== placeholderLocationName || json.data.length == 0) {
          arr.push({ ...location, index: i++ });
        }

        arr.push(
          ...json.data.map(a => ({
            name: a.name,
            id: a.saloon_id,
            index: i++
          }))
        );

        setAvailableLocations(arr);

        if(location.name === placeholderLocationName) {
          setLocation(arr[0]);
          handleChange(null, arr[0]);
        }
      },
      err => console.log(err)
    );
  }, [ userType ]);

  return (
      <Popover
        classes={{ paper: classes.popover }}
        open={state.open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        { userType === 'super-admin' && (
          <>
            <InputLabel htmlFor="location">Selected Locations</InputLabel>
            <Select
              className={ classes.select }
              native
              value={ location?.index }
              onChange={ e => setLocation(availableLocations[e.target.value]) }
              inputProps={{
                name: 'location',
                id: 'location',
              }}
            >
              {
                availableLocations.map((a, i) => (
                  <option key={i} value={ i }>{ a.name }</option>
                ))
              }
            </Select>
          </>
        )}

        { hasPeriodSelect &&
          <>
            <InputLabel htmlFor="period">Time Period</InputLabel>
            <Select
              className={ classes.select }
              native
              value={ period }
              onChange={ e => setPeriod(e.target.value) }
              inputProps={{
                name: 'period',
                id: 'period',
              }}
            >
              {
                availableTimePeriods.map((a, i) => (
                  <option key={i} value={ a }>{ isForPast ? 'Last' : 'Next' } { a } days</option>
                ))
              }
            </Select>
            <br/>
          </>
        }

        <Button
          variant="contained"
          className={ classes.secondary }
          onClick={ handleClose }
        >
          Close
        </Button>
        <Button
          variant="contained"
          className={ classes.primary }
          onClick={ _ => handleChange(period, location) }
        >
          Apply Changes
        </Button>
      </Popover>
  )
};

export default CardAction;
