import React, { useEffect, useState , useContext, useRef, useCallback } from 'react';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../utils/authService';
import { formatDuration, effectFetch } from '../utils/helpers';
import NewServiceDialog from './services/NewServiceDialog';
import ServiceInfoDialog from './services/ServiceInfoDialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import useCurrency from '../utils/useCurrency';
import { Errors, Success } from '../App.js';
import InfiniteScroll from '../utils/InfiniteScroll'

const useStyles = makeStyles(() => ({
  search: {
    margin: '0'
  },
  table: {
    margin: '1rem auto 3rem auto',
    maxWidth: '1024px',
    border: '1px solid #e2e6ea'
  },
  tableRow: {
    background: 'white',
    borderLeft: '5px #a5dff8 solid',
    cursor: 'pointer'
  },
  cell: {
    fontSize: '1rem',
    fontWeight: '500'
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    borderRadius: '4px',
    padding: '.5rem 1.5rem',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  toolbar: {
    margin: '0 auto',
    marginTop: '2rem',
    maxWidth: '1024px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div > div': {
      backgroundColor: 'white',
    },
    '& > div > div + div': {
      marginLeft: '1rem'
    },
  },
}));

const limit = 20;
const Services = ({ userType }) => {
  const loader = useRef(null);
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const { formatCurrency, toBaseCurrency } = useCurrency();
  const classes = useStyles();

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [availableBarbers, setAvailableBarbers] = useState([]);

  const [selectedSaloon, setSelectedSaloon] = useState({ id: 0, name: 'All', index: 0 });
  const [selectedBarber, setSelectedBarber] = useState({ id: 0, name: 'All', index: 0 });
  const [searchValue, setSearchValue] = useState('');
  const [selectedService, setSelectedService] = useState(null);

  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const observerCallback = () => {
    if (page * limit === services.length) {
      setPage(pr => pr + 1);
    }
  }

  // Fetch availableSaloons
  useEffect(() => {
    let toReturn;

    if(userType === 'super-admin') {
      toReturn = effectFetch(
        `/api/super-admin/saloons/all?limit=100`,
        json => {
          const avSaloons = (json.data || [])
            .map((a, i) => ({
              name: a.name,
              id: a.saloon_id,
              index: i + 1
            }));

          setAvailableSaloons([ { index: 0, id: 0, name: 'All' }, ...avSaloons ]);
        },
        err => console.log(err)
      );
    }

    return toReturn;
  }, [ userType ]);

  // Fetch availableBarbers
  useEffect(() => {
    setSelectedBarber({ id: 0, name: 'All', index: 0 });
    let toReturn;

    if(userType !== 'barber') {
      toReturn = effectFetch(
        `/api/${userType}/barbers/all?saloon=${selectedSaloon.id}&limit=100`,
        json => {
          const avBarbers = (json.data || []).map((a, i) => ({
            name: a.name,
            id: a.id,
            index: i + 1
          }));

          setAvailableBarbers([ { index: 0, id: 0, name: 'All' }, ...avBarbers ]);
        },
        err => console.log(err)
      );
    }

    return toReturn;
  }, [ selectedSaloon, userType ]);

  const fetchServices = (shouldClearPreviousData) => {
    setLoading(true);
    const barberQuery = (selectedBarber.id !== 0) ? `barber=${selectedBarber.id}&` : '';
    const saloonQuery = (selectedSaloon.id !== 0) ? `saloon=${selectedSaloon.id}`  : '';

    const url = {
      barber: '/api/barber/services/self?',
      admin: '/api/admin/services?' + barberQuery,
      'super-admin': '/api/super-admin/services?' + barberQuery + saloonQuery
    }[userType] + `&searchTerm=${searchValue}&limit=${limit}&page=${page}`;

    return effectFetch(
      url,
      json => {
        const data = json.data || [];

        if(shouldClearPreviousData) {
          setServices(data);
          setPage(1);
        } else {
          setServices(pr => [ ...pr, ...data ]);
        }
        setLoading(false);
      },
      err => console.log(err)
    );
  }

  // Fetch services
  useEffect(fetchServices, [ selectedSaloon, selectedBarber, updateTrigger, page ]);

  // Reset services
  useEffect(() => {
    setServices([]);
    setPage(1)
  }, [ selectedSaloon, selectedBarber, updateTrigger ]);

  const submitHandler = (hasNewService, service) => {
    if(hasNewService) {
      // Post new service
      authService.authFetch('/api/admin/services/new', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(service)
      }).then(_ => {
        setSuccess({ message: 'Service created successfully.' });
        setUpdateTrigger(!updateTrigger);
      }).catch(err => setErrors([err]));
    }

    setIsDialogOpen(false);
  };

  const handleKeyDown = (event) => {
    if(event.key === 'Enter') {
      fetchServices(true);
    }
  };

  return (
    <div>
      <ServiceInfoDialog
        formatCurrency={ formatCurrency }
        toBaseCurrency={ toBaseCurrency }
        userType={ userType }
        open={ Boolean(selectedService) }
        handleClose={ _ => setSelectedService(null) }
        service={ selectedService }
        triggerUpdate={ _ => setUpdateTrigger(a => !a) }
      />

      <div className={ classes.toolbar }>
        <div>
          <TextField
            classes={{ root: classes.search }}
            label="Search"
            margin="dense"
            variant="outlined"
            onChange={ e => setSearchValue(e.target.value) }
            onKeyDown={ handleKeyDown }
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="search-icon"
                  onClick={ _ => {
                    fetchServices(true);
                  }}
                  edge="end"
                  style={{ padding: 0 }}
                >
                  <SearchIcon />
                </IconButton>
              )
            }}
          />

          { (userType === 'super-admin' && availableSaloons.length > 0) && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                native
                value={ selectedSaloon.index }
                onChange={ e => setSelectedSaloon(availableSaloons[e.target.value]) }
                inputProps={{
                  name: 'saloon',
                  id: 'saloon',
                }}
              >
                {
                  availableSaloons.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}

          { (userType !== 'barber' && availableBarbers.length > 0) && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                disableUnderline
                native
                value={ selectedBarber.index }
                onChange={ e => setSelectedBarber(availableBarbers[e.target.value]) }
                inputProps={{
                  name: 'barber',
                  id: 'barber',
                }}
              >
                {
                  availableBarbers.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}
        </div>


        { userType === 'admin' &&
        <>
          <Button
            className={ classes.primary }
            onClick={ _ => setIsDialogOpen(true) }
            variant="contained"
            color="primary"
          >
            Add New
          </Button>

          <NewServiceDialog
            isDialogOpen={ isDialogOpen }
            handleSubmit={ submitHandler }
            toBaseCurrency={ toBaseCurrency }
          />
        </>
        }
      </div>

      <Table className={ classes.table }>
        <TableBody>
          {
            services.map((a, i) => (
              <TableRow
                key={i}
                className={ classes.tableRow }i
                onClick={ _ => setSelectedService(a) }
                hover
              >
                <TableCell className={ classes.cell }>
                  { a.title }
                </TableCell>
                <TableCell className={ classes.cell } style={{ color: '#939dad' }}>
                  { formatDuration(a.duration + a.extra_duration) }
                </TableCell>
                <TableCell className={ classes.cell }>
                  { formatCurrency(a.price) }
                </TableCell>
              </TableRow>
            ))
          }

          { services.length <= 0 && <h3>None</h3> }
        </TableBody>
      </Table>

      <InfiniteScroll onEndReached={ observerCallback } isLoading={ loading } />
    </div>
  )
};

export default Services;
