import React, { useState, useEffect, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '560px',
    overflow: 'auto'
  },
  tableHeader: {
    color: '#939dad'
  },
}));

const TopStaffCard = ({ userType, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [topStaff, setTopStaff] = useState([]);

  // Fetch data
  useEffect(() => {
    return effectFetch(
      `/api/${userType}/barbers/top?limit=7&page=1`,
      json => setTopStaff(json.data || []),
      err => console.log(err)
    );
  }, [ userType ]);

  return (
    <Card className={ classes.card }>
      <CardHeader
        title="Top Staff"
      />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={ classes.tableHeader }
              >
                Staff
              </TableCell>
              <TableCell
                className={ classes.tableHeader }
                align="right"
              >
                This Month
              </TableCell>
              <TableCell
                className={ classes.tableHeader }
                align="right"
              >
                Last Month
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              topStaff.map((a, i) => (
                <TableRow key={i}>
                  <TableCell>
                    { a.name }
                  </TableCell>
                  <TableCell align="right">
                    { formatCurrency(a.this_month) }
                  </TableCell>
                  <TableCell align="right">
                    { formatCurrency(a.last_month) }
                  </TableCell>
                </TableRow>
              ))
            }

            { topStaff.length <= 0 && <h3>None</h3> }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
};

export default TopStaffCard;
