import { useState, useEffect } from 'react';
import currencyFormatter from 'currency-formatter';
import authService from './authService';
import { backupFetch } from './helpers';

const baseCurrency = 'EUR';

const useCurrency = () => {
  const [rate, setRate] = useState(1);
  const [currency, setCurrency] = useState(baseCurrency);

  useEffect(() => {
    const fetchRate = async () => {
      try {
        const data = await backupFetch([
          `http://data.fixer.io/api/latest?access_key=${process.env.REACT_APP_FIXER_KEY}`,
          `http://api.exchangeratesapi.io/v1/latest?access_key=${process.env.REACT_APP_EXCHANGE_RATES_KEY}`
        ]);
        setRate(data.rates[cur]);
      } catch(err) {
        setCurrency(baseCurrency);
        setRate(1);
        console.log(err)
      }
    };

    const cur = authService.getToken().currency;
    setCurrency(cur);

    if(cur !== baseCurrency) {
      fetchRate();
    }
  }, []);


  const formatCurrency = (amount) => {
    return currencyFormatter.format(amount * rate, { code: currency });
  };

  const toTargetCurrency = (amount) => {
    return amount * rate;
  };
  const toBaseCurrency = (amount) => {
    return amount / rate;
  };

  return { formatCurrency, toBaseCurrency , toTargetCurrency };
};

export default useCurrency;
