import React, { useState, useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import './App.css';
import AuthRoute from './utils/AuthRoute';
import RedirectToUserHome from './utils/RedirectToUserHome';
import Dashboard from './staffSide/Dashboard';
import Login from './staffSide/Login';
import Signup from './staffSide/Signup';
import EmailVerification from './staffSide/EmailVerification';
import PasswordReset from './staffSide/PasswordReset';
import PhoneVerification from './staffSide/PhoneVerification';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#3c485c',
      main: '#1a263a',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      contrastText: 'black',
    },
  },
});

export const Errors = React.createContext({
    errors: [],
    setErrors: () => {}
});

export const Success = React.createContext({
    success: [],
    setSuccess: () => {}
});

const userTypes = [ 'barber', 'admin', 'super-admin' ];

function App() {
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(null);
  const value = { errors, setErrors};

  const handleClose = () => setErrors(errors.slice(1, errors.length));

  return (
    <ThemeProvider theme={theme}>
      <Success.Provider value={{ success, setSuccess }}>
        <Errors.Provider value={ value }>
          <Router>
            <Switch>
              <AuthRoute
                path='/super-admin/signup'
                userType='super-admin'
                authed={false}
                render={ (props) => (
                  <Signup {...props} />
                ) }
              />

              <Route path={ `/password-reset/:token` }>
                <PasswordReset />
              </Route>

              {
                userTypes.map((a) => (
                  [
                    <Route path={ `/${a}/confirmation/:token/:usePassword` }>
                      <EmailVerification userType={a} />
                    </Route>,

                    <AuthRoute
                      authed={true}
                      userType={a}
                      path={ `/${a}/phone-number-confirmation` }
                      render={ props => (<PhoneVerification {...props} userType={a} />)}
                    />
                    ,

                    <AuthRoute
                      userType={ a }
                      path={ `/${a}/login` }
                      authed={false}
                      render={ (props) => (
                        <Login {...props} userType={ a } />
                      )}
                    />,

                    <AuthRoute
                      userType={ a }
                      path={ "/" + a }
                      authed={true}
                      render={ (props) => (
                        <Dashboard {...props} userType={ a } />
                      )}
                    />,
                  ]
                ))
              }

              <Route path='*'>
                <RedirectToUserHome />
              </Route>
            </Switch>
          </Router>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={errors.length > 0}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} variant="filled" severity="error">
              {errors[0]?.message}
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={success}
            autoHideDuration={6000}
            onClose={_ => setSuccess(null)}
          >
            <Alert onClose={_ => setSuccess(null)} variant="filled" severity="success">
              {success?.message}
            </Alert>
          </Snackbar>
        </Errors.Provider>
      </Success.Provider>
    </ThemeProvider>
  );
}

export default App;
