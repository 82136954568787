import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  drawer: {
    width: '100%',
    margin: '1rem 2rem',
    maxWidth: '35rem',
    '& ul > li': {
      margin: 0
    },
  },
  selectContainer: {
    marginBottom: '1.5rem'
  },
  white: {
    background: 'white'
  },
  primary: {
    padding: '1rem auto 1rem auto',
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
}));

const TaxDialog = ({
  defaultValue,
  taxes,
  open,
  closeHandler,
  updateHandler,
}) => {
  const classes = useStyles();
  const [selectedTaxes, setSelectedTaxes] = useState(defaultValue || {});

  return (
    <Dialog
      classes={{ paper: classes.drawer }}
      open={ open }
      onClose={ closeHandler }
    >
      <DialogTitle>Change Tax</DialogTitle>
      <DialogContent className={ classes.content }>
        <DialogContentText>
          Choose Tax Rate applicable in locations
        </DialogContentText>
        {
          taxes.map((saloon, i) => (
            <div key={i} className={ classes.selectContainer }>
              <InputLabel htmlFor={ saloon.saloon }>{ saloon.saloon }</InputLabel>
              <Select
                native
                fullWidth
                margin="dense"
                margin="dense"
                value={ selectedTaxes[saloon.saloon_id] }
                variant="outlined"
                onChange={ e => setSelectedTaxes({ ...selectedTaxes, [saloon.saloon_id]: e.target.value }) }
                inputProps={{
                  className: classes.inputPadding,
                  name: saloon.saloon,
                  id: saloon.saloon,
                }}
              >
                {
                  (saloon.taxes || []).map((tax, j) => (
                    <option key={j} value={ tax.id }>{tax.name} ({tax.rate}%)</option>
                  ))
                }
              </Select>
            </div>
          ))
        }
      </DialogContent>
      <DialogActions>
        <Button
          className={ classes.white }
          variant="contained"
          onClick={ closeHandler }
        >
          Cancel
        </Button>
        <Button
          className={ classes.primary }
          variant="contained"
          onClick={ _ => updateHandler(selectedTaxes) }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default TaxDialog;
