import React, { useState, useEffect, useContext } from 'react';
import DatePicker from '../calendar/DatePicker';
import moment from 'moment';
import FilterDrawer from '../inventory/FilterDrawer';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import authService from '../../utils/authService';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import AnalyticsCard from './AnalyticsCard';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { splitNCapitalize, formatDuration, effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '1.5rem',
  },
  cardContainer: {
    margin: '1.5rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '& > *': {
      flexBasis: '15%',
      flex: 1,

      '& + *': {
        marginLeft: '1rem'
      }
    },
  },
  graph: {
    margin: '1.5rem 0',
    paddingBottom: '3rem'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button': {
      backgroundColor: 'white',
      padding: '.5rem 1.5rem',
      '& + button': {
        marginLeft: '.5rem',
      }
    }
  }
}));

const DashboardTab = ({ userType, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [date, setDate] = useState(moment());
  const [data, setData] = useState(null);
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [selectedSaloon, setSelectedSaloon] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const saloonQuery = selectedSaloon ? `saloon=${selectedSaloon}` : '';

    return effectFetch(
      `/api/${userType}/analytics/all?${saloonQuery}&date=${date.format('YYYY-MM-DD')}`,
      json => setData(json.data),
      err => console.log(err)
    );
  }, [ userType, date, selectedSaloon ]);

  // Fetch availableSaloons
  useEffect(() => {
    let toReturn;

    if(userType === 'super-admin') {
      toReturn = effectFetch(
        `/api/super-admin/saloons/all?limit=100`,
        json => {
          const avSaloons = (json.data || [])
            .map((a, i) => ({
              name: a.name,
              id: a.saloon_id,
              index: i + 1
            }));

          avSaloons.unshift({ name: 'All', id: 0, index: 0 });
          setAvailableSaloons(avSaloons);

          if(avSaloons.length > 0) {
            setSelectedSaloon(avSaloons[0].id);
          }
        },
        err => console.log(err)
      );
    }

    return toReturn;
  }, [ userType ]);

  return (
    <div className={ classes.container }>
      <FilterDrawer
        userType={ userType }
        availableOptions={{ saloon: availableSaloons }}
        handleChange={ filters => {
          if(filters !== null) {
            setSelectedSaloon(filters.saloon);
          }

          setIsDrawerOpen(false);
        }}
        open={ isDrawerOpen }
      />

      <div className={ classes.toolbar }>
        <DatePicker
          type="1day"
          dates={{ start: date, end: date }}
          onChange={ dates => setDate(dates.start) }
        />

        {
          userType === 'super-admin' &&
            <Button
              onClick={ _ => setIsDrawerOpen(true) }
              align="right"
              variant="contained"
              endIcon={ <FilterListIcon /> }
            >
              Filters
            </Button>
        }
      </div>
      { data &&
      <div className={ classes.cardContainer }>
        <AnalyticsCard title="Appointments" data={ data.appointments } formatCurrency={ formatCurrency } />
        <AnalyticsCard
          title="Occupancy"
          formatCurrency={ formatCurrency }
          data={{
            ...data.workingHours,
            working_hours: formatDuration(data.workingHours.working_hours),
            booked_hours: formatDuration(data.workingHours.booked_hours),
            unbooked_hours: formatDuration(data.workingHours.unbooked_hours),
            total: Math.round(data.workingHours.booked_hours_percentage) + '%'
          }}
        />
        <AnalyticsCard title="Total Sales" data={ data.totalSales } formatCurrency={ formatCurrency } />
        <AnalyticsCard title="Average Sale" data={ data.averageSale } formatCurrency={ formatCurrency } />
        <AnalyticsCard title="Client Retention"
          formatCurrency={ formatCurrency }
          data={{
            ...data.customerRetention,
            total: Math.round(data.customerRetention.returning_customers_percentage) + '%'
          }}
        />
      </div>
      }
      { data &&
        <>
          <Card className={ classes.graph }>
            <CardHeader title="Total Appointments" />
            <ResponsiveContainer height={300} width="95%">
              <LineChart data={ data.appointmentsGraph }>
                <XAxis
                  tickFormatter={ (tick) => moment(tick).format('ddd D') }
                  stroke="#777"
                  dataKey="date"
                  axisLine={false}
                  angle={ -45 }
                  textAnchor="end"
                />
                <YAxis
                  stroke="#777"
                  allowDecimals={false}
                  axisLine={false}
                />
                <CartesianGrid stroke="#eef0f2"/>
                <Tooltip
                  formatter={ (value, name) => [ value, splitNCapitalize(name) ] }
                  contentStyle={{
                    backgroundColor: 'rgba(57, 67, 74, .7)',
                    color: 'white',
                    padding: '1px 20px',
                  }}
                  itemStyle={{ color: 'white' }}
                />
                <Legend
                  formatter={ (value) => splitNCapitalize(value) }
                  wrapperStyle={{ top: 300 }}
                />
                <Line
                  dataKey="total"
                  stroke="#0bc2b0"
                  strokeWidth="2"
                  dot={{ fill: "#0bc2b0", stroke: "#0bc2b0" }}
                  activeDot={{ fill: "white", stroke: "#0bc2b0" }}
                />
                <Line
                  dataKey="walk_in"
                  stroke="#f74969"
                  strokeWidth="2"
                  dot={{ fill: "#f74969", stroke: "#f74969" }}
                  activeDot={{ fill: "white", stroke: "#f74969" }}
                />
                <Line
                  dataKey="online"
                  stroke="#2b72bd"
                  strokeWidth="2"
                  dot={{ fill: "#2b72bd", stroke: "#2b72bd" }}
                  activeDot={{ fill: "white", stroke: "#2b72bd" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
          <Card className={ classes.graph }>
            <CardHeader title="Total Sales" />
            <ResponsiveContainer height={300} width="95%">
              <LineChart
                data={ data.salesGraph }
                margin={{ top: 0, right: 0, left: 40, bottom: 0 }}
              >
                <XAxis
                  tickFormatter={ (tick) => moment(tick).format('ddd D') }
                  stroke="#777"
                  dataKey="date"
                  axisLine={false}
                  angle={ -45 }
                  textAnchor="end"
                />
                <YAxis
                  stroke="#777"
                  axisLine={false}
                  tickFormatter={ (tick) => formatCurrency(tick) }
                />
                <CartesianGrid stroke="#eef0f2"/>
                <Tooltip
                  formatter={ (value, name) => [ formatCurrency(value), splitNCapitalize(name) ] }
                  contentStyle={{
                    backgroundColor: 'rgba(57, 67, 74, .7)',
                    color: 'white',
                    padding: '1px 20px',
                  }}
                  itemStyle={{ color: 'white' }}
                />
                <Legend
                  formatter={ (value) => splitNCapitalize(value) }
                  wrapperStyle={{ top: 300 }}
                />
                <Line
                  dataKey="appointments"
                  stroke="#0bc2b0"
                  strokeWidth="2"
                  dot={{ fill: "#0bc2b0", stroke: "#0bc2b0" }}
                  activeDot={{ fill: "white", stroke: "#0bc2b0" }}
                />
                <Line
                  dataKey="sales"
                  stroke="#2b72bd"
                  strokeWidth="2"
                  dot={{ fill: "#2b72bd", stroke: "#2b72bd" }}
                  activeDot={{ fill: "white", stroke: "#2b72bd" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </>
      }
    </div>
  )
};

export default DashboardTab;
