import React, { useState, useEffect , useContext } from 'react';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterDrawer from './FilterDrawer';
import NewProductDialog from './NewProductDialog';
import { Errors } from '../../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '1.5rem'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    '& > div > button': {
      padding: '.5rem 1.5rem'
    },
    '& > div > *': {
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: 'white',
      '& + button': {
        marginLeft: '1rem',
        backgroundColor: '#1a263a',
        color: 'white',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.6)'
        }
      }
    },
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(1)': {
      color: '#368dff',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}));

const ListView = ({ userType, onSelectProduct, formatCurrency, toBaseCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [availableOptions, setAvailableOptions] = useState({
    saloon:   [{ id: 0, name: 'All', index: 0 }],
    brand:    [{ id: 0, name: 'All', index: 0 }],
    supplier: [{ id: 0, name: 'All', index: 0 }],
    category: [{ id: 0, name: 'All', index: 0 }],
    tax:      [{ id: 0, name: 'All', index: 0, rate: 0 }],
  });
  const [filters, setFilters] = useState({
    saloon:   0,
    brand:    0,
    supplier: 0,
    category: 0,
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [updateTrigger, setUpdateTrigger] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState([]);

  // Fetch all available options
  useEffect(() => {
    Object.keys(availableOptions).forEach(option => {
      let pluralFilter = option + 's';

      // Correct plural for category
      if (option === 'category') {
        pluralFilter = 'categories';
      }
      // Correct plural for taxes
      if (option === 'tax') {
        pluralFilter = 'payments/taxes/all';
      } else {
        pluralFilter += '/all';
      }

      // Don't check saloons for non super-admins
      if(option === 'saloon' && userType !== 'super-admin') {
        return;
      }

      return effectFetch(
        `/api/${userType}/${pluralFilter}?limit=100`,
        json => {
          let arr;

          if(option === 'tax') {
            arr = (json.data || []).map(saloon => {
              saloon.taxes = saloon.taxes.map((tax, i) => {
                tax = {
                  ...tax,
                  id: tax.tax_id,
                  index: i + 1,
                  name: tax.tax_name,
                  rate: tax.tax_rate
                };

                return tax;
              });

              saloon.taxes.unshift({ name: 'No tax', id: 0, index: 0, rate: 0 });
              return saloon;
            });
          } else {
            arr = (json.data || []).map((a, i) => {
              const obj = {
                name: a.name,
                id: a[option + '_id'],
                index: i + 1
              };

              return obj;
            });

            arr.unshift({ name: 'All', id: 0, index: 0 });
          }

          setAvailableOptions(pr => ({
            ...pr,
            [option]: arr
          }));
        },
        err => console.log(err)
      );

    });
  }, [ userType, updateTrigger ]);

  const fetchProducts = () => {
    const filterQuery = Object.keys(filters).reduce((pr, cur) => {
      const val = filters[cur];

      return pr + ((val) ? `&${cur}=${val}` : '');
    }, '');
    const search = (searchValue !== '') ? `&searchTerm=${searchValue}` : '';

    const url = `/api/${userType}/products/all?limit=20&page=${page + 1}`
      + filterQuery
      + search;

    return effectFetch(
      url,
      json => setProducts(json.data || []),
      err => console.log(err)
    );

  };

  // Fetch products
  useEffect(fetchProducts, [ filters, page, updateTrigger ]);

  // Reset page number
  useEffect(() => setPage(0), [ filters, searchValue ]);

  const handleChangePage = (event, newPage) => {
    if(products.length === 20 || newPage < page) {
      setPage(newPage);
    }
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Enter') {
      fetchProducts();
    }
  };

  const submitHandler = (hasNewProduct, newProduct) => {
    if(hasNewProduct) {
      // Post new product
      authService.authFetch(`/api/${userType}/products/new`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newProduct)
      }).then(res => {
        setUpdateTrigger(!updateTrigger);
      }).catch(err => setErrors([err]));
    }

    setIsDialogOpen(false);
  };

  return (
    <div className={ classes.root }>
      <FilterDrawer
        userType={ userType }
        // Get availableOptions without tax
        availableOptions={ (() => {
          const temp = { ...availableOptions };
          delete temp.tax;

          return temp;
        })() }
        handleChange={ filters => {
          if(filters !== null) {
            setFilters(filters);
          }

          setIsDrawerOpen(false);
        }}
        open={ isDrawerOpen }
      />

      <NewProductDialog
        isNew={ true }
        open={ isDialogOpen }
        handleSubmit={ submitHandler }
        availableOptions={{
          brand: availableOptions.brand,
          category: availableOptions.category,
          tax: availableOptions.tax,
        }}
        setUpdateTrigger={ setUpdateTrigger }
        userType={ userType }
        toBaseCurrency={ toBaseCurrency }
      />

      <div className={ classes.toolbar }>
        <TextField
          label="Search"
          margin="dense"
          variant="outlined"
          onChange={ e => setSearchValue(e.target.value) }
          onKeyDown={ handleKeyDown }
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="search-icon"
                onClick={ _ => {
                  setPage(0);
                  fetchProducts();
                }}
                edge="end"
                style={{ padding: 0 }}
              >
                <SearchIcon />
              </IconButton>
            )
          }}
        />

        <div>
          <Button
            onClick={ _ => setIsDrawerOpen(true) }
            align="right"
            variant="contained"
            endIcon={ <FilterListIcon /> }
          >
            Filters
          </Button>

          <Button
            onClick={ _ => setIsDialogOpen(true) }
            align="right"
            variant="contained"
          >
            New Product
          </Button>
        </div>
      </div>

      <Card>
        <CardContent>
          <TableContainer component="paper">
            <Table>
              <TableHead>
                <TableRow>
                  {
                    [
                      'Product name',
                      'Price',
                      'Category',
                      'Brand',
                      'Stock Level',
                    ].map(a => (
                      <TableCell
                        key={a}
                      >
                        { a }
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  products.map((a, i) => (
                    <TableRow
                      className={ classes.row }
                      key={i}
                      onClick={ _ => onSelectProduct(a) }
                    >
                      <TableCell>
                        { a.title }
                      </TableCell>
                      <TableCell>
                        {
                          a.special_price ? (
                            <>
                              <div
                                style={{
                                  color: 'red',
                                  textDecoration: 'line-through'
                                }}
                              >
                                { formatCurrency(a.retail_price) }
                              </div>
                              <div>{ formatCurrency(a.special_price) }</div>
                            </>
                          ) : (
                            <>{ formatCurrency(a.retail_price) }</>
                          )
                        }
                      </TableCell>
                      <TableCell>
                        { a.category }
                      </TableCell>
                      <TableCell>
                        { a.brand }
                      </TableCell>
                      <TableCell>
                        { a.stock_level }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[ 20 ]}
              component="div"
              count={ (products.length === 20) ? (-1) : (20 * page + products.length) }
              rowsPerPage={20}
              page={ page }
              onChangePage={ handleChangePage }
              nextIconButtonProps={{ disabled: products.length < 20 }}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  )
};

export default ListView;
