import React, { useContext } from 'react';
import useForm from '../utils/useForm';
import authService from '../utils/authService';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Errors, Success } from '../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',

    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  paper: {
    margin: 'auto',
    marginTop: '10rem',
    maxWidth: '30rem',
    padding: theme.spacing(2),

    '& .MuiPaper-root': {
      backgroundColor: '#eee'
    }
  },
  button: {
    backgroundColor: 'white',
    margin: '.5rem',
    color: 'black',
  }
}));

const PhoneVerification = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const classes = useStyles();
  const [values, handleChange] = useForm({
    phone_number: '',
    token: '',
  });

  const handleSubmit = e => {
    e.preventDefault();

    authService.authFetch(`/api/admin/sms-token/verify`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...values })
    })
      .then(data => data.json())
      .then(json => {
        if(json.ok || json.success) {
          window.location.replace(`/${userType}/home`);
        } else {
          setErrors([{ message: json }]);
        }
      })
      .catch((err) => setErrors([err]));
  }

  const handleResendToken = _ => {
    authService.authFetch(`/api/admin/sms-token/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(data => data.json())
      .then(json => {
        if(json.ok || json.success) {
          setSuccess({ message: "SMS sent successfully, check your phone" });
        } else {
          setErrors([{ message: json }]);
        }
      })
      .catch((err) => setErrors([err]));
  }

  return (
    <div className={ classes.root }>
      <Paper className={ classes.paper } variant="outlined">
        <Typography variant="h2" gutterBottom>
          Phone verification
        </Typography>

        <form onSubmit={ handleSubmit }>
          <TextField
            label="Phone Number"
            id="phone_number"
            name="phone_number"
            type="text"
            variant="filled"
            value={ values.phone_number }
            onChange={ handleChange }
          />
          <br />

          <TextField
            label="Token"
            id="token"
            name="token"
            type="text"
            variant="filled"
            value={ values.token }
            onChange={ handleChange }
          />
          <br/>

          <Button
            size="large"
            variant="contained"
            onClick={ handleResendToken }
            className={ classes.button }
          >
            Resend Token
          </Button>

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Done
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export default PhoneVerification;
