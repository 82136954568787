import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Layout from './general/Layout';
import {
  Route,
  Redirect,
  useRouteMatch,
  Switch
} from 'react-router-dom';
import Home from './Home';
import Calendar from './Calendar';
import Appointments from './Appointments';
import Sales from './Sales';
import Clients from './Clients';
import Staff from './Staff';
import Services from './Services';
import Inventory from './Inventory';
import Analytics from './Analytics';
import Setup from './Setup';
import Profile from './Profile';

class LocalizedMomentUtils extends MomentUtils {
  constructor(a) { super(a) }

  getWeekArray(date) {
    return super.getWeekArray(this.moment(date.format('YYYY-MM-DD')));
  }
}

const Dashboard = ({ userType }) => {
  const { path } = useRouteMatch();

  const routes = [
    { path: `${path}/home`,         component: <Home         userType={ userType } /> },
    { path: `${path}/calendar`,     component: <Calendar     userType={ userType } /> },
    { path: `${path}/appointments`, component: <Appointments userType={ userType } /> },
    { path: `${path}/sales`,        component: <Sales        userType={ userType } /> },
    { path: `${path}/clients`,      component: <Clients      userType={ userType } /> },
    { path: `${path}/staff`,        component: <Staff        userType={ userType } /> },
    { path: `${path}/services`,     component: <Services     userType={ userType } /> },
    { path: `${path}/inventory`,    component: <Inventory    userType={ userType } />, hide: userType === 'barber' },
    { path: `${path}/analytics`,    component: <Analytics    userType={ userType } />, hide: userType === 'barber' },
    { path: `${path}/setup`,        component: <Setup        userType={ userType } /> },
    { path: `${path}/profile`,      component: <Profile      userType={ userType } /> },
  ];

  return (
    <Layout userType={ userType }>
      <MuiPickersUtilsProvider utils={LocalizedMomentUtils}>
        <Switch>
          {
            routes.filter(a => !a.hide).map((route, i) => (
              <Route path={ route.path } key={ i }>
                { route.component }
              </Route>
            ))
          }

          <Route path='*'>
            <Redirect to={ `/${userType || 'barber'}/home` } />
          </Route>
        </Switch>
      </MuiPickersUtilsProvider>
    </Layout>
  )
};

export default Dashboard;
