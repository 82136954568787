import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import authService from '../../../utils/authService';
import { effectFetch } from '../../../utils/helpers';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EditDialog from './EditDialog';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { Errors } from '../../../App.js';

const useStyles = makeStyles(() => ({
  select: {
    background: 'white',
    margin: '1rem'
  },
  table: {
    marginTop: '1rem',

    '& td, & th': {
      width: '12%'
    }
  },
  holiday: {
    borderRadius: '5px',
    backgroundColor: '#eef0f2'
  },
  cell: {
    borderRadius: '5px',
    cursor: 'pointer'
  },
  emptyCell: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee'
    }
  }
}));


const Grid = ({ userType, date, saloon }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [workingHours, setWorkingHours] = useState([]);
  const [selectedWH, setSelectedWH] = useState({ wh: null, date: null });
  const [updateTrigger, setUpdateTrigger] = useState(true);

  // Fetch data
  useEffect(() => {
    const query = {
      barber: 'profile/working-hours?includePending=true&',
      admin: 'barbers/working-hours?includePending=true&',
      'super-admin': `barbers/working-hours?saloon=${saloon.id}&includePending=true&`,
    }[userType] + 'date=' + moment(date).format('YYYY-MM-DD') + '&limit=100';

    return effectFetch(
      `/api/${userType}/${query}`,
      json => setWorkingHours(json.data.name ? [json.data] : json.data),
      err => console.log(err)
    );
  }, [ userType, date, saloon, updateTrigger ]);

  const handleClick = (obj, barberId, date) => {
    if(userType === 'barber' && (obj.status && obj.status !== 'normal')) {
      return;
    }

    setSelectedWH({ wh: obj, date, barberId });
  };

  const handleClose = () => {
    setSelectedWH({ wh: null, date: null });
  };

  return (
    <Card className={ classes.table }>
      <CardContent>
        <Table>
          <EditDialog
            setUpdateTrigger={ setUpdateTrigger }
            handleClose
            workingHours={ selectedWH.wh }
            date={ selectedWH.date }
            barberId={ selectedWH.barberId }
            userType={ userType }
            handleClose={ handleClose }
          />

          <TableHead>
            <TableRow>
              <TableCell>Change Staff</TableCell>
              {
                new Array(7).fill(0)
                  .map((_, i) => (
                    <TableCell key={i}>
                      { moment(date).startOf('week').add(i, 'day').format('ddd D MMM') }
                    </TableCell>
                  ))
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {
              workingHours?.map((a, j) => (
                <TableRow key={j}>
                  <TableCell>
                    { a.name }
                  </TableCell>
                  {
                    (() => {
                      let arr = [];
                      for(let i = 0; i < 7; i ++) {
                        const curDate = moment(date).startOf('week').add(i, 'day');
                        const emptyCell = (
                          <TableCell
                            className={ classes.emptyCell }
                            key={i}
                            onClick={ _ => handleClick(a[i], a.barberId, curDate) }
                          />
                        );

                        if(a[i].isOnHoliday) {
                          arr.push(
                            <TableCell
                              key={i}
                              className={ classes.holiday }
                            />
                          );
                          continue;
                        }

                        if(!a[i]?.status) {
                          arr.push(emptyCell);
                          continue;
                        }

                        switch(a[i].status) {
                          case 'normal':
                            if(
                              a[i]?.working_hours.start_time ===
                                a[i]?.working_hours.end_time
                            ) {
                              arr.push(emptyCell);
                              break;
                            }
                            arr.push(
                              <TableCell
                                className={ classes.cell }
                                key={i}
                                style={{ backgroundColor: '#a5dff8' }}
                                onClick={ _ => handleClick(a[i], a.barberId, curDate) }
                              >
                                { a[i]?.working_hours.start_time } - { a[i]?.working_hours.end_time}
                              </TableCell>
                            );
                            break;
                          case 'edited':
                          case 'added':
                            arr.push(
                              <TableCell
                                className={ classes.cell }
                                key={i}
                                style={{ backgroundColor: '#f5a623' }}
                                onClick={ _ => handleClick(a[i]) }
                              >
                                { a[i]?.to.start_time } - { a[i]?.to.end_time}
                              </TableCell>
                            );
                            break;
                          case 'deleted':
                            arr.push(
                              <TableCell
                                className={ classes.cell }
                                style={{ backgroundColor: '#f33155' }}
                                onClick={ _ => handleClick(a[i]) }
                              >
                                { a[i]?.from?.start_time } - { a[i]?.from?.end_time}
                              </TableCell>
                            );
                            break;
                          default:
                            arr.push(emptyCell);
                        }
                      }

                      return arr;
                    })()
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default Grid;
