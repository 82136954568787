import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

export const Header = ({ label, date }) => {
  const isToday = moment().isSame(moment(date), 'day');
  const isPast = moment().isAfter(moment(date), 'day');

  const colors = {
    past: '#6f7e92',
    presentHeading: '#f7f7f8',
    present: '#037aff',
    future: 'black',
  };

  const style = {
    backgroundColor: '#f7f7f8',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors[(isToday) ? 'present' : (isPast) ? 'past' : 'future']
  };

  const dateStyle = {
    borderRadius: '500px',
    marginRight: '.25rem',
    backgroundColor: (isToday) ? '#037aff' : 'transparent',
    color: colors[(isToday) ? 'presentHeading' : (isPast) ? 'past' : 'future'],
    height: '2.5rem',
    width: '2.5rem',
    paddingTop: '.25rem'
  };

  return (
    <div style={{ margin: '0 -3px' }}>
      <div style={ style }>
        <Typography variant="h5" style={ dateStyle }>{ label.split(' ')[0] }</Typography>
        <Typography variant="body1">{ label.split(' ')[1] }</Typography>
      </div>
    </div>
  );
};
