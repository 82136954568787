import React, { useEffect , useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import useForm from '../../utils/useForm';
import { Errors, Success } from '../../App.js';

const ChangePhoneCard = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const [formData, setFormData, setFormDataInit] = useForm({
    phoneNumber: '',
    password: '',
  });

  useEffect(() => {
    const userT = (userType === 'super-admin') ? 'admin' : userType;

    return effectFetch(
      `/api/${userT}/profile`,
      json => setFormDataInit(pr => ({ ...(json.data || pr), password: '' })),
      err => console.log(err)
    );

  }, [ userType ]);

  const handleSubmit = () => {
    const userT = (userType === 'super-admin') ? 'admin' : userType;

    authService.authFetch(`/api/${userT}/profile/new-phone`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        newPhone: formData.phoneNumber,
        password: formData.password
      })
    })
      .then(_ => {
        setSuccess({ message: 'Phone updated, check for an otp' });
        window.location.reload();
      })
      .catch(err => setErrors([err]))
      .finally(() => {
        // Clear password field
        const e = {
          target: {
            name: 'password',
              value: ''
          }
        };

        setFormData(e);
      });
  };

  return (
    <div>
      <div>
        <h3>Change Phone Number</h3>

        To make an update, enter your new phone number followed by your password.
      </div>

      <Card>
        <CardContent>
          <TextField
            label="Phone"
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            value={ formData.phoneNumber }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            value={ formData.password }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />

          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={ handleSubmit }
          >
            Save
          </Button>
        </CardContent>
      </Card>
    </div>
  )
};

export default ChangePhoneCard;
