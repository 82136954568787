import React, { useEffect , useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import useForm from '../../utils/useForm';
import { Errors, Success } from '../../App.js';

const initialFormData = {
  password: '',
  newPassword: '',
  verifyPassword: ''
};

const ChangePasswordCard = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const [formData, setFormData, setFormDataInit] = useForm(initialFormData);

  useEffect(() => {
    const userT = (userType === 'super-admin') ? 'admin' : userType;

    return effectFetch(
      `/api/${userT}/profile`,
      json => setFormDataInit(pr => ({
        ...(json.data || pr),
        ...initialFormData
      })),
      err => console.log(err)
    );

  }, [ userType ]);

  const handleSubmit = () => {
    if(formData.newPassword !== formData.verifyPassword) {
      setErrors([{ message: 'The 2 passwords don\'t match' }])
      return;
    }

    const userT = (userType === 'super-admin') ? 'admin' : userType;

    authService.authFetch(`/api/${userT}/profile/new-password`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        newPassword: formData.newPassword,
        oldPassword: formData.password,
        email: formData.email,
        phone_number: formData.phoneNumber
      })
    })
      .then(_ => {
        setSuccess({ message: 'Password updated successfully' });
      })
      .catch(err => setErrors([err]))
      .finally(() => setFormDataInit({ ...formData, ...initialFormData }));
  };

  return (
    <div>
      <div>
        <h3>Change Password</h3>

        To make an update, enter your password followed by your new password.
      </div>

      <Card>
        <CardContent>
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            value={ formData.password }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />
          <TextField
            label="New Password"
            id="newPassword"
            name="newPassword"
            type="password"
            value={ formData.newPassword }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />

          <TextField
            label="Verify Password"
            id="verifyPassword"
            name="verifyPassword"
            type="password"
            value={ formData.verifyPassword }
            onChange={ setFormData }
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          />
          <br />

          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={ handleSubmit }
          >
            Save
          </Button>
        </CardContent>
      </Card>
    </div>
  )
};

export default ChangePasswordCard;
