import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import authService from './authService';

const RedirectToUserHome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  (async () => {
    // Call the is Authenticated method to get a new access token if there is
    // none. Then we store as isAuthed whether the token exists or not.
    await authService.isAuthenticated('admin')
    setIsAuthed(Boolean(authService.getToken()));
    setIsLoading(false);
  })();

  if(isLoading) {
    return ( <>Loading...</> );
  } else {
    if(isAuthed) {
      // If is authed then go to home page of correct user type
      const user = authService.getToken();
      let userType = user.type;

      if(user.isSuperAdmin) {
        userType = 'super-admin';
      }

      return ( <Redirect to={ `/${userType}/home` } /> );
    } else {
      // If not authed go to barber login
      return ( <Redirect to='/barber/login' /> );
    }
  }
};

export default RedirectToUserHome;
