import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loadable from './Loadable';

const useStyles = makeStyles(() => ({
  space: {
    marginBottom: '3rem',
  },
  loader: {
    height: 1,
    width: 1,
    margin: 10
  }
}));

const InfiniteScroll = ({ isLoading, onEndReached }) => {
  const loader = useRef(null);
  const classes = useStyles();
  const [prevY, setPrevY] = useState(0);

  const observerCallback = (entities) => {
    const y = entities[0].boundingClientRect.y;

    if (prevY > y) {
      onEndReached();
    }
    setPrevY(y);
  }

  // Create the observer
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });

    if(loader.current) observer.observe(loader.current);

    return () => observer.disconnect()
  }, [loader, observerCallback])

  return (
    <>
      <Loadable isLoading={ isLoading }>
        <div className={ classes.loader } ref={ loader }></div>
      </Loadable>
      <div className={ classes.space } />
    </>
  );
};

export default InfiniteScroll;
