import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  appbar: {
    backgroundColor: 'white',
    color: '#101928',
    boxShadow: '0px 1px 0 #dddddd',

    // Tooltip
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      // Div containing button and avatar
      '& > div': {
        display: 'flex',

        // Avatar
        '& img': {
          cursor: 'pointer',
        }
      }
    },
  },
  heading: {
    fontSize: '1.7rem',
  },
  button: {
    marginRight: '1rem',
  },
  avatar: {
    height: 44,
    width: 44,
    cursor: 'pointer'
  },
  verifyButton: {
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  }
}));

const MyAppBar = ({ heading, userType }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const history = useHistory();
  const [userData, setUserData] = useState({ name: '', avatar: { image_data: '', image_type: '' }, phoneVerified: true, emailVerified: true });

  useEffect(() => {
    const userT = (userType === 'super-admin') ? 'admin' : userType;

    return effectFetch(
      `/api/${userT}/profile`,
      json => setUserData(pr => ({ ...json.data, phoneVerified: pr.phoneVerified, emailVerified: pr.emailVerified } || pr)),
      err => console.log(err)
    );

  }, [ userType ]);

  useEffect(() => {
    const func = async () => {
      const { phoneVerified, emailVerified } = await authService.getToken();
      setUserData(pr => ({ ...pr, phoneVerified, emailVerified }));
    };

    func();
  }, [])

  const handleLogout = async () => {
    try {
      await authService.logout();
      history.push('/');
    } catch(err) {
      setErrors([err]);
    }
  };

  return (
    <AppBar position="static" className={ classes.appbar }>
      <Toolbar>
        <h2 className={ classes.heading }>{ heading }</h2>

        <div>
          { !userData.phoneVerified && userType === 'super-admin' &&
            <Button
              className={ classes.verifyButton }
              onClick={ () => history.push(`/${userType}/phone-number-confirmation`) }
            >
              Verify Phone Number
            </Button>
          }
          <Button
            className={ classes.button }
            onClick={ handleLogout }
          >
            Logout
          </Button>

          <Badge
            badgeContent="!"
            color="error"
            invisible={userData.emailVerified && (userData.phoneVerified || userType !== 'super-admin')}
          >
            <Avatar
              className={ classes.avatar }
              alt={ userData.name }
              src={ `data:${userData.avatar.image_type};base64, ${userData.avatar.image_data}` }
              onClick={ () => history.push(`/${userType}/profile`) }
            />
          </Badge>
        </div>
      </Toolbar>
    </AppBar>
  )
};

export default MyAppBar;
