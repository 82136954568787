import React, { useEffect } from 'react';
import clsx from "clsx";
import moment from 'moment';
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import authService from '../../utils/authService';
import { changeWeekStart } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  input: {
    textAlign: 'center',
    padding: '.6rem .5rem',
    borderLeft: '1px solid #ced4da',
    borderRight: '1px solid #ced4da',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapper: {
    borderRadius: '4px',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    display: 'inline-block'
  },
  chevron: {
    padding: '.4rem .5rem',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: '1rem',
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: '#8595ac',
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: '#101928',
    color: 'white',
  },
  firstHighlight: {
    background: '#101928',
    color: 'white',
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    background: '#101928',
    color: 'white',
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

const getLastDaysNum = (type) => parseInt(type.split('_')[1]);

const getStartAndEndDate = (startDate, endDate, type) => {
  let dates = {
    start: null,
    end: null
  };

  switch(type) {
    case 'week':
      dates.start = moment(startDate.format('YYYY-MM-DD')).clone().startOf('week');
      dates.end = moment(startDate.format('YYYY-MM-DD')).clone().endOf('week');
      break;

    case '3day':
    case '1day':
      dates.start = startDate.clone();
      dates.end = startDate.clone().add(type.charAt(0) - 1, 'day');
      break;

    default:
      dates.start = startDate.clone();
      dates.end = startDate.clone();
      break;
  }

  return dates;
};

const CustomDatePicker = ({ dates, type, onChange }) => {
  const classes = useStyles();

  const handleChange = (startDate, endDate) => {
    const theDates = getStartAndEndDate(startDate, endDate, type);

    onChange({ start: theDates.start, end: theDates.end });
  };

  // Update moment locale
  useEffect(() => {
    const { weekStart } = authService.getToken();
    changeWeekStart(weekStart);
  }, []);

  // Everytime the type changes recalculate start and end dates
  useEffect(() => {
    handleChange(dates.start || moment(), dates.end);
  }, [ type ]);

  const handleButtonClick = direction => {
    switch(direction) {
      case 'forward':
        handleChange(dates.end.add(1, 'day'));
        break;

      case 'backward':
        switch(type) {
          case 'week':
            handleChange(dates.start.subtract(1, 'day'));
            break;

          case '3day':
          case '1day':
            handleChange(dates.start.subtract(type.charAt(0), 'day'));
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const formatSelectLabel = (date, invalidLabel) => {
    let firstDateFormat = '';

    if(!date.isSame(dates.end, 'day')) {
      firstDateFormat += 'D';
    }

    if(!date.isSame(dates.end, 'month')) {
      firstDateFormat += ' MMM';
    }

    if(!date.isSame(dates.end, 'year')) {
      firstDateFormat += ', yyyy';
    }

    return date && date.isValid()
      ? `${firstDateFormat ? date.format(firstDateFormat) + ' - ' : ''}${dates.end.format('D MMM, yyyy')}`
      : invalidLabel;
  };

  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const dayIsBetween = date.clone().isBetween(dates.start, dates.end);
    const isFirstDay = date.clone().isSame(dates.start, 'day');
    const isLastDay = date.clone().isSame(dates.end, 'day');

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween || isFirstDay || isLastDay,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && (dayIsBetween || isFirstDay || isLastDay),
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> { date.format('D') } </span>
        </IconButton>
      </div>
    );
  };

  return (
    <div className={ classes.wrapper }>
      <IconButton
        className={ classes.chevron }
        onClick={ _ => handleButtonClick('backward') }
      >
        <ChevronLeftIcon />
      </IconButton>

      <DatePicker
        disableToolbar
        variant="inline"
        value={dates.start}
        onChange={handleChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatSelectLabel}
        InputProps={{
          disableUnderline: true,
          margin: 'dense',
          classes: { input: classes.input }
        }}
      />

      <IconButton
        className={ classes.chevron }
        onClick={ _ => handleButtonClick('forward') }
      >
        <ChevronRightIcon />
      </IconButton>
    </div>
  );
};

export default CustomDatePicker;
