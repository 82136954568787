import React, { useState, useEffect, useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PersonalDetailsCard from './profile/PersonalDetailsCard';
import ChangeEmailCard from './profile/ChangeEmailCard';
import ChangePhoneCard from './profile/ChangePhoneCard';
import ChangePasswordCard from './profile/ChangePasswordCard';
import DeleteAccountCard from './profile/DeleteAccountCard';
import authService from '../utils/authService';
import { Errors, Success } from '../App.js';

const useStyles = makeStyles(() => ({
  root: {
    margin: '1.5rem',

    '& > div + div': {
      margin: '2rem auto',
      width: '100%',
      maxWidth: '67rem',
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',

      // Left and right divs
      '& > div': {
        flexBasis: '40%',

        // Right divs
        '& + div': {
          flexBasis: '55%',
          marginLeft: '1rem',

          // Everything in right divs
          '& > div > *': {
            marginTop: '1rem',
            marginBottom: '1rem'
          },

          // Buttons in right divs
          '& button': {
            // width: '100%',
            // padding: '1rem auto 1rem auto',
            backgroundColor: '#1a263a',
            color: 'white',
            '&:hover': {
              backgroundColor: '#3c485c'
            },
          }
        }
      }
    }
  },
}));

const Profile = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const classes = useStyles();
  const [verified, setVerified] = useState({ mail: true, phone: true });

  useEffect(() => {
    const func = async () => {
      const { phoneVerified, emailVerified, type } = await authService.getToken();
      setVerified({ phone: phoneVerified, mail: emailVerified, type });
    };

    func();
  }, []);

  const handleResendEmail = _ => {
    authService.authFetch(`/api/email-token/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userEntity: verified.type })
    })
      .then(data => data.json())
      .then(json => {
        if(json.ok || json.success) {
          setSuccess({ message: "Email sent successfully, check your mail" });
        } else {
          setErrors([{ message: json }]);
        }
      })
      .catch((err) => setErrors([err]));
  }

  return (
    <div className={ classes.root }>
      <div>
        {
          !verified.mail &&
            <Alert
              action={
                <Button size="small" onClick={handleResendEmail}>Resend Verification Email</Button>
              }
              severity="error"
            >
              You haven't verified your email yet. If you don't confirm your email you won't be able to do much.
            </Alert>
        }
        <br/>

        {
          !verified.phone && userType === 'super-admin' &&
            <Alert severity="error">
              You haven't verified your phone-number yet. If you don't confirm your phone-number you won't be able to do much.
            </Alert>
        }
      </div>

      <PersonalDetailsCard userType={ userType } />

      <ChangeEmailCard userType={ userType } />

      <ChangePhoneCard userType={ userType } />

      <ChangePasswordCard userType={ userType } />

      <DeleteAccountCard userType={ userType } />
    </div>
  )
};

export default Profile;
