import React, { useState, useEffect, useContext } from 'react';
import authService from '../utils/authService';
import { effectFetch } from '../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import DatePicker from './calendar/DatePicker';
import CalendarComponent from './calendar/CalendarComponent';
import NewAppointmentDialog from './calendar/NewAppointmentDialog';
import moment from 'moment';
import StatusKeys from './calendar/StatusKeys';
import useCurrency from '../utils/useCurrency';
import { Errors } from '../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '1.5rem'
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  select: {
    margin: '1rem 0 1rem 1rem',
    borderRadius: '4px',
    padding: '.6rem 1.6rem',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: 'white'
    }
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    borderRadius: '4px',
    margin: '.5rem',
    padding: '.5rem 1.5rem',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
}));

const availablePeriods = [
  { index: 0, type: '1day', name: 'Day' },
  { index: 1, type: '3day', name: '3 Days' },
  { index: 2, type: 'week', name: 'Week' }
];

const Calendar = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { formatCurrency } = useCurrency();
  const classes = useStyles();
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [availableBarbers, setAvailableBarbers] = useState([]);

  const [selectedSaloon, setSelectedSaloon] = useState({ id: 0, name: '', index: 0 });
  const [selectedBarber, setSelectedBarber] = useState({ id: 0, name: '', index: 0 });

  const [dates, setDates] = useState({ start: moment(), end: moment() });
  const [period, setPeriod] = useState(availablePeriods[2]);

  const [newAppointmentTime, setNewAppointmentTime] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  // Fetch availableSaloons
  useEffect(() => {
    if(userType !== 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        const avSaloons = (json.data || [])
          .map((a, i) => ({
            name: a.name,
            id: a.saloon_id,
            index: i
          }));

        setAvailableSaloons(avSaloons);

        if(avSaloons.length > 0) {
          setSelectedSaloon(avSaloons[0]);
        }
      },
      err => console.log(err)
    );
  }, [ userType ]);

  // Fetch availableBarbers
  useEffect(() => {
    if(userType === 'barber') {
      return;
    }

    return effectFetch(
      `/api/${userType}/barbers/all?saloon=${selectedSaloon.id}&limit=100`,
      json => {
        const avBarbers = (json.data || []).map((a, i) => ({
          name: a.name,
          id: a.id,
          index: i
        }));

        setAvailableBarbers(avBarbers);

        if(avBarbers.length > 0) {
          setSelectedBarber(avBarbers[0]);
        }
      },
      err => console.log(err)
    );
  }, [ selectedSaloon, userType ]);

  const handleNewWalkIn = (slotInfo) => {
    setNewAppointmentTime(slotInfo.start);
  };

  const submitHandler = (hasNewAppointment, appointment) => {
    if(hasNewAppointment) {
      // Post new walkin appointment
      authService.authFetch('/api/barber/appointments/new/walk-in', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(appointment)
      }).then(res => {
        setUpdateTrigger(!updateTrigger);
        res.json();
      }).catch(err => setErrors([err]));
    }

    setNewAppointmentTime(null);
  };

  return (
    <div className={ classes.root }>
      <div className={ classes.toolbar }>
        <div>
          { (userType === 'super-admin' && availableSaloons.length > 0) && (
            <>
              <Select
                classes={{ root: classes.select }}
                disableUnderline
                native
                value={ selectedSaloon.index }
                onChange={ e => setSelectedSaloon(availableSaloons[e.target.value]) }
                inputProps={{
                  name: 'saloon',
                  id: 'saloon',
                }}
              >
                {
                  availableSaloons.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}

          { (userType !== 'barber' && availableBarbers.length > 0) && (
            <>
              <Select
                classes={{ root: classes.select }}
                disableUnderline
                native
                value={ selectedBarber.index }
                onChange={ e => setSelectedBarber(availableBarbers[e.target.value]) }
                inputProps={{
                  name: 'barber',
                  id: 'barber',
                }}
              >
                {
                  availableBarbers.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}
        </div>

        <DatePicker
          type={ period.type }
          dates={ dates }
          onChange={ setDates }
        />

        <div>
          <Select
            classes={{ root: classes.select }}
            disableUnderline
            native
            value={ period.index }
            onChange={ e => setPeriod(availablePeriods[e.target.value]) }
            inputProps={{
              name: 'period',
              id: 'period',
            }}
          >
            {
              availablePeriods.map((a, i) => (
                <option key={i} value={ i }>{ a.name }</option>
              ))
            }
          </Select>

          { userType === 'barber' &&
          <>
            <Button
              className={ classes.primary }
              onClick={ _ => handleNewWalkIn({ start: new Date() }) }
              variant="contained"
              color="primary"
            >
              Add New
            </Button>

            <NewAppointmentDialog
              defaultTime={ newAppointmentTime }
              handleSubmit={ submitHandler }
              formatCurrency={ formatCurrency }
            />
          </>
          }
        </div>
      </div>

      <StatusKeys
        confirmed
        completed
      />

      <CalendarComponent
        updateTrigger={ updateTrigger }
        handleSelect={ handleNewWalkIn }
        view={ period.type }
        dates={ dates }
        barber={ selectedBarber }
        userType={ userType }
      />
    </div>
  )
};

export default Calendar;
