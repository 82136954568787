import React, { useState, useEffect, useRef, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CardAction from './CardAction';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '560px',
    overflow: 'auto'
  },
  tableHeader: {
    color: '#939dad'
  },
}));

const TopServicesCard = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const anchorEl = useRef(null);
  const [topServices, setTopServices] = useState([]);
  const [actionState, setActionState] = useState({
    open: false,
    location: { name: 'N/A', id: -1, index: -1 }
  });

  const handleActionChange = (period, location) => {
    setActionState({
      open: false,
      location
    });
  };

  const setOpen = (open) => {
    setActionState({
      ...actionState,
      open
    });
  };

  // Fetch data
  useEffect(() => {
    const url = ( userType === 'super-admin' ?
      `/api/${userType}/services/top?saloon=${actionState?.location?.id}&limit=7&page=1` :
      `/api/${userType}/services/top?limit=7&page=1`
    );

    return effectFetch(
      url,
      json => setTopServices(json.data || []),
      err => console.log(err)
    );
  }, [ userType, actionState.location ]);

  return (
    <Card className={ classes.card }>
      <CardHeader
        action={
          userType === 'super-admin' && (
            <>
              <IconButton
                aria-label="settings"
                ref={ anchorEl }
                onClick={ _ => setOpen(!actionState.open) }
              >
                <MoreHorizIcon />
              </IconButton>
            </>
          )
        }
        title="Top Services"
        subheader={ actionState?.location?.name }
      />
      <CardContent>

        { userType === 'super-admin' &&
          <CardAction
            userType={ userType }
            anchorEl={ anchorEl.current }
            state={ actionState }
            handleChange={ handleActionChange }
            handleClose={ _ => setOpen(false) }
          />
        }

        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={ classes.tableHeader }
              >
                Service
              </TableCell>
              <TableCell
                className={ classes.tableHeader }
                align="right"
              >
                This Month
              </TableCell>
              <TableCell
                className={ classes.tableHeader }
                align="right"
              >
                Last Month
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              topServices.map((a, i) => (
                <TableRow key={i}>
                  <TableCell>
                    { a.title }
                  </TableCell>
                  <TableCell align="right">
                    { a.this_month }
                  </TableCell>
                  <TableCell align="right">
                    { a.last_month }
                  </TableCell>
                </TableRow>
              ))
            }

            { topServices.length <= 0 && <h3>None</h3> }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
};

export default TopServicesCard;
