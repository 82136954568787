import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { formatDuration, getShortDay } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  date: {
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  lightText: {
    color: '#8595ac'
  },
  statusText: {
    fontSize: '.65rem',
    fontWeight: 500,
    marginLeft: '.5rem'
  }
}));

const statusColors = {
  confirmed: '#2883d2',
  cancelled: '#f33155',
  pending: '#f5a623',
  completed: '#0bc2b0',
  rescheduled: '#b028d2'
};

const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

const AppointmentTableRow = ({ appointment, formatCurrency }) => {
  const classes = useStyles();
  const services = appointment.services.map(a => a?.name).join(', ') || 'Walk-In';
  const price = appointment.customer && formatCurrency(appointment.gross);

  const dateObj = new Date(appointment.time);
  const month   = months[dateObj.getMonth()];
  let   minutes = dateObj.getMinutes();
  let   hour    = dateObj.getHours();
  const date    = dateObj.getDate();
  const year    = dateObj.getFullYear();
  const weekday = getShortDay(dateObj);

  // Format nicely
  if(hour < 10) hour = '0' + hour;
  if(minutes < 10) minutes = '0' + minutes;

  const dateStr = `${weekday}, ${date} ${month} ${year} ${hour}:${minutes}`;
  const subHeader = `${appointment.customer || 'Walk-In'}, ${formatDuration(appointment.duration)} with ${appointment.barber.split(' ')[0]}`;

  const statusColorStyle = { color: statusColors[appointment.status] };

  return (
    <TableRow>
      <TableCell style={{ display: 'flex', padding: '.8rem' }}>
        <div style={{ marginRight: '1rem' }}>
          <div className={ classes.date }>{ dateObj.getDate() }</div>
          <div className={ classes.lightText }>{ month }</div>
        </div>
        <div>
          <span className={ classes.lightText }>{ dateStr }</span>
          <span className={ classes.statusText } style={ statusColorStyle }>
            { appointment.status.toUpperCase() }
          </span>
          <div><Typography variant="subtitle2">{ services }</Typography></div>
          <div className={ classes.lightText }>{ subHeader }</div>
        </div>
      </TableCell>
      <TableCell align="right">
        <Typography variant="subtitle2">{ price }</Typography>
      </TableCell>
    </TableRow>
  )
};

export default AppointmentTableRow;
