import React, { useState, useEffect, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './staff/TabPanel';
import DailySalesTab from './sales/DailySalesTab';
import InvoicesTab from './sales/InvoicesTab';
import authService from '../utils/authService';
import { effectFetch } from '../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import useCurrency from '../utils/useCurrency';
import { Errors } from '../App.js';

const useStyles = makeStyles(() => ({
  appbar: {
    background: 'white',
    marginBottom: '1.5rem',
    boxShadow: '0 4px 4px rgba(0, 0, 0, .2)',
    color: 'black'
  }
}));

const Sales = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { formatCurrency } = useCurrency();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [availableSaloons, setAvailableSaloons] = useState([
    { id: 0, name: 'All', index: 0 }
  ]);

  // Fetch all available saloons
  useEffect(() => {
    // Don't check saloons for non super-admins
    if(userType !== 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        const arr = (json.data || []).map((a, i) => ({
          name: a.name,
          id: a.saloon_id,
          index: i + 1
        }));

        arr.unshift({ name: 'All', id: 0, index: 0 });

        setAvailableSaloons(arr);
      },
      err => console.log(err)
    );

  }, [ userType ]);

  const handleChange = (_, newVal) => {
    setValue(newVal);
  }

  return (
    <>
      <AppBar position="static" className={ classes.appbar }>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Daily Sales" />
          <Tab label="Invoices" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DailySalesTab userType={ userType } availableSaloons={ availableSaloons } formatCurrency={ formatCurrency } />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InvoicesTab userType={ userType } availableSaloons={ availableSaloons } formatCurrency={ formatCurrency } />
      </TabPanel>
    </>
  );
};

export default Sales;
