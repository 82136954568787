import React, { useEffect, useState , useContext } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import NewTaxDialog from './NewTaxDialog';
import { Errors } from '../../App.js';
import InfiniteScroll from '../../utils/InfiniteScroll';

const useStyles = makeStyles(() => ({
  search: {
    margin: '0'
  },
  table: {
    margin: '1rem auto 3rem auto',
    maxWidth: '1024px',
  },
  tableRow: {
    background: 'white',
    borderLeft: '5px #dddddd solid',
    cursor: 'pointer'
  },
  cell: {
    fontSize: '1rem',
    fontWeight: '500'
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    borderRadius: '4px',
    padding: '.5rem 1.5rem',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  toolbar: {
    margin: '0 auto',
    marginTop: '2rem',
    maxWidth: '1024px',
    textAlign: 'right'
  },
}));

const limit = 20;

const Taxes = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [selectedTax, setSelectedTax] = useState(null);
  const [taxes, setTaxes] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const observerCallback = () => {
    if (page * limit === taxes.length) {
      setPage(pr => pr + 1);
    }
  }

  const fetchTaxes = () => {
    setLoading(true);

    return effectFetch(
      `/api/${userType}/payments/taxes/single?limit=${limit}&page=${page}`,
      json => {
        setTaxes(pr => [ ...pr, ...(json.data || []) ]);
        setLoading(false);
      },
      err => console.log(err)
    );
  }

  // Fetch taxes
  useEffect(fetchTaxes, [ updateTrigger, page ]);

  // Reset taxes
  useEffect(() => {
    setTaxes([]);
    setPage(1)
  }, [updateTrigger]);

  const submitHandler = (hasNewTax, tax) => {
    if(hasNewTax) {
      if(selectedTax) {
        // Edit tax
        authService.authFetch(`/api/${userType}/payments/taxes`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: selectedTax.tax_id, name: tax.name, rate: tax.rate })
        }).then(_ => {
          setUpdateTrigger(!updateTrigger);
        }).catch(err => setErrors([err]));
      } else {
        // Post new tax
        authService.authFetch(`/api/${userType}/payments/taxes/new`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(tax)
        }).then(_ => {
          setUpdateTrigger(!updateTrigger);
        }).catch(err => setErrors([err]));
      }
    }

    setIsDialogOpen(false);
    setSelectedTax(null);
  };

  const deleteHandler = () => {
    // Delete tax
    authService.authFetch(`/api/${userType}/payments/taxes`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: selectedTax.tax_id })
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
      setSelectedTax(null);
    }).catch(err => setErrors([err]));

    setIsDialogOpen(false);
  };

  return (
    <div>
      <div className={ classes.toolbar }>
        <Button
          className={ classes.primary }
          onClick={ _ => setIsDialogOpen(true) }
          variant="contained"
          color="primary"
        >
          New Tax
        </Button>

        <NewTaxDialog
          tax={ selectedTax }
          isDialogOpen={ isDialogOpen || selectedTax }
          handleSubmit={ submitHandler }
          handleDelete={ deleteHandler }
        />
      </div>

      <Table className={ classes.table }>
        <TableBody>
          {
            taxes.map((saloon) => (
              <>
                <h2>{ saloon.saloon }</h2>
                {
                  saloon.taxes.map((tax, j) => (
                    <TableRow
                      key={j}
                      className={ classes.tableRow }i
                      onClick={ _ => setSelectedTax(tax) }
                      hover
                    >
                      <TableCell className={ classes.cell }>
                        { tax.tax_name }
                      </TableCell>
                      <TableCell className={ classes.cell }>
                        { tax.tax_rate }%
                      </TableCell>
                    </TableRow>
                  ))
                }
              </>
            ))
          }

          { taxes.length <= 0 && <h3>None</h3> }
        </TableBody>
      </Table>
      < InfiniteScroll isLoading={ loading } onEndReached={ observerCallback } />
    </div>
  )
};

export default Taxes;
