import React, { useEffect, useState , useContext } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import InfiniteScroll from '../../utils/InfiniteScroll';
import { effectFetch } from '../../utils/helpers';
import NewStaffDialog from './membersTab/NewStaffDialog';
import FilterDrawer from '../inventory/FilterDrawer';
import { Errors, Success } from '../../App.js';

const useStyles = makeStyles(() => ({
  table: {
    margin: '1rem auto 3rem auto',
    maxWidth: '1024px',
    border: '1px solid #e2e6ea'
  },
  tableRow: {
    background: 'white',
    borderLeft: '5px #a5dff8 solid',
    cursor: 'pointer'
  },
  cell: {
    fontSize: '1rem',
    fontWeight: '500'
  },
  toolbar: {
    margin: '0 auto',
    marginTop: '2rem',
    maxWidth: '1024px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div > div': {
      backgroundColor: 'white',
    },
    '& > div > div + div': {
      marginLeft: '1rem'
    },
    '& button': {
      marginLeft: '1rem',
      backgroundColor: '#1a263a',
      color: 'white',
      borderRadius: '4px',
      padding: '.5rem 1.5rem',
      '&:hover': {
        backgroundColor: '#3c485c'
      },
    }
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
}));

const availableUserTypes = [
  { id: 0, index: 0, name: 'All' },
  { id: 1, index: 1, name: 'Super Admin' },
  { id: 2, index: 2, name: 'Admin' },
  { id: 3, index: 3, name: 'Barber' }
];

const limit = 20;

const MembersTab = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const classes = useStyles();

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [staff, setStaff] = useState([]);
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [filterSaloons, setFilterSaloons] = useState([]);
  const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    usertype: availableUserTypes[0].id,
    saloon: 0
  });
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleFilterChange = (filters) => {
    if(filters !== null) {
      setFilters(filters);
    }

    setIsFilterOpen(false);
  };

  const observerCallback = () => {
    if (page * limit === staff.length) {
      setPage(pr => pr + 1);
    }
  }

  // Fetch staff
  useEffect(() => {
    setLoading(true);
    let saloon = filters.saloon;
    saloon = saloon === 0 ? '' : `saloon=${filters.saloon}`;

    let userTypeQ = availableUserTypes[filters.usertype].name.toLowerCase().split(' ').join('-');
    userTypeQ = userTypeQ === 'all' ? '' : `userType=${userTypeQ}`;

    const query = {
      'super-admin': `${saloon}&${userTypeQ}`,
      'admin': `${userTypeQ}`,
      'barber': `${userTypeQ}`,
    }[userType] + `&limit=${limit}&page=${page}`;

    return effectFetch(
      `/api/${userType}/users/staff?${query}`,
      json => {
        setStaff(pr => [ ...pr, ...(json.data || []) ]);
        setLoading(false);
      },
      err => console.log(err)
    );
  }, [ userType, updateTrigger, filters.saloon, filters.usertype, page ]);

  // Reset staff
  useEffect(() => {
    setStaff([]);
    setPage(1)
  }, [ updateTrigger, filters.saloon, filters.usertype ]);

  // Fetch availableSaloons
  useEffect(() => {
    if(userType !== 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        let avSaloons = (json.data || [])
          .map((a, i) => ({
            name: a.name,
            id: a.saloon_id,
            index: i
          }));

        setAvailableSaloons(avSaloons);
        avSaloons = avSaloons.map(a => ({ ...a, index: a.index + 1 }));
        setFilterSaloons(avSaloons);
      },
      err => console.log(err)
    );
  }, [ userType ]);

  const newStaffHandler = (data) => {
    authService.authFetch(`/api/${userType}/${data.userType.toLowerCase()}s/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
      setIsNewDialogOpen(false);
    }).catch(err => setErrors([err]));
  };

  const editStaffHandler = (data) => {
    authService.authFetch(`/api/${userType}/${data.userType.toLowerCase()}s/edit`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
      setSelectedStaff(null)
    }).catch(err => setErrors([err]));
  };

  const handleDelete = (staff) => {
    let url = `/api/${userType}/`;

    if(userType === 'super-admin') {
      url += 'users/staff';
    } else {
      url += 'barbers'
    }

    authService.authFetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: staff.id, userType: staff.user_type, saloon: staff.saloon_id })
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
      setSuccess({ message: "Staff member deleted successfully"})
    }).catch(err => setErrors([err]));

    setIsNewDialogOpen(false);
  };


  return (
    <div>
      <FilterDrawer
        userType={ userType }
        availableOptions={{
          saloon: [ { id: 0, name: 'All' }, ...filterSaloons ],
          usertype: availableUserTypes
        }}
        handleChange={ handleFilterChange }
        open={ isFilterOpen }
      />

      { /* New staff dialog */ }
      <NewStaffDialog
        open={ Boolean(isNewDialogOpen) }
        handleSubmit={ newStaffHandler }
        handleClose={ _ => setIsNewDialogOpen(false) }
        userType={ userType }
        availableSaloons={ availableSaloons }
        isNew={ true }
      />

      { /* Edit staff dialog */ }
      <NewStaffDialog
        open={ Boolean(selectedStaff) }
        handleClose={ shouldUpdate => {
          setSelectedStaff(null);

          if (shouldUpdate === true) {
            setUpdateTrigger(!updateTrigger);
          }
        }}
        handleSubmit={ editStaffHandler }
        userType={ userType }
        availableSaloons={ selectedStaff?.saloon ? availableSaloons : [ {id: -1, index: -1, name: 'None'}, ...availableSaloons ] }
        isNew={ false }
        staff={ selectedStaff }
      />

      <div className={ classes.toolbar }>
        <Button
          onClick={ _ => setIsNewDialogOpen(true) }
          variant="contained"
        >
          New Staff
        </Button>

        <Button
          onClick={ _ => setIsFilterOpen(true) }
          align="right"
          variant="contained"
          endIcon={ <FilterListIcon /> }
        >
          Filters
        </Button>
      </div>

      <Table className={ classes.table }>
        <TableHead>
          <TableRow>
            {
              ['Name', 'Phone Number', 'Email', 'User Type', 'Location', ''].map((a, i) => (
                <TableCell key={i} >{ a }</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            staff
              .map((a, i) => (
              <TableRow
                key={i}
                className={ classes.tableRow }i
              >
                <>
                  <TableCell className={ classes.cell } onClick={ _ => setSelectedStaff(a) }>
                    { a.name }
                  </TableCell>
                  <TableCell className={ classes.cell } onClick={ _ => setSelectedStaff(a) }>
                    { a.phone_number }
                  </TableCell>
                  <TableCell className={ classes.cell } onClick={ _ => setSelectedStaff(a) }>
                    { a.email }
                  </TableCell>
                  <TableCell className={ classes.cell } onClick={ _ => setSelectedStaff(a) }>
                    { a.user_type.toUpperCase() }
                  </TableCell>
                  <TableCell className={ classes.cell } onClick={ _ => setSelectedStaff(a) }>
                    { a.saloon }
                  </TableCell>
                </>
                <TableCell className={ classes.cell }>
                  {
                    (a.user_type === 'super-admin') || (a.user_type === 'admin' && userType !== 'super-admin') ||
                      <Button
                        className={ classes.delete }
                        onClick={ _ => handleDelete(staff[i]) }>
                        Delete
                      </Button>
                  }
                </TableCell>
              </TableRow>
              ))
          }
        </TableBody>
      </Table>

      <InfiniteScroll onEndReached={ observerCallback } isLoading={ loading } />
    </div>
  )
};

export default MembersTab;
