import React, { useState, useEffect , useContext } from 'react';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import Select from '@material-ui/core/Select';
import Table from './holidayTab/Table';
import Button from '@material-ui/core/Button';
import NewHolidayDialog from './holidayTab/NewHolidayDialog';
import { makeStyles } from '@material-ui/core/styles';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  select: {
    background: 'white',
    margin: '1rem',
    marginBottom: 0,
    marginLeft: 0,
    marginTop: 0
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  wrapper: {
    padding: '1rem'
  },
  status: {
    color: 'white',
    borderRadius: '1rem',
    padding: '.25rem',
    width: '100%',
    textAlign: 'center'
  },
}));

const availableStatuses = [ 'All', 'Pending', 'Confirmed', 'Rejected' ];

const HolidayTab = ({ userType, onlySaloon }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [status, setStatus] = useState(availableStatuses[0]);
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [selectedSaloon, setSelectedSaloon] = useState({ id: null, name: '', index: 0 });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(true);

  // Fetch availableSaloons
  useEffect(() => {
    if(userType === 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        const avSaloons = (json.data || [])
          .map((a, i) => ({
            name: a.name,
            id: a.saloon_id,
            index: i
          }));

        setAvailableSaloons(avSaloons);

        if(avSaloons.length > 0) {
          setSelectedSaloon(avSaloons[0]);
        }
      },
      err => console.log(err)
    );
  }, [ userType ]);

  const submitHandler = (hasNewHoliday, holiday) => {
    if(hasNewHoliday) {
      if(onlySaloon && userType === 'super-admin') {
        holiday.saloon = selectedSaloon.id;
      }

      // Post new holiday
      authService.authFetch(`/api/${userType}/holidays/new`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(holiday)
      }).then(_ => {
        setUpdateTrigger(!updateTrigger);
      }).catch(err => setErrors([err]));
    }

    setIsDialogOpen(false);
  };

  return (
    <div className={ classes.wrapper }>
      <NewHolidayDialog
        open={ isDialogOpen }
        handleSubmit={ submitHandler }
      />

      { (userType === 'super-admin' && availableSaloons.length > 0) &&
        <>
          <Select
            className={ classes.select }
            margin="dense"
            variant="outlined"
            native
            value={ selectedSaloon.index }
            onChange={ e => setSelectedSaloon(availableSaloons[e.target.value]) }
            inputProps={{
              name: 'saloon',
              id: 'saloon',
            }}
          >
            {
              availableSaloons.map((a, i) => (
                <option key={i} value={ i }>{ a.name }</option>
              ))
            }
          </Select>
        </>
      }

      { !onlySaloon &&
        <Select
          className={ classes.select }
          margin="dense"
          variant="outlined"
          native
          value={ status }
          onChange={ e => setStatus(e.target.value) }
          inputProps={{
            name: 'status',
              id: 'status',
          }}
        >
            {
              availableStatuses.map((a, i) => (
                <option key={i} value={ a }>{ a }</option>
              ))
          }
        </Select>
      }

      {
        (
          (userType === 'barber' && !onlySaloon) ||
          (userType !== 'barber' && onlySaloon)
        ) &&
          <Button
            className={ classes.primary }
            onClick={ _ => setIsDialogOpen(true) }
            variant="contained"
            color="primary"
          >
            Add New
          </Button>
      }

      <Table
        onlySaloon={ onlySaloon }
        updateTrigger={ updateTrigger }
        setUpdateTrigger={ setUpdateTrigger }
        userType={ userType }
        status={ status }
        saloon={ selectedSaloon }
      />
    </div>
  );
};

export default HolidayTab;
