import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Marker from './Marker';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Errors } from '../../App.js';
import GoogleMapReact from 'google-map-react';

const useStyles = makeStyles(_ => ({
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
    '& > div > div > span': {
      borderLeft: '5px solid black',
      paddingLeft: '.5rem'
    },
    '& > div + div': {
      borderTop: '1px solid #ccc',
    },
    '& > div > div': {
      marginBottom: '1.5rem'
    },
    '& .button-container': {
      display: 'flex',

      '& button + button': {
        marginLeft: '1rem',
        padding: '.5rem',
        fontSize: '1rem',
      }
    },
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    borderRadius: '4px',
    padding: '.5rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
}));

const NewSaloonDialog = ({
  saloons,
  handleSubmit,
  handleClose,
}) => {
  const classes = useStyles();
  const { setErrors } = useContext(Errors);
  const [saloon, setSaloon] = useState({ name: '', location: null, address: '' });
  const [disabled, setDisabled] = useState(false);

  const handleLocationChange = async (address) => {
    const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=" + process.env.REACT_APP_MAPS_KEY);
    const json = await response.json();
    setSaloon(pr => ({
      ...pr,
      location: [
        json.results[0]?.geometry?.location?.lat,
        json.results[0]?.geometry?.location?.lng
      ]
    }));

    if(json.results.length === 0) {
      setErrors([{ message: 'Invalid address' }]);
    }
  }

  return (
    <Card className={ classes.card }>
      <CardHeader
        title={'New Saloon: ' + saloon.name}
      />
      <CardContent>
        <TextField
          label="Name"
          id={ saloon.saloon_id + '-name' }
          name="name"
          value={ saloon.name }
          onChange={ e => setSaloon(pr => ({ ...pr,  name: e.target.value })) }
          fullWidth
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label="Address"
          id={ saloon.saloon_id + '-address' }
          name="address"
          value={ saloon.address }
          onChange={ e => setSaloon(pr => ({ ...pr, address: e.target.value })) }
          onBlur={e => { handleLocationChange(e.target.value); setDisabled(false); }}
          onFocus={_ => setDisabled(true)}
          fullWidth
          variant="outlined"
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div style={{height: '400px'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
            center={ saloon.location?.every(a => !isNaN(a)) ? saloon.location : [52, -1] }
            defaultZoom={14}
          >
            {
              [ saloon, ...saloons ].filter(a => a.location && a.location.every(b => !isNaN(b))).map(a =>
                <Marker
                  selected={saloon.saloon_id === a.saloon_id}
                  text={a.name}
                  lat={a.location?.[0]}
                  lng={a.location?.[1]}
                />
              )
            }
          </GoogleMapReact>
        </div>

        <div class="button-container">
          <Button
            fullWidth
            variant="contained"
            className={ classes.delete }
            onClick={ () => {
              setSaloon({ name: '' });
              handleClose();
            } }
          >
            Delete
          </Button>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={ disabled }
            onClick={ () => handleSubmit(saloon.name, saloon.address) }
          >
            Save
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default NewSaloonDialog;
