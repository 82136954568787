import React, { useState } from 'react';
import DashboardTab from './analytics/DashboardTab';
import ReportsTab from './analytics/ReportsTab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from './staff/TabPanel';
import useCurrency from '../utils/useCurrency';

const useStyles = makeStyles(() => ({
  appbar: {
    background: 'white',
    marginBottom: '1.5rem',
    boxShadow: '0 4px 4px rgba(0, 0, 0, .2)',
    color: 'black'
  }
}));

const Analytics = ({ userType }) => {
  const { formatCurrency } = useCurrency();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (_, newVal) => {
    setValue(newVal);
  }

  return (
    <>
      <AppBar position="static" className={ classes.appbar }>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Dashboard" />
          <Tab label="Reports" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DashboardTab userType={ userType } formatCurrency={ formatCurrency } />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReportsTab userType={ userType } formatCurrency={ formatCurrency } />
      </TabPanel>
    </>
  );
};

export default Analytics;
