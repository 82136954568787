import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import Home from './setup/Home';
import { links } from './setup/setupLinks';
import useCurrency from '../utils/useCurrency';

const Setup = ({ userType }) => {
  const { path } = useRouteMatch();
  const { toBaseCurrency, toTargetCurrency } = useCurrency();

  return (
    <div>
      <Switch>
        {
          links.filter(a => !a.hide(userType)).map(link => (
            <Route key={ link.link } path={ `${path}/${link.link}` }>
              { link.page({ userType, toTargetCurrency, toBaseCurrency }) }
            </Route>
          ))
        }

        <Route path='*'>
          <Home userType={ userType }/>
        </Route>
      </Switch>
    </div>
  )
};

export default Setup;
