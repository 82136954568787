import React, { useState, useEffect, useContext } from 'react';
import XLSX from 'xlsx';
import moment from 'moment';
import DatePicker from '../calendar/DatePicker';
import Export from './Export';
import TransactionSummaryCard from './dailySalesTab/TransactionSummaryCard';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import FilterDrawer from '../inventory/FilterDrawer';
import CashMovementSummaryCard from './dailySalesTab/CashMovementSummaryCard';
import { makeStyles } from '@material-ui/core/styles';
import { splitNCapitalize, duplicateObj, setFontPDF, effectFetch } from '../../utils/helpers';
import authService from '../../utils/authService';
import { Errors } from '../../App.js';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
applyPlugin(jsPDF);

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '1rem'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '.75rem',
    marginTop: 0,
    '& > div + div > button': {
      backgroundColor: 'white',
      padding: '.5rem 1.5rem',
      '& + button': {
        marginLeft: '.5rem',
      }
    }
  },
}));

const generateExcelOrCSV = (date, transactionSummaryData, cashMovementData, isCSV, formatCurrency) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Daily Sales",
    CreatedDate: new Date()
  };

  const transactionSummaryAOA = Object
    .keys(transactionSummaryData)
    .map(key =>
      [
        splitNCapitalize(key),
        transactionSummaryData[key].sales_qty,
        transactionSummaryData[key].refund_qty,
        formatCurrency(transactionSummaryData[key].gross_total),
      ]
    );
  transactionSummaryAOA.unshift(['Item Type', 'Sales Qty', 'Refund Qty', 'Gross Total']);

  const cashMovementAOA = cashMovementData
    .map(a =>
      [
        a.type,
        formatCurrency(a.payments_collected),
        formatCurrency(a.refunds_paid),
      ]
    );
  cashMovementAOA.unshift(['Payment Type', 'Payments Collected', 'Refunds Paid']);

  wb.SheetNames.push("Transaction Summary");

  if(isCSV) {
    wb.Sheets["Transaction Summary"] = XLSX.utils.aoa_to_sheet([...transactionSummaryAOA, [], ... cashMovementAOA]);
  } else {
    wb.Sheets["Transaction Summary"] = XLSX.utils.aoa_to_sheet(transactionSummaryAOA);

    wb.SheetNames.push("Cash Movement Summary");
    wb.Sheets["Cash Movement Summary"] = XLSX.utils.aoa_to_sheet(cashMovementAOA);
  }

  XLSX.writeFile(wb, 'daily_sales_' + date.format('YYYY-MM-DD') + ((isCSV) ? '.csv' : '.xlsx'));
};

const generatePDF = ({
  date,
  selectedSaloon,
  transactionSummaryData,
  cashMovementData,
  formatCurrency
}) => {
  const doc = new jsPDF();
  setFontPDF(doc);

  doc.text(`Daily Sales: ${date.format('dddd, D MMM YYYY')}`, 10, 10);
  doc.setFontSize(7);
  doc.text(
    `${selectedSaloon || 'All locations'}, generated ${
      moment().format('DD MMM YYYY [at] HH:mm')
    }`,
    10,
    15
  );

  doc.setFontSize(12);
  doc.text('Transaction Summary', 10, 25);

  doc.autoTable({
    startY: 30,
    head: [['Item type', 'Sales qty', 'Refund qty', 'Gross total']],
    body: Object.keys(transactionSummaryData).map((key, i) => [
      splitNCapitalize(key),
      transactionSummaryData[key].sales_qty.toString(),
      (transactionSummaryData[key].refund_qty ?? 'N/A').toString(),
      formatCurrency(transactionSummaryData[key].gross_total)
    ]),
  })

  const lastY = doc.lastAutoTable.finalY;
  doc.text('Cash Movement Summary', 10, lastY + 10);

  doc.autoTable({
    startY: lastY + 15,
    head: [['Payment Type', 'Payments Collected', 'Refunds Paid']],
    body: cashMovementData.map(item => [
      item.type,
      formatCurrency(item.payments_collected),
      formatCurrency(item.refunds_paid)
    ]),
  })

  doc.save(`daily_sales_${date.format('YYYY-MM-DD')}.pdf`);
};

const template = {
  appointments:             { sales_qty: 0, refund_qty: null,    gross_total: 0 },
  products:                 { sales_qty: 0, refund_qty: 0,    gross_total: 0 },
  'late-cancellation-fees': { sales_qty: 0, refund_qty: null,    gross_total: 0 },
  'no-show-fees':           { sales_qty: 0, refund_qty: null,    gross_total: 0 },
  'total-sales':            { sales_qty: 0, refund_qty: 0,    gross_total: 0 },
};

const DailySalesTab = ({ userType, availableSaloons, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [date, setDate] = useState(moment());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedSaloon, setSelectedSaloon] = useState(0);
  const [transactionSummaryData, setTransactionSummaryData] = useState(duplicateObj(template));
  const [cashMovementData, setCashMovementData] = useState([]);

  // Fetch data
  useEffect(() => {
    // Fetch transaction summary data
    const saloonQuery = selectedSaloon !== 0 ? `saloon=${selectedSaloon}` : '';
    const dateQuery = `date=${date.format('YYYY-MM-DD')}`;

    return effectFetch(
      `/api/${userType}/transactions/summary?${dateQuery}&${saloonQuery}`,
      json => {
        const newData = duplicateObj(template);

        json.data.forEach(a => {
          switch(a.type) {
            case 'appointment':
              newData.appointments = a;
              break;
            case 'product':
              newData.products = a;
              break;
            case 'late-cancellation':
              newData['late-cancellation-fees'] = a;
              break;
            case 'no-show':
              newData['no-show-fees'] = a;
              break;
            default:
              break;
          }

          newData['total-sales'].sales_qty += a.sales_qty;
          newData['total-sales'].refund_qty += a.refund_qty;
          newData['total-sales'].gross_total += a.gross_total;
        });

        setTransactionSummaryData(newData);
      },
      err => console.log(err)
    );
  }, [ userType, selectedSaloon, date ]);

  useEffect(() => {
    const saloonQuery = selectedSaloon !== 0 ? `saloon=${selectedSaloon}` : '';
    const dateQuery = `date=${date.format('YYYY-MM-DD')}`;

    // Fetch cash movement data
    return effectFetch(
      `/api/${userType}/payments?${dateQuery}&${saloonQuery}`,
      json => {
        const total = {
          type: 'Payments Collected',
          payments_collected: 0,
          refunds_paid: 0
        };

        json.data.forEach(a => {
          total.payments_collected += a.payments_collected;
          total.refunds_paid += a.refunds_paid;
        });

        setCashMovementData([ ...json.data, total ]);
      },
      err => console.log(err)
    );
  }, [ userType, selectedSaloon, date ]);

  return (
    <div className={ classes.wrapper }>
      <FilterDrawer
        userType={ userType }
        availableOptions={{ saloon: availableSaloons }}
        handleChange={ filters => {
          if(filters !== null) {
            setSelectedSaloon(filters.saloon);
          }

          setIsDrawerOpen(false);
        }}
        open={ isDrawerOpen }
      />

      <div className={ classes.toolbar }>
        <DatePicker
          type="1day"
          dates={{ start: date, end: date }}
          onChange={ dates => setDate(dates.start) }
        />

        <div>
          <Export
            generatePDF={
              _ => generatePDF({
                date,
                selectedSaloon: availableSaloons.find(a => a.id === selectedSaloon).name,
                transactionSummaryData,
                cashMovementData,
                formatCurrency
              })
            }
            generateExcel={
              _ => generateExcelOrCSV(
                date,
                transactionSummaryData,
                cashMovementData,
                false,
                formatCurrency
              )
            }
            generateCSV={
              _ => generateExcelOrCSV(
                date,
                transactionSummaryData,
                cashMovementData,
                true,
                formatCurrency
              )
            }
          />

          { userType === 'super-admin' &&
          <Button
            onClick={ _ => setIsDrawerOpen(true) }
            align="right"
            variant="contained"
            endIcon={ <FilterListIcon /> }
          >
            Filters
          </Button>
          }
        </div>
      </div>

      <div className={ classes.container }>
        <TransactionSummaryCard
          formatCurrency={ formatCurrency }
          data={ transactionSummaryData }
        />
        <CashMovementSummaryCard
          data={ cashMovementData }
          formatCurrency={ formatCurrency }
        />
      </div>
    </div>
  );
};

export default DailySalesTab;
