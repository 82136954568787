export const cards = [
  {
    title: 'Finances',
    description: 'Monitor your overall finances including sales, refunds, taxes, payments and more',
    links: [
      'payments-summary',
      // 'taxes-summary',
      // 'outstanding-invoices',
    ]
  },
  {
    title: 'Inventory',
    description: 'Monitor product stock levels and adjustments made, analyse product sales performance, consumption costs and more',
    links: [
      'stock-on-hand',
      'product-sales-performance',
      'stock-movement-log',
      'stock-movement-summary',
      'internal-stock-consumption',
    ]
  },
  {
    title: 'Appointments',
    description: 'View projected revenues of upcoming appointments, track cancellation rates and reasons',
    links: [
      'appointments-by-staff',
      'appointments-by-service',
      'appointment-cancellations',
    ]
  },
  {
    title: 'Clients',
    description: 'Gain insights into how clients interact with your business and who your top spenders are',
    links: [
      'client-list',
      'client-retention',
    ]
  },
  {
    title: 'Sales',
    description: 'Analyse the performance of your business by comparing sales across products, staff, channels and more',
    links: [
      'sales-by-service',
      'sales-by-product',
      'sales-by-location',
      'sales-by-staff-overall-stats',
      'sales-by-staff-granular',
      // 'Sales by Channel - need a way of tracking where the sale came from (stretch goal)',
      'sales-by-year',
      'sales-by-quarter',
      'sales-by-month',
      'sales-by-day',
      'sales-by-hour',
    ]
  },
  // {
  //   title: 'Staff',
  //   description: 'View your team\'s performance, hours worked as well as commission and tip earnings',
  //   links: [
  //     'Staff working hours',
  //     'Tips by Staff',
  //     'Staff commission summary',
  //     'Staff commission detail',
  //   ]
  // }
];

export const links = cards.reduce((pr, cur) => {
  return [
    ...pr,
    ...cur.links
  ];
}, [])
