import React, { useState } from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menu: {
    width: '8rem',
    textAlign: 'center'
  }
}));


const Export = ({ generatePDF, generateExcel, generateCSV }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={ e => setAnchorEl(e.currentTarget) }
        startIcon={ <ImportExportIcon /> }
      >
        Export
      </Button>

      <Menu
        classes={{ paper: classes.menu }}
        getContentAnchorEl={null}
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={ _ => {
            generatePDF();
            handleClose();
          }}
        >
          PDF
        </MenuItem>
        <MenuItem
          onClick={ _ => {
            generateExcel();
            handleClose();
          }}
        >
          Excel
        </MenuItem>
        <MenuItem
          onClick={ _ => {
            generateCSV();
            handleClose();
          }}
        >
          CSV
        </MenuItem>
      </Menu>
    </>
  );
};

export default Export;
