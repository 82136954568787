import React, { useContext } from 'react';
import useForm from '../utils/useForm';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { Errors } from '../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',

    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  paper: {
    margin: 'auto',
    marginTop: '10rem',
    maxWidth: '30rem',
    padding: theme.spacing(2),

    '& .MuiPaper-root': {
      backgroundColor: '#eee'
    }
  }
}));

const PasswordReset = () => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const [values, handleChange] = useForm({
    email: '',
    newPassword: ''
  });

  const handleSubmit = e => {
    e.preventDefault();

    fetch(`/api/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...values, token })
    })
      .then(data => data.json())
      .then(json => {
        if(json.ok || json.success) {
          history.push(`/barber/login`);
        } else {
          setErrors([{ message: json }]);
        }
      })
      .catch((err) => setErrors([err]));
  }

  return (
    <div className={ classes.root }>
      <Paper className={ classes.paper } variant="outlined">
        <Typography variant="h2" gutterBottom>
          Reset Password
        </Typography>

        <form onSubmit={ handleSubmit }>
          <TextField
            label="Email"
            id="email"
            name="email"
            type="email"
            variant="filled"
            value={ values.email }
            onChange={ handleChange }
          />
          <br />

          <TextField
            label="New Password"
            id="newPassword"
            name="newPassword"
            type="password"
            variant="filled"
            value={ values.newPassword }
            onChange={ handleChange }
          />
          <br />

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Done
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export default PasswordReset;
