import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '30%',
  },
  content: {
    '& > *:nth-child(even)': {
      marginBottom: '1rem'
    }
  },
  white: {
    background: 'white'
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
  primary: {
    padding: '1rem auto 1rem auto',
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
}));


const NewTaxDialog = ({ tax, isDialogOpen, handleSubmit, handleDelete }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [rate, setRate] = useState('');

  useEffect(() => {
    setName(tax?.tax_name || '');
    setRate(tax?.tax_rate || '');
  }, [ isDialogOpen ]);

  const handleClose = () => handleSubmit(false);

  const submitHandler = () => {
    handleSubmit(true, { name, rate });
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={ isDialogOpen }
        onClose={ handleClose }
      >
        <DialogTitle>
          { tax ? 'Edit' : 'New' } Tax
        </DialogTitle>

        <DialogContent className={ classes.content }>
          <InputLabel htmlFor="name">Tax Name</InputLabel>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              id: 'name',
              name: 'name',
            }}
            value={ name }
            onChange={ e => setName(e.target.value) }
          />

          <InputLabel htmlFor="rate">Tax Rate</InputLabel>
          <TextField
            fullWidth
            margin="dense"
            type="number"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              id: 'rate',
              name: 'rate',
            }}
            value={ rate }
            onChange={ e => setRate(e.target.value) }
          />
        </DialogContent>
        <DialogActions>
          {
            tax &&
              <Button
                className={ classes.delete }
                onClick={ handleDelete }
                variant="contained"
              >
                Delete
              </Button>
          }
          <Button
            className={ classes.white }
            onClick={ handleClose }
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className={ classes.primary }
            onClick={ submitHandler }
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewTaxDialog;
