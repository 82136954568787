import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

import { Header} from './CustomCalendarComponents';
import * as dates from 'date-arithmetic';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

import "./calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

/*
 * =======================================================================
 * CUSTOM VIEW
 * =======================================================================
*/

class MultiDay extends React.Component {
  range = date => {
    let start = date;
    let end = dates.add(start, this.props.numOfDays - 1, 'day');

    let current = start;
    let range = [];

    while (dates.lte(current, end, 'day')) {
      range.push(current);
      current = dates.add(current, 1, 'day');
    }

    return range;
  };

  navigate = (date, action) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return dates.add(date, -this.props.numOfDays, 'day');

      case Navigate.NEXT:
        return dates.add(date, this.props.numOfDays, 'day');

      default:
        return date;
    }
  };

  render() {
    let { date } = this.props;

    return <TimeGrid {...this.props} range={this.range(date)} eventOffset={15} />
  };
};

MultiDay.title = date => {
  return `date`;
};

const CalendarComponent = ({
  view,
  dates,
  barber,
  userType,
  handleSelect,
  updateTrigger
}) => {
  const { setErrors } = useContext(Errors);
  const [appointments, setAppointments] = useState([]);
  const [workingHours, setWorkingHours] = useState(null);

  if(view === '1day') {
    view = 'day';
  }
  const weekStart = parseInt(moment(dates.start).startOf('week').format('d'));

  // Fetch appointments
  useEffect(() => {
    let toReturn;
    let numOfApps;

    const appStatuses = 'confirmed completed rescheduled';
    const start = dates.start.format('YYYY-MM-DD');
    const end = dates.end.format('YYYY-MM-DD');
    const barberQuery = (userType !== 'barber') ? `&barber=${barber.id}` : '';
    const url = `/api/${userType}/appointments/date?statuses=${appStatuses}&dateType=date-range&from=${start}&to=${end}${barberQuery}&limit=100`;

    const fetchFunction = (page) => new Promise((resolve, reject) => {
      toReturn = effectFetch(
        url + `&page=${page}`,
        (json) => {
          const newAppointments = (json.data || []).map((a) => ({
            id: a.appointment_id,
            title: a.customer ? a.customer : 'Walk In',
            status: a.status,
            desc: a.services.map(a => a.name).join(', '),
            start: new Date(moment(a.time)),
            end: new Date(moment(a.time).add(a.duration, 'minute'))
          })).reduce((pr, cur) => {
            if(moment(cur.start).isSame(moment(cur.end), 'day')) {
              return [...pr, cur];
            }

            const appA = { ...cur };
            const appB = { ...cur };

            appA.end = new Date(moment(cur.start).endOf('day'));
            appB.start = new Date(moment(cur.end).startOf('day'));

            return [...pr, appA, appB];
          }, []);

          if(page === 1) {
            setAppointments(newAppointments);
            numOfApps = newAppointments.length;
          } else {
            setAppointments(pr => [ ...pr, newAppointments ]);
            numOfApps = appointments.length + newAppointments.length;
          }
          resolve();
        },
        err => {
          reject();
        }
      );
    });

    const fetchAllAppointments = async () => {
      let i = 0;

      while(i === 0 || numOfApps === i * 100) {
        await fetchFunction(++ i);
      }
    };

    fetchAllAppointments();

    return toReturn;
  }, [ barber, dates, userType, updateTrigger ]);

  // Fetch working hours
  useEffect(_ => {
    const query = {
      barber: `/api/barber/profile/working-hours?`,
      admin: `/api/admin/barbers/working-hours?barber=${barber.id}&`,
      'super-admin': `/api/super-admin/barbers/working-hours?barber=${barber.id}&`,
    }[userType] + `date=${moment(dates.start).format('YYYY-MM-DD')}`;

    return effectFetch(
      query,
      json => setWorkingHours(json.data),
      err => console.log(err)
    );
  }, [ barber, userType, dates ]);

  return (
    <>
      <DnDCalendar
        style={{ width: '100%' }}
        toolbar={false}
        timeslots={1}
        localizer={ localizer }
        selectable
        events={ appointments }
        date={ new Date(dates.start) }
        onNavigate={ date => date }
        onView={ view => view }
        view={ view }
        onSelectEvent={ event => alert(event.title) }
        onSelectSlot={ handleSelect }
        numOfDays={ view.charAt(0) }
        formats={{
          dayFormat: 'D dddd',
          timeGutterFormat: 'H:mm',
          eventTimeRangeFormat: ({ start, end}) => {
            return moment(start).format('H:mm') + ' - ' + moment(end).format('H:mm');
          }
        }}
        views={{
          day: true,
          '3day': MultiDay,
          week: true
        }}
        slotPropGetter={ (date) => {
          // Determine if background is white or grey based on working hours
          if(!workingHours) {
            return {};
          }

          const weekDay = (
            parseInt(moment(date).format('d')) -
            weekStart + 7
          ) % 7;

          let isWorking = false;
          const cur = workingHours[weekDay];

          if(!cur.isOnHoliday && cur.status) {
            const curDate = moment(date).format('YYYY/MM/DD ');
            const isBetween = moment(date).isBetween(
              moment(curDate + cur.start_time),
              moment(curDate + cur.end_time),
              'minute',
              '[)'
            );

            if(isBetween) {
              isWorking = true;
            }
          }

          return {
            style: {
              backgroundColor: (!isWorking) ? '#eef0f2' : 'white',
            }
          };
        }}
        eventPropGetter={ event => ({ style: {
          backgroundColor: (event.status === 'completed') ? '#0bc2b0' : '#2883d2',
          color: 'white',
          border: 'none',
          padding: '.5rem',
          fontSize: '.9rem',
          cursor: 'default'
        } }) }
        components={{
          header: Header
        }}
      />
    </>
  );
}

export default CalendarComponent;
