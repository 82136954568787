import React, { useState, useEffect, useCallback } from 'react';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ComplicatedDatePicker from './ComplicatedDatePicker';
import Export from '../../sales/Export';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Loadable from '../../../utils/Loadable';
import { splitNCapitalize, setFontPDF, effectFetch } from '../../../utils/helpers';
import authService from '../../../utils/authService';
import FilterDrawer from '../../inventory/FilterDrawer';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
applyPlugin(jsPDF);

const useStyles = makeStyles(() => ({
  link: {
    color: '#368dff',
    cursor: 'pointer',
    fontSize: '1.1rem',
    '&:hover': {
      textDecoration: 'underline'
    }

  },
  wrapper: {
    padding: '1rem'
  },
  container: {
    padding: '.75rem'
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '.75rem',
    marginTop: 0,
    '& > div + div > button': {
      backgroundColor: 'white',
      padding: '.5rem 1.5rem',
      '& + button': {
        marginLeft: '.5rem',
      }
    }
  },
}));

const generatePDF = (path, data, fields, dates, selectedSaloon, formatValue) => {
  const title = splitNCapitalize(path);
  const doc = new jsPDF();
  setFontPDF(doc);

  doc.text(title, 10, 10);
  doc.setFontSize(7);
  doc.text(
    `${
      dates.start.format('dddd, D MMM YYYY')
    } to ${
      dates.end.format('dddd, D MMM YYYY')
    }, ${
      selectedSaloon || 'All locations'
    }, generated ${
      moment().format('DD MMM YYYY [at] HH:mm')
    }`,
    10,
    15
  );

  let y = 25;
  doc.setFontSize(12);

  tables.forEach(table => {
    doc.text(table, 10, y);

    doc.autoTable({
      startY: y + 2,
      head: [fields.map(a => splitNCapitalize(a))],
      body: data[table].map(a => Object.values(a).map(b => formatValue(b).toString()))
    });

    y = doc.lastAutoTable.finalY + 10;
  })

  doc.save(path + moment().format('_YYYY-MM-DD') + '.pdf');
};

const generateExcelOrCSV = (path, data, fields, isCSV, formatValue) => {
  const title = splitNCapitalize(path);

  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    CreatedDate: new Date()
  };

  if(isCSV) {
    const dataAOA = tables.reduce((pr, cur) => [ ...pr, ...data[cur].map(a => Object.values(a).map(b => formatValue(b))) ], []);

    dataAOA.unshift(fields.map(field => splitNCapitalize(field)));
    wb.SheetNames.push(title);

    wb.Sheets[title] = XLSX.utils.aoa_to_sheet(dataAOA);
  } else {
    tables.forEach(table => {
      const dataAOA = data[table].map(a => Object.values(a).map(b => formatValue(b)));

      dataAOA.unshift(fields.map(field => splitNCapitalize(field)));

      wb.SheetNames.push(table);

      wb.Sheets[table] = XLSX.utils.aoa_to_sheet(dataAOA);
    });
  }

  XLSX.writeFile(wb, path + moment().format('_YYYY-MM-DD') + ((isCSV) ? '.csv' : '.xlsx'));
};

const tables = [
  'New Stock',
  'Return',
  'Transfer',
  'Adjustment',
  'Other'
];
const pageLimit = 20;
const getArrayOfVal = (val) => new Array(5).fill(val);

const ReportPage = ({ userType, path, formatCurrency }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(getArrayOfVal(false));
  const [dates, setDates] = useState({ start: moment(), end: moment() });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = useState(getArrayOfVal(0));
  const [data, setData] = useState({});
  const [selectedSaloon, setSelectedSaloon] = useState(0);
  const [availableSaloons, setAvailableSaloons] = useState([]);

  const formatValue = (value) => {
    // If currency value
    if(typeof value === 'string' && value[0] === '€') {
      return formatCurrency(parseFloat(value.slice(1)));
    }

    return value;
  };


  // Fetch availableSaloons
  useEffect(() => {
    let toReturn;

    if(userType === 'super-admin') {
      toReturn = effectFetch(
        `/api/super-admin/saloons/all?limit=100`,
        json => {
          const avSaloons = (json.data || [])
            .map((a, i) => ({
              name: a.name,
              id: a.saloon_id,
              index: i + 1
            }));

          avSaloons.unshift({ name: 'All', id: 0, index: 0 });
          setAvailableSaloons(avSaloons);

          if(avSaloons.length > 0) {
            setSelectedSaloon(avSaloons[0].id);
          }
        },
        err => console.log(err)
      );
    }

    return toReturn;
  }, [ userType ]);

  const getDataUrl = (pageIn, reason) => {
    const saloonQuery = (selectedSaloon === 0) ? '' : 'saloon=' + selectedSaloon;
    const start = dates.start.format('YYYY-MM-DD');
    const end = dates.end.format('YYYY-MM-DD');

    return `/api/${userType}/analytics/reports/${path}?${saloonQuery}&reason=${reason + 1}&startDate=${start}&endDate=${end}&limit=${pageLimit}&page=${pageIn + 1}`;
  }

  const getAllData = async () => {
    const allData = tables.reduce((pr, cur) => ({ ...pr, [cur]: [] }), {});

    for(let j = 1; j < 5; j ++) {
      let i = 0;

      do {
        const res = await authService.authFetch(getDataUrl(i, j));
        allData[tables[j - 1]].push(...(await res.json()).data);
        i ++;
      } while(allData[tables[j - 1]].length === i * pageLimit)
    }

    return allData;
  }

  const fetchData = useCallback((index) => {
    setLoading(getArrayOfVal(true));

    return effectFetch(
      getDataUrl(page[index], index),
      json => {
        setData(pr => ({ ...pr, [tables[index]]: json }));
        setLoading(getArrayOfVal(false));
      },
      err => console.log(err)
    );
  }, [dates, selectedSaloon, page]);

  // Fetch data
  useEffect(() => {
    return fetchData(0);
  }, [ userType, page[0], dates, selectedSaloon ]);

  useEffect(() => {
    return fetchData(1);
  }, [ userType, page[1], dates, selectedSaloon ]);

  useEffect(() => {
    return fetchData(2);
  }, [ userType, page[2], dates, selectedSaloon ]);

  useEffect(() => {
    return fetchData(3);
  }, [ userType, page[3], dates, selectedSaloon ]);

  useEffect(() => {
    return fetchData(4);
  }, [ userType, page[4], dates, selectedSaloon ]);

  // Reset page when selectedSaloon or date is changed
  useEffect(() => setPage(getArrayOfVal(0)), [ selectedSaloon, dates ]);

  const handleChangePage = (_, newPage, tableIndex) => {
    if(
      data[tables[tableIndex]]?.data.length === pageLimit ||
      newPage < page[tableIndex]
    ) {
      setPage(pr => [
        ...pr.slice(0, tableIndex),
        newPage,
        ...pr.slice(tableIndex + 1, pr.length)
      ]);
    }
  }

  return (
    <div className={ classes.wrapper }>
      <FilterDrawer
        userType={ userType }
        availableOptions={{ saloon: availableSaloons }}
        handleChange={ filters => {
          if(filters !== null) {
            setSelectedSaloon(filters.saloon);
          }

          setIsDrawerOpen(false);
        }}
        open={ isDrawerOpen }
      />

      <a
        href="#"
        className={ classes.link }
        onClick={ _ => history.push('/' + userType + '/analytics') }
      >
        { '< Reports' }
      </a>

      <h2>{ splitNCapitalize(path) }</h2>
      <div className={ classes.toolbar }>
        <ComplicatedDatePicker
          type="year_to_date"
          hasPopup={ true }
          dates={ dates }
          onChange={ setDates }
        />

        <div>
          <Export
            generatePDF={ async _ => generatePDF(path, await getAllData(), data.Return.fields, dates, availableSaloons.find(a => a.id === selectedSaloon).name, formatValue) }
            generateExcel={ async _ => generateExcelOrCSV(path, await getAllData(), data.Return.fields, false, formatValue) }
            generateCSV={ async _ => generateExcelOrCSV(path, await getAllData(), data.Return.fields, true, formatValue) }
          />

          { userType === 'super-admin' &&
          <Button
            onClick={ _ => setIsDrawerOpen(true) }
            align="right"
            variant="contained"
            endIcon={ <FilterListIcon /> }
          >
            Filters
          </Button>
          }
        </div>
      </div>

      <div className={ classes.container }>
        {
          tables.map((table, tableIndex) => (
            <Loadable loading={loading[tableIndex]}>
              <div key={table}>
                <h2>{ table }</h2>
                <Card>
                  <CardContent>
                    <TableContainer component="paper">
                      <Table>
                        <TableHead>
                          <TableRow>
                            {
                              data[table]?.fields.map(key => (
                                <TableCell
                                  key={ key }
                                >
                                  { splitNCapitalize(key) }
                                </TableCell>
                              ))
                            }
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            data[table]?.data.map((item, i) => (
                              <TableRow
                                className={ classes.row }
                                key={i}
                              >
                                {
                                  Object.keys(item).map(key => (
                                    <TableCell key={key}>{ item[key] }</TableCell>
                                  ))
                                }
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[ pageLimit ]}
                        component="div"
                        count={
                          (data[table]?.data.length === pageLimit) ?
                            (-1) : (pageLimit * page[tableIndex] + data[table]?.data.length)
                        }
                        rowsPerPage={pageLimit}
                        page={ page[tableIndex] }
                        onChangePage={
                          (_, newPage) => handleChangePage(null, newPage, tableIndex)
                        }
                        nextIconButtonProps={{ disabled: data[table]?.data.length < pageLimit }}
                      />
                    </TableContainer>
                  </CardContent>
                </Card>
              </div>
            </Loadable>
          ))
        }
      </div>
    </div>
  );
};

export default ReportPage;
