import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { cards } from './setupLinks';
import { splitNCapitalize } from '../../utils/helpers';

const useStyles = makeStyles(_ => ({
  link: {
    display: 'block',
    color: '#368dff',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '75rem',
    alignContent: 'center',
  },
  card: {
    margin: '1rem',
    maxWidth: '25%',
  }
}));

const Home = ({ userType }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={ classes.container }>
      {
        cards.map(card => (
          <Card key={ card.title } className={ classes.card }>
            <CardHeader
              title={ card.title }
              subheader={ card.description }
            />
            <CardContent>
              <List>
                {
                  card.links.filter(a => !a.hide(userType)).map((link, i) => (
                    <div key={i}>
                      <ListItem>
                        <ListItemText>
                          <a
                            className={ classes.link }
                            onClick={ _ => history.push(`/${userType}/setup/${link.link}`) }
                          >
                            { splitNCapitalize(link.link) }
                          </a>
                        </ListItemText>
                      </ListItem>
                      { (i !== card.links.length - 1) && <Divider /> }
                    </div>
                  ))
                }
              </List>
            </CardContent>
          </Card>
        ))
      }
    </div>
  )
};

export default Home;
