import React, { useState, useEffect , useContext } from 'react';
import currencyFormatter from 'currency-formatter';
import { effectFetch } from '../../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { Errors, Success } from '../../App.js';

const useStyles = makeStyles(_ => ({
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
    '& > div + div': {
      borderTop: '1px solid #ccc',
    },
    '& > div > div': {
      marginBottom: '1.5rem'
    },
    '& button': {
      padding: '.5rem',
      fontSize: '1rem',
      backgroundColor: '#1a263a',
      '&:hover': {
        backgroundColor: '#3c485c'
      },
    }
  },
  emptyMessage: {
    width: '100%',
    marginTop: '10rem',
    textAlign: 'center'
  }
}));

const FeeSettings = ({ userType, toTargetCurrency, toBaseCurrency }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const classes = useStyles();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    return effectFetch(
      `/api/${userType}/saloons/settings?limit=100`,
      json => {
        setSettings((json.data.map(a => ({
          ...a,
          cancellation_fee: toTargetCurrency(a.cancellation_fee).toFixed(2),
          no_show_fee: toTargetCurrency(a.no_show_fee).toFixed(2),
        })) || []));

        setLoading(false);
      },
      err => console.log(err)
    )
  }, [updateTrigger])

  const handleSubmit = (id, cancellationFee, cancellationTimeframe, noShowFee) => {
    authService.authFetch(`/api/${userType}/saloons`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id,
        cancellationTimeframe,
        cancellationFee: toBaseCurrency(cancellationFee),
        noShowFee: toBaseCurrency(noShowFee)
      })
    }).then(_ => {
      setUpdateTrigger(pr => !pr);
      setSuccess({ message: 'Settings updated successfully' });
    })
      .catch(err => setErrors([err]));
  }

  const changeSaloonSetting = (index, setting, value) => {
    setSettings(pr => [
      ...pr.slice(0, index),
      { ...pr[index], [setting]: value },
      ...pr.slice(index + 1, pr.length)
    ]);
  };

  return (
    <div>
      {
        (settings.length === 0 && !loading) &&
          <div className={ classes.emptyMessage }>
            <h2>No saloons yet</h2>
            <p>Create a saloon to see this page</p>
          </div>
      }
      { settings.map((saloon, i) => (
        <Card className={ classes.card }>
          <CardHeader
            title={saloon.name}
          />
          <CardContent>
            <TextField
              label="Cancellation Fee"
              id={ saloon.saloon_id + '-cancellation-fee' }
              name="cancellation-fee"
              type="number"
              value={ saloon.cancellation_fee }
              onChange={ e => changeSaloonSetting(i, 'cancellation_fee', e.target.value) }
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: currencyFormatter.findCurrency(authService.getToken().currency).symbol
              }}
            />

            <TextField
              label="Cancellation Timeframe"
              id={ saloon.saloon_id + '-cancellation-timeframe' }
              name="cancellation-timeframe"
              type="number"
              value={ saloon.cancellation_timeframe }
              onChange={ e => changeSaloonSetting(i, 'cancellation_timeframe', e.target.value) }
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: 'hours'
              }}
            />

            <TextField
              label="No Show Fee"
              id={ saloon.saloon_id + '-no-show-fee' }
              name="no-show-fee"
              type="number"
              value={ saloon.no_show_fee }
              onChange={ e => changeSaloonSetting(i, 'no_show_fee', e.target.value) }
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: currencyFormatter.findCurrency(authService.getToken().currency).symbol
              }}
            />

            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={ () => handleSubmit(
                saloon.saloon_id,
                saloon.cancellation_fee,
                saloon.cancellation_timeframe,
                saloon.no_show_fee
              ) }
            >
              Save
            </Button>
        </CardContent>
        </Card>
      )) }
    </div>
  )
};

export default FeeSettings;
