import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '30%',
  },
  content: {
    '& > *:nth-child(even)': {
      marginBottom: '1rem'
    }
  },
  white: {
    background: 'white'
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
}));

const ConfirmationDialog = ({ text, handleSubmit, isOpen, handleClose }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={ isOpen }
        onClose={ handleClose }
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>

        <DialogContent className={ classes.content }>
          <DialogContentText>{ text }</DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button
            className={ classes.white }
            onClick={ handleClose }
            variant="contained"
          >
            No, take me back
          </Button>
          <Button
            className={ classes.delete }
            onClick={ handleSubmit }
            variant="contained"
          >
            Yes, I want to proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
