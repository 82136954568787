import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialog: {
    width: '30%',
  },
  content: {
    '& > *:nth-child(even)': {
      marginBottom: '1rem'
    }
  },
  primary: {
    width: '100%',
    padding: '1rem auto 1rem auto',
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
}));


const NewHolidayDialog = ({ open, handleSubmit }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [description, setDescription] = useState('');

  useEffect(() => {
    setStartDate(moment());
    setEndDate(moment());
    setDescription('');
  }, [ open ]);

  const handleClose = () => handleSubmit(false);

  const submitHandler = () => {
    handleSubmit(true, {
      startDate: startDate,
      endDate: endDate,
      description
    });
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={ open }
        onClose={ handleClose }
      >
        <DialogTitle>
          New Holiday
        </DialogTitle>

        <DialogContent className={ classes.content }>
          <InputLabel htmlFor="start-date">Start Date</InputLabel>
          <DatePicker
            margin="dense"
            inputVariant="outlined"
            value={ startDate }
            onChange={ setStartDate }
            fullWidth
            inputProps={{
              id: 'end-date',
              name: 'end-date'
            }}
          />

          <InputLabel htmlFor="end-date">End Date</InputLabel>
          <DatePicker
            margin="dense"
            inputVariant="outlined"
            value={ endDate }
            fullWidth
            onChange={ setEndDate }
            inputProps={{
              id: 'end-date',
              name: 'end-date'
            }}
          />

          <InputLabel htmlFor="description">Description</InputLabel>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true,
              id: 'description',
              name: 'description',
            }}
            value={ description }
            onChange={ e => setDescription(e.target.value) }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={ classes.primary }
            onClick={ submitHandler }
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewHolidayDialog;
