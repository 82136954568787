import React, { useState, useEffect , useContext } from 'react';
import authService from '../../utils/authService';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import ChangeStockDialog from './ChangeStockDialog';
import { capitalizeFirst, effectFetch } from '../../utils/helpers';
import NewProductDialog from './NewProductDialog';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Errors } from '../../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '1.5rem'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    '& button': {
      backgroundColor: 'white',
      marginTop: 0,
      marginBottom: 0,
      padding: '.5rem 1rem',
      '& + button': {
        marginLeft: '.5rem'
      }
    },
    '& > div > button:last-child': {
      marginLeft: '1rem',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      backgroundColor: '#1a263a',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3c485c'
      },
    }
  },
  content: {
    padding: '2rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 30%)',
    gridTemplateRows: 'repeat(4, 4rem)',
  },
}));

const ProductView = ({ userType, selectedProduct, onBack, formatCurrency, toBaseCurrency, toTargetCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [product, setProduct] = useState(null);
  const [addOrRemoveStock, setAddOrRemoveStock] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateTrigger1, setUpdateTrigger1] = useState(true);
  const [updateTrigger2, setUpdateTrigger2] = useState(true);
  const [availableOptions, setAvailableOptions] = useState({
    brand:    [{ id: 0, name: 'All', index: 0 }],
    category: [{ id: 0, name: 'All', index: 0 }],
    tax:      [{ id: 0, name: 'No tax', rate: 0, index: 0 }],
  });

  // Fetch product information
  useEffect(() => {
    return effectFetch(
      `/api/${userType}/products/single?id=${selectedProduct}`,
      json => setProduct(json.data),
      err => console.log(err)
    );

  }, [ userType, selectedProduct, updateTrigger1 ]);

  // Fetch all available options
  useEffect(() => {
    Object.keys(availableOptions).forEach(option => {
      let pluralFilter = option + 's';

      // Correct plural for category
      if (option === 'category') {
        pluralFilter = 'categories';
      }
      // Correct plural for taxes
      if (option === 'tax') {
        pluralFilter = 'payments/taxes/all';
      } else {
        pluralFilter += '/all';
      }

      // Don't check saloons for non super-admins
      if(option === 'saloon' && userType !== 'super-admin') {
        return;
      }

      return effectFetch(
        `/api/${userType}/${pluralFilter}?limit=100`,
        json => {
          let arr;

          if(option === 'tax') {
            arr = (json.data || []).map(saloon => {
              saloon.taxes = saloon.taxes.map((tax, i) => {
                tax = {
                  ...tax,
                  id: tax.tax_id,
                  index: i + 1,
                  name: tax.tax_name,
                  rate: tax.tax_rate
                };

                return tax;
              });

              saloon.taxes.unshift({ name: 'No tax', id: 0, index: 0, rate: 0 });
              return saloon;
            });
          } else {
            arr = (json.data || []).map((a, i) => {
              const obj = {
                name: a.name,
                id: a[option + '_id'],
                index: i + 1
              };

              return obj;
            });

            arr.unshift({ name: 'All', id: 0, index: 0 });
          }

          setAvailableOptions(pr => ({
            ...pr,
            [option]: arr
          }));
        },
        err => console.log(err)
      );

    });
  }, [ userType, updateTrigger2 ]);

  const submitHandler = (hasUpdatedProduct, updatedProduct) => {
    if(hasUpdatedProduct) {
      authService.authFetch(`/api/${userType}/products`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          product: selectedProduct,
          ...updatedProduct
        })
      }).then(() => {
        setUpdateTrigger1(!updateTrigger1);
      }).catch(err => setErrors([err]));
    }

    setIsDialogOpen(false);
  };

  const deleteHandler = () => {
    authService.authFetch(`/api/${userType}/products?product=${selectedProduct}`, {
      method: 'DELETE'
    }).then(() => {
      setUpdateTrigger1(!updateTrigger1);
    }).catch(err => setErrors([err]));

    setIsDialogOpen(false);
    onBack();
  };

  if(!product) {
    return null;
  }

  return (
    <div className={ classes.root }>
      <ChangeStockDialog
        userType={ userType }
        addOrRemoveStock={ addOrRemoveStock }
        saloonData={ product.saloon_stock_levels }
        product={ product.product_id }
        closeHandler={ _ => setAddOrRemoveStock(null) }
        updateHandler={ _ => setUpdateTrigger1(!updateTrigger1) }
        toBaseCurrency={ toBaseCurrency }
      />

      <NewProductDialog
        isNew={ false }
        product={ product }
        open={ isDialogOpen }
        handleSubmit={ submitHandler }
        handleDelete={ deleteHandler }
        availableOptions={{
          brand: availableOptions.brand,
          category: availableOptions.category,
          tax: availableOptions.tax
        }}
        updateHandler={ _ => setUpdateTrigger2(!updateTrigger2) }
        userType={ userType }
        toBaseCurrency={ toBaseCurrency }
        toTargetCurrency={ toTargetCurrency }
      />

      <div className={ classes.toolbar }>
        <Button
          onClick={ onBack }
          variant="contained"
          startIcon={ <ChevronLeftIcon /> }
        >
          Product List
        </Button>

        <div>
          <Button
            onClick={ _ => setAddOrRemoveStock('remove') }
            variant="contained"
          >
            Stock -
          </Button>

          <Button
            variant="contained"
            onClick={ _ => setAddOrRemoveStock('add') }
          >
            Stock +
          </Button>

          <Button
            variant="contained"
            onClick={ _ => setIsDialogOpen(true) }
          >
            Edit
          </Button>
        </div>
      </div>

      <Card>
        <CardHeader
          title={ product?.title }
          subheader={ product?.description }
        />
        <CardContent className={ classes.content }>
          {
            [ 'barcode', 'sku', 'brand', 'category', 'supplier' ].map(key =>
              <div>
                <Typography variant="body2">{ capitalizeFirst(key) }</Typography>
                <Typography variant="subtitle2">{ product ? product[key] : 'N/A' }</Typography>
              </div>
            )
          }

          <div>
            <Typography variant="body2">Supply Price</Typography>
            <Typography variant="subtitle2">{ product ? formatCurrency(product.supply_price) : 'N/A' }</Typography>
          </div>

          <div>
            <Typography variant="body2">Total On Hand</Typography>
            <Typography variant="subtitle2">{ product ? product.stock_level : 'N/A' }</Typography>
          </div>

          <div>
            <Typography variant="body2">Total Stock Cost</Typography>
            <Typography variant="subtitle2">{ product ? formatCurrency(product.total_cost) : 'N/A' }</Typography>
          </div>

          <div>
            <Typography variant="body2">Avg Stock Cost</Typography>
            <Typography variant="subtitle2">{ product ? formatCurrency(product.avg_cost) : 'N/A' }</Typography>
          </div>

          <div>
            <Typography variant="body2">Retail Price</Typography>
            <Typography
              variant="subtitle2"
              style={product.special_price && {
                color: 'red',
                textDecoration: 'line-through'
              }}
            >
              { product ? formatCurrency(product.retail_price) : 'N/A' }
            </Typography>
            { product.special_price && <Typography variant="subtitle2">{ product ? formatCurrency(product.special_price) : 'N/A' }</Typography> }
          </div>
        </CardContent>
      </Card>
    </div>
  )
};

export default ProductView;
