import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from '@material-ui/icons/Remove';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { splitNCapitalize } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  stat: {
    '& > span': {
      fontWeight: 700,

      '& + span': {
        marginLeft: '.5rem',
      },

      '&:first-child': {
        color: 'rgba(0, 0, 0, .54)',
      }
    }
  }
}));

const GREEN = '#0bc2b0';
const RED = '#f74969';

const AnalyticsCard = ({ title, data, formatCurrency }) => {
  const classes = useStyles();
  let color = 'black';

  if(data.comp_to_previous_day > 0) {
    color = GREEN;
  } else if(data.comp_to_previous_day < 0) {
    color = RED;
  }

  const formatData = (value) => {
    if(value[0] === '€') {
      return formatCurrency(value.slice(1));
    }

    return value;
  }

  return (
    <Card>
      <CardHeader
        title={ title }
      />
      <CardContent>
        <h2>{ formatData(data.total) }</h2>
        <div style={{ color: color }}>
          <span>
            { data.comp_to_previous_day > 0 && <ArrowDropUpIcon /> }
            { data.comp_to_previous_day < 0 && <ArrowDropDownIcon /> }
            { data.comp_to_previous_day === 0 && <RemoveIcon /> }
          </span>
          <span>{ Math.round(data.comp_to_previous_day) }% previous day</span>
        </div>
        {
          Object.keys(data)
            .filter(key =>
              key !== 'total' &&
              !key.includes('percentage') &&
              key !== 'comp_to_previous_day'
            )
            .map(key => (
              <div className={ classes.stat }>
                <span>{ splitNCapitalize(key) }</span>
                <span>{ formatData(data[key]) }</span>
                { data.hasOwnProperty(key + '_percentage') &&
                  <span>({ Math.round(data[key + '_percentage']) }%)</span>
                }
              </div>
            ))
        }
      </CardContent>
    </Card>
  )
};

export default AnalyticsCard;
