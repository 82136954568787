import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { formatDuration } from '../../utils/helpers';
import Button from '@material-ui/core/Button';
import authService from '../../utils/authService';
import NewServiceDialog from './NewServiceDialog';
import { Success, Errors } from '../../App.js';

const useStyles = makeStyles(_ => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    '& > *': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '.25rem 2rem'
    }
  },
  content: {
    backgroundColor: '#f0f0f2',
  },
  scroll: {
    padding: '0 3rem',
    paddingTop: '6rem'
  },
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
    '& > div + div': {
      borderTop: '1px solid #ccc',
    }
  },
  primary: {
    width: 'calc(50% - .5rem)',
    fontSize: '1rem',
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  delete: {
    width: 'calc(50% - .5rem)',
    marginRight: '1rem',
    fontSize: '1rem',
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
}));

const ServiceInfoDialog = ({
  userType,
  open,
  handleClose,
  service,
  formatCurrency,
  triggerUpdate,
  toBaseCurrency
}) => {
  const { setSuccess } = useContext(Success);
  const { setErrors } = useContext(Errors);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();

  const handleDelete = () => {
    authService.authFetch(`/api/admin/services`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ services: [service.service_id] })
    }).then(() => {
      triggerUpdate();
      handleClose();
      setSuccess({ message: 'Service deleted successfully' });
    }).catch(err => setErrors([err]));

    handleClose();
  };

  const editHandler = (hasNewService, newService) => {
    if(hasNewService) {
      // Update service
      authService.authFetch('/api/admin/services', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...newService, service: service.service_id })
      }).then(_ => {
        setSuccess({ message: 'Service updated successfully.' });
        triggerUpdate();
        handleClose();
      }).catch(err => setErrors([err]));
    }

    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        classes={{ paper: classes.content }}
        open={ open }
        onClose={ handleClose }
      >
        <AppBar className={ classes.appBar }>
          <Toolbar>
            <IconButton edge="start" onClick={ handleClose } aria-label="close">
              <CloseIcon />
            </IconButton>
            <h2>
              Service information
            </h2>
          </Toolbar>
        </AppBar>

        <div className={ classes.scroll }>
          <Card className={ classes.card }>
            <CardHeader
              title={ service?.title }
              subheader={ service?.description }
            />
            <CardContent>
              <Typography variant="h6">Available for: { service?.available_for }</Typography>
              <Typography variant="h6">Duration: { formatDuration(service?.duration) }</Typography>
              <Typography variant="h6">Price: { formatCurrency(service?.price) }</Typography>
              <Typography variant="h6">Extra Time: { formatDuration(service?.extra_duration) || 'N/A' }</Typography>
              <Typography variant="h6">Tax: { service?.tax.name || 'None' } ({service?.tax.rate || 0}%)</Typography>
            </CardContent>
            {
              userType === 'admin' &&
                <>
                  <Button
                    className={ classes.delete }
                    onClick={ handleDelete }
                  >
                    Delete
                  </Button>
                  <Button
                    className={ classes.primary }
                    onClick={ _ => setIsDialogOpen(true) }
                  >
                    Edit
                  </Button>
                </>
            }
          </Card>
        </div>
      </Dialog>
      <NewServiceDialog
        isDialogOpen={ isDialogOpen }
        handleSubmit={ editHandler }
        toBaseCurrency={ toBaseCurrency }
        service={ service }
      />
    </>
  );
}

export default ServiceInfoDialog;
