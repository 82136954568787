import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  progressContainer: {
    height: '100%',
    textAlign: 'center'
  },

  progress: {
    color: '#1a263a'
  }
}));

const Loadable = ({ isLoading, children }) => {
  const classes = useStyles();

  if(isLoading) {
    return (
      <div className={ classes.progressContainer }>
        <CircularProgress disableShrink={true} classes={{ root: classes.progress }}/>
      </div>
    );
  } else {
    return children || null;
  }
};

export default Loadable;
