import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const SaloonSelection = ({ saloonData, setSelectedSaloon }) => {
  return (
    <DialogContent>
      <List>
        {
          saloonData.map(saloon => (
            <>
              <ListItem onClick={ _ => setSelectedSaloon(saloon) } button>
                <ListItemText
                  primary={ saloon.name }
                  secondary={ saloon.stock + ' in stock' }
                />
                <ChevronRightIcon />
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))
        }
      </List>
    </DialogContent>
  )
};

export default SaloonSelection;

