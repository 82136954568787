import React, { useState, useContext } from 'react';
import useForm from '../utils/useForm';
import authService from '../utils/authService';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Errors } from '../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',

    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  paper: {
    margin: 'auto',
    marginTop: '10rem',
    maxWidth: '30rem',
    padding: theme.spacing(2),

    '& .MuiPaper-root': {
      backgroundColor: '#eee'
    }
  }
}));

const Signup = ({ history, userType }) => {
  const { errors, setErrors } = useContext(Errors);
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();
  const [values, handleChange] = useForm({
    email: '',
    password: ''
  });

  // Signup user
  const handleSubmit = e => {
    if(!clicked) {
      e.preventDefault();

      authService.signupUser(values)
        .then(_ => {
          history.push(`/${userType}/login`);
        })
        .catch((err) => {
          setErrors([err]);
          setClicked(false);
        });
    }
    setClicked(true);
  }

  return (
    <div className={ classes.root }>
      <Paper className={ classes.paper } variant="outlined">
        <Typography variant="h2" gutterBottom>
          Super Admin Signup
        </Typography>

        <form onSubmit={ handleSubmit }>
          <TextField
            label="Name"
            id="name"
            name="name"
            type="text"
            variant="filled"
            value={ values.name }
            onChange={ handleChange }
          />
          <br />

          <TextField
            label="Email"
            id="email"
            name="email"
            type="email"
            variant="filled"
            value={ values.email }
            onChange={ handleChange }
          />
          <br />

          <TextField
            label="Phone number"
            id="phone_number"
            name="phone_number"
            type="text"
            variant="filled"
            value={ values.phoneNumber }
            onChange={ handleChange }
          />
          <br />


          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            variant="filled"
            value={ values.password }
            onChange={ handleChange }
          />
          <br />

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={ clicked }
          >
            Signup
          </Button>
        </form>

        <div>
          <p>Already have an account?</p>
          <a href="/super-admin/login">Login now</a>
        </div>
      </Paper>
    </div>
  );
}

export default Signup;
