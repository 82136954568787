import React, { useState, useContext } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useForm from '../utils/useForm';
import authService from '../utils/authService';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { splitNCapitalize } from '../utils/helpers';
import { Errors, Success } from '../App.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',

    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  paper: {
    margin: 'auto',
    marginTop: '10rem',
    maxWidth: '30rem',
    padding: theme.spacing(2),

    '& .MuiPaper-root': {
      backgroundColor: '#eee'
    }
  }
}));

const Login = ({ history, userType }) => {
  const { errors, setErrors } = useContext(Errors);
  const { success, setSuccess } = useContext(Success);
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();
  const [values, handleChange] = useForm({
    email: '',
    password: '',
    forgotPassword: false
  });

  // Login user
  const handleSubmit = e => {
    if(!clicked) {
      e.preventDefault();
      let userEntity = (userType === 'super-admin') ? 'admin' : userType;

      if(values.forgotPassword) {
        authService.authFetch(`/api/${userEntity}/forgot-password`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: values.email
          })
        }).then(_ => setSuccess({ message: 'If your account exists an email has been sent.'}))
          .catch(err => {
            setErrors([err]);
            setClicked(false);
          });
      } else {
        authService.loginUser(values, userType)
          .then(_ => {
            history.push(`/${userType}/login`);
          })
          .catch((err) => {
            setErrors([err]);
            setClicked(false);
          });
      }
    }
    setClicked(true);

  }

  return (
    <div className={ classes.root }>
      {
        ['barber', 'admin', 'super-admin'].map(a => (
          <>
            { userType !== a &&
            <Button
              onClick={ () => history.push(`/${a}/login`) }
            >
              Login as { splitNCapitalize(a) }
            </Button>
            }
          </>
        ))
      }

      <Paper className={ classes.paper } variant="outlined">
        <Typography variant="h2" gutterBottom>
          { splitNCapitalize(userType) } Login
        </Typography>

        <form onSubmit={ handleSubmit }>
          <TextField
            label="Email"
            id="email"
            name="email"
            type="email"
            variant="filled"
            value={ values.email }
            onChange={ handleChange }
          />
          <br />

          {
            values.forgotPassword ||
              <>
                <TextField
                  label="Password"
                  id="password"
                  name="password"
                  type="password"
                  variant="filled"
                  value={ values.password }
                  onChange={ handleChange }
                />
                <br />
              </>
          }

          <FormControlLabel
            control={
              <Checkbox
                name="forgotPassword"
                checked={ values.forgotPassword }
                style={{ color: '#1a263a' }}
                onChange={ handleChange }
              />
            }
            label="Forgot Password"
          />
          <br />

          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={ clicked }
          >
            { values.forgotPassword ? 'Send Email' : 'LOGIN' }
          </Button>
        </form>

        {
          userType === 'super-admin' &&
            <div>
              <p>Don't have a super admin account?</p>
              <a href="/super-admin/signup">Sign up</a>
            </div>
        }
      </Paper>
    </div>
  );
}

export default Login;
