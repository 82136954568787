import React, { useState , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import authService from '../../../utils/authService';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { Errors } from '../../../App.js';

const getStrTimeRange = (obj) => {
  return obj?.start_time + ' - ' + obj?.end_time;
};

const AdminDialog = ({ workingHours, handleClose, userType, setUpdateTrigger }) => {
  const { errors, setErrors } = useContext(Errors);

  const handleAction = (action) => {
    authService.authFetch(`/api/${userType}/barbers/working-hours/${action}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ workingHourId: workingHours.to.barber_working_hour_id })
    }).then(() => {
      setUpdateTrigger(updateTrigger => !updateTrigger);
      handleClose();
    }).catch(err => setErrors([err]));

    handleClose();
  };

  const text = {
    added: `Barber has added the following working hours: 
              ${getStrTimeRange(workingHours?.to)}`,
    edited: `Barber has edited the following working hours: 
              From ${getStrTimeRange(workingHours?.from)}
              To ${getStrTimeRange(workingHours?.to)}`,
    deleted: `Barber has deleted the following working hours: 
              ${getStrTimeRange(workingHours?.from)}`,
  }[workingHours?.status];

  return (
    <>
      <DialogTitle>
        Barber has {workingHours?.status} these working hours.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{ text }</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ backgroundColor: '#f33155' }}
          onClick={ _ => handleAction('reject') }
        >
          Reject
        </Button>

        <Button
          style={{ backgroundColor: '#2883d2' }}
          onClick={ _ => handleAction('confirm') }
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  )
};

const availableTimes = new Array(96).fill(0).map((a, i) => {
  a = i * 15;
  return moment({ hour: Math.floor(a / 60), minute: a % 60}).format('HH:mm');
});

const availableRepeats = [ 'Don\'t Repeat', 'Weekly' ];

const useStyles = makeStyles(() => ({
  select: {
    marginRight: '.5rem'
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
}));


const BarberDialog = ({ workingHours, date, setUpdateTrigger, handleClose, userType, barberId }) => {
  const { errors, setErrors } = useContext(Errors);
  const classes = useStyles();
  const [startTime, setStartTime] = useState(
    workingHours?.working_hours?.start_time.slice(0, 5) || '09:00'
  );
  const [endTime, setEndTime] = useState(
    workingHours?.working_hours?.end_time.slice(0, 5) || '17:00'
  );
  const [hasRepeat, setHasRepeat] = useState(availableRepeats[
    (workingHours?.working_hours?.start_date === workingHours?.working_hours?.end_date) ?
      0 : 1
  ]);

  const handleAction = (action) => {
    const param = userType === 'barber' ? 'profile' : 'barbers';

    authService.authFetch(`/api/${userType}/${param}/working-hours/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        startTime,
        endTime: (action !== 'edit') ? startTime : endTime,
        startDate: moment(date).format('YYYY-MM-DD'),
        endDate: (
          (
            action === 'delete-all' || (action === 'edit' && hasRepeat === 'Weekly')
          ) ? null : moment(date).format('YYYY-MM-DD')
        ),
        weekday: workingHours?.working_hours?.weekday ??
          (parseInt(moment(date).format('d')) + 6) % 7 ?? 6,
        barber: barberId
      })
    }).then(() => {
      setUpdateTrigger(updateTrigger => !updateTrigger);
      handleClose();
    }).catch(err => setErrors([err]));
  };

  return (
    <>
      <DialogTitle>
        { workingHours?.status ? 'Edit' : 'Add' } Working Hours
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{ date?.format('dddd, D MMMM YYYY') }</DialogContentText>

        <Select
          className={ classes.select }
          native
          value={ startTime }
          variant="outlined"
          onChange={ e => setStartTime(e.target.value) }
          inputProps={{
            name: 'start-time',
            id: 'start-time',
          }}
        >
          {
            availableTimes.map((a, i) => (
              <option key={i} value={ a }>{ a }</option>
            ))
          }
        </Select>

        <Select
          className={ classes.select }
          native
          value={ endTime }
          variant="outlined"
          onChange={ e => setEndTime(e.target.value) }
          inputProps={{
            name: 'end-time',
            id: 'end-time',
          }}
        >
          {
            availableTimes.map((a, i) => (
              <option key={i} value={ a }>{ a }</option>
            ))
          }
        </Select>

        <Select
          className={ classes.select }
          native
          value={ hasRepeat }
          variant="outlined"
          onChange={ e => setHasRepeat(e.target.value) }
          inputProps={{
            name: 'has-repeat',
            id: 'has-repeat',
          }}
        >
          {
            availableRepeats.map((a, i) => (
              <option key={i} value={ a }>{ a }</option>
            ))
          }
        </Select>
      </DialogContent>
      <DialogActions>
        { workingHours?.status &&
          <>
            <Button
              className={ classes.delete }
              onClick={ _ => handleAction('delete-all') }
            >
              Delete Upcoming Shifts
            </Button>
            <Button
              className={ classes.delete }
              onClick={ _ => handleAction('delete-one') }
            >
              Delete This Shift Only
            </Button>
          </>
        }

        <Button
          className={ classes.primary }
          onClick={ _ => handleAction('edit') }
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
};

const EditDialog = ({ handleClose, workingHours, date, userType, setUpdateTrigger, barberId }) => {
  return (
    <Dialog
      open={ Boolean(date || workingHours) }
      onClose={ handleClose }
    >
      {
        (userType === 'barber' || workingHours && (!workingHours.status || workingHours?.status === 'normal')) ? (
          <BarberDialog
            userType={ userType }
            setUpdateTrigger={ setUpdateTrigger }
            date={ date }
            barberId={ barberId }
            workingHours={ workingHours }
            userType={ userType }
            handleClose={ handleClose }
          />
        ) : (
          <AdminDialog
            setUpdateTrigger={ setUpdateTrigger }
            workingHours={ workingHours }
            userType={ userType }
            handleClose={ handleClose }
          />
        )
      }
    </Dialog>
  )
};

export default EditDialog;
