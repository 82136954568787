import React, { useState , useContext, useEffect } from 'react';
import authService from '../../utils/authService';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from './Autocomplete';
import { capitalizeFirst } from '../../utils/helpers';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import currencyFormatter from 'currency-formatter';
import TaxDialog from './TaxDialog';
import { Errors } from '../../App.js';

const useStyles = makeStyles(_ => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    '& > *': {
      padding: '.25rem 2rem',
      '& > *:nth-child(2)': {
        position: 'absolute',
        right: 0,
        left: 0,
        textAlign: 'center',
        zIndex: -1
      }
    }
  },
  buttonWrapper: {
    position: 'absolute',
    right: '0',
  },
  scroll: {
    padding: '0 3rem',
    paddingTop: '6rem',
    backgroundColor: '#f0f0f2',
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    borderRadius: '4px',
    margin: '.5rem',
    padding: '.5rem 3rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    borderRadius: '4px',
    margin: '.5rem',
    padding: '.5rem 3rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  form: {
    '& > *': {
      width: '100%',
      maxWidth: '30rem',
      display: 'block',
      '& + *': {
        marginTop: '1rem',
      },
    },
  },
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
  },
}));

const NewProductDialog = ({
  userType,
  open,
  handleSubmit,
  handleDelete,
  availableOptions,
  setUpdateTrigger,
  isNew,
  product,
  toBaseCurrency,
  toTargetCurrency
}) => {
  const { errors, setErrors } = useContext(Errors);
  const classes = useStyles();

  const initializeForm = () => product ? {
    title: product.title,
    category: availableOptions.category.filter(a => a.name === product.category)[0],
    brand: availableOptions.brand.filter(a => a.name === product.brand)[0],
    taxes: product.taxes,
    retailPrice: toTargetCurrency(product.retail_price),
    specialPrice: toTargetCurrency(product.special_price),
    barcode: product.barcode,
    sku: product.sku,
    description: product.description,
  } : {
    title: '',
    category: { name: '', id: 0 },
    brand: { name: '', id: 0 },
    taxes: [],
    retailPrice: 0,
    specialPrice: 0,
    barcode: '',
    sku: '',
    description: '',
  };

  const [formData, setFormData] = useState(initializeForm());
  const [isTaxDialogOpen, setIsTaxDialogOpen] = useState(false);


  useEffect(() => {
    setFormData(initializeForm());
  }, [ open ])

  const newOptionHandler = async (value, key) => {
    let pluralKey = key + 's';

    if(key === 'category') {
      pluralKey = 'categories';
    }

    try {
      // Post new option
      const res = await authService.authFetch(`/api/${userType}/${pluralKey}/new`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: value })
      });

      const json = await res.json();
      return json.id;
    } catch(err) {
      setErrors([err]);
    }
  };

  const closeHandler = () => handleSubmit(false);

  const submitHandler = () => {
    let taxVal = Object.keys(formData.taxes ?? {}).map(key => ({ saloon: key, tax: parseInt(formData.taxes[key]) || null }));
    let taxPropName;

    if(userType === 'super-admin') {
      taxPropName = 'taxes';
    } else {
      taxPropName = 'tax';
      taxVal = taxVal[0]?.tax;
    }

    handleSubmit(true, {
      ...formData,
      [taxPropName]: taxVal,
      retailPrice: toBaseCurrency(formData.retailPrice),
      specialPrice: toBaseCurrency(formData.specialPrice),
      brand: formData.brand.id,
      category: formData.category.id,
    });
  }

  return (
    <>
      <Dialog
        fullScreen
        open={ open }
        onClose={ closeHandler }
      >
        <AppBar className={ classes.appBar }>
          <Toolbar>
            <IconButton edge="start" onClick={ closeHandler } aria-label="close">
              <CloseIcon />
            </IconButton>

            <h2>
              { isNew ? 'Create' : 'Edit' } Product
            </h2>

            <div className={ classes.buttonWrapper }>
              { !isNew &&
                <Button
                  className={ classes.delete }
                  onClick={ handleDelete }
                >
                  Delete
                </Button>
              }

              <Button
                className={ classes.primary }
                onClick={ submitHandler }
              >
                Save
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        <div className={ classes.scroll }>
          <Card className={ classes.card }>
            <CardHeader
              title="Product Details"
            />
            <CardContent>
              <form className={ classes.form }>
                <TextField
                  fullWidth
                  label="Product name"
                  value={ formData.title }
                  variant="outlined"
                  onChange={ e => setFormData({ ...formData, title: e.target.value }) }
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                {
                  Object.keys(availableOptions).filter(a => a !== 'tax').map(a =>
                    <Autocomplete
                      key={ a }
                      fullWidth
                      label={ capitalizeFirst(a) }
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={ formData[a] }
                      handleAddOption={ option => newOptionHandler(option, a) }
                      onChange={ val => setFormData({ ...formData, [a]: val }) }
                      options={ availableOptions[a].slice(1) }
                    />
                  )
                }

                <TextField
                  fullWidth
                  label="Retail Price"
                  type="number"
                  value={ formData.retailPrice}
                  variant="outlined"
                  onChange={ e => setFormData({ ...formData, retailPrice: e.target.value }) }
                  InputProps={{
                    startAdornment: currencyFormatter.findCurrency(authService.getToken().currency).symbol
                  }}
                />

                <TextField
                  fullWidth
                  label="Special Price"
                  type="number"
                  value={ formData.specialPrice}
                  variant="outlined"
                  onChange={ e => setFormData({ ...formData, specialPrice: e.target.value }) }
                  InputProps={{
                    startAdornment: currencyFormatter.findCurrency(authService.getToken().currency).symbol
                  }}
                />

                <Button variant="outlined" onClick={ _ => setIsTaxDialogOpen(true) }>Tax</Button>
                <TaxDialog
                  defaultValue={ (product?.taxes || []).reduce((pr, cur) => ({ ...pr, [cur.saloon]: cur.tax_id }), {}) }
                  taxes={ availableOptions.tax }
                  open={ isTaxDialogOpen }
                  closeHandler={ _ => setIsTaxDialogOpen(false) }
                  updateHandler={ taxes => {
                    setFormData({ ...formData, taxes });
                    setIsTaxDialogOpen(false);
                  }}
                />

                <TextField
                  fullWidth
                  label="Barcode"
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText="(ISBN, UPC, etc.)"
                  value={ formData.barcode }
                  variant="outlined"
                  onChange={ e => setFormData({ ...formData, barcode: e.target.value }) }
                />

                <TextField
                  fullWidth
                  label="SKU"
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText="Stock Keeping Unit"
                  value={ formData.sku }
                  variant="outlined"
                  onChange={ e => setFormData({ ...formData, sku: e.target.value }) }
                />

                <TextField
                  fullWidth
                  label="Description"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={ formData.description }
                  variant="outlined"
                  onChange={ e => setFormData({ ...formData, description: e.target.value }) }
                  multiline
                  rows={4}
                />

              </form>
            </CardContent>
          </Card>
        </div>
      </Dialog>
    </>
  );
}

export default NewProductDialog;
