import React from 'react';

const markerStyle = {
  position: 'absolute',
  width: 10,
  height: 10,
  left: -10 / 2,
  top: -10 / 2,

  border: '5px solid #f44336',
  borderRadius: 30,
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4,
  cursor: 'pointer'
};

const Marker = ({ selected, text, ...props }) => {
  return (
    <div style={{...markerStyle, borderColor: selected ? '#3e8ed0' : '#f14668' }}>
      <div style={{
        display: !props.$hover ? 'none' : 'table',
        padding: '.3rem',
        backgroundColor: selected ? '#3e8ed0' : '#f14668' ,
        borderRadius: '5px',
        color: 'white',
        marginTop: '-2rem'
      }}>
        {text}
      </div>
    </div>
  )
};

export default Marker;
