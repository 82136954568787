import React, { useState, useEffect , useContext } from 'react';
import NewSaloonDialog from './NewSaloonDialog';
import Marker from './Marker';
import { effectFetch } from '../../utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { Success, Errors } from '../../App.js';
import GoogleMapReact from 'google-map-react';
import ConfirmationDialog from '../../utils/ConfirmationDialog';

const useStyles = makeStyles(_ => ({
  toolbar: {
    margin: '0 auto',
    marginTop: '2rem',
    maxWidth: '52rem',
    textAlign: 'right'
  },
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
    '& > div + div': {
      borderTop: '1px solid #ccc',
    },
    '& > div > div': {
      marginBottom: '1.5rem'
    },
    '& .button-container': {
      display: 'flex',

      '& button + button': {
        marginLeft: '1rem',
        padding: '.5rem',
        fontSize: '1rem',
      }
    },
  },
  delete: {
    backgroundColor: '#f74969',
    color: 'white',
    borderRadius: '4px',
    padding: '.5rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#ff7191'
    },
  },
  emptyMessage: {
    width: '100%',
    marginTop: '10rem',
    textAlign: 'center'
  }
}));

const SaloonSetup = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const classes = useStyles();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [saloons, setSaloons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [mapIndex, setMapIndex] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const [saloonToDelete, setSaloonToDelete] = useState(null);

  const handleDialogClose = () => setIsDialogOpen(false);

  useEffect(() => {
    setLoading(true);

    return effectFetch(
      `/api/${userType}/saloons/all?limit=100`,
      json => {
        setSaloons(json.data || []);
        setLoading(false);
      },
      err => console.log(err)
    )
  }, [updateTrigger])

  const handleSubmit = (id, name, address) => {
    authService.authFetch(`/api/${userType}/saloons`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id,
        name,
        address
      })
    }).then(_ => {
      setUpdateTrigger(pr => !pr);
      setSuccess({ message: 'Saloon Updated' });
    })
      .catch(err => setErrors([err]));
  }

  const handleNewSaloon = (name, address) => {
    authService.authFetch(`/api/${userType}/saloons/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        address
      })
    }).then(_ => {
      setUpdateTrigger(pr => !pr);
      setIsDialogOpen(false);
      setSuccess({ message: 'Saloon Created' });
    }).catch(err => setErrors([err]));
  }

  const changeSaloon= (index, setting, value) => {
    setSaloons(pr => [
      ...pr.slice(0, index),
      { ...pr[index], [setting]: value },
      ...pr.slice(index + 1, pr.length)
    ]);
  };

  const handleDelete = id => {
    authService.authFetch(`/api/${userType}/saloons/`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    }).then(_ => {
      setUpdateTrigger(pr => !pr);
      setSuccess({ message: 'Saloon Deleted' });
    }).catch(err => setErrors([err]));

    setSaloonToDelete(null);
  }

  const handleLocationChange = async (index, address) => {
    const response = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=" + process.env.REACT_APP_MAPS_KEY);
    const json = await response.json();
    changeSaloon(index, 'location', [json.results[0]?.geometry?.location?.lat, json.results[0]?.geometry?.location?.lng]);

    if(json.results.length === 0) {
      setErrors([{ message: 'Invalid address' }]);
    }
  }

  return (
    <div>
      <ConfirmationDialog
        text={ "Deleting this saloon is permanent, all transactions and appointments associated with this saloon will be lost. Only related barbers and admins will remain" }
        handleSubmit={ _ => handleDelete(saloonToDelete) }
        isOpen={ saloonToDelete }
        handleClose={ _ => setSaloonToDelete(null) }
      />

      <div className={ classes.toolbar }>
        <Button
          className={ classes.primary }
          onClick={ _ => setIsDialogOpen(true) }
          variant="contained"
          color="primary"
        >
          New Saloon
        </Button>
      </div>

      {
        (saloons.length === 0 && !loading) &&
          <div className={ classes.emptyMessage }>
            <h2>No saloons yet</h2>
            <p>Create a saloon to see this page</p>
          </div>
      }

      {
        (isDialogOpen) &&
          <NewSaloonDialog
            saloons={ saloons }
            handleClose={ handleDialogClose }
            handleSubmit={ handleNewSaloon }
          />
      }

      { saloons.map((saloon, i) => (
        <Card className={ classes.card }>
          <CardHeader
            title={saloon.name}
          />
          <CardContent>
            <TextField
              label="Name"
              id={ saloon.saloon_id + '-name' }
              name="name"
              value={ saloon.name }
              onChange={ e => changeSaloon(i, 'name', e.target.value) }
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              label="Address"
              id={ saloon.saloon_id + '-address' }
              name="address"
              value={ saloon.address }
              onChange={ e => changeSaloon(i, 'address', e.target.value) }
              onBlur={e => { handleLocationChange(i, e.target.value); setDisabled(null); }}
              onFocus={ _ => { setMapIndex(i); setDisabled(i); }}
              fullWidth
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {
              mapIndex === i &&
                <div style={{height: '400px'}}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
                    center={ saloon.location?.every(a => !isNaN(a)) ? saloon.location : [52, -1] }
                    defaultZoom={14}
                  >
                    {
                      saloons?.filter(a => a.location && a.location.every(b => !isNaN(b))).map(a =>
                        <Marker
                          selected={saloons[i].saloon_id === a.saloon_id}
                          text={a.name}
                          lat={a.location?.[0]}
                          lng={a.location?.[1]}
                        />
                      )
                    }
                  </GoogleMapReact>
                </div> ||
                <Button
                  fullWidth
                  style={{ marginBottom: '1rem' }}
                  color="primary"
                  onClick={ _ => setMapIndex(i) }
                >
                  Open Map
                </Button>
            }

            <div className="button-container">
              <Button
                fullWidth
                variant="contained"
                className={ classes.delete }
                onClick={ () => setSaloonToDelete(saloon.saloon_id) }
              >
                Delete
              </Button>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={ disabled === i }
                onClick={ () => handleSubmit(saloon.saloon_id, saloon.name, saloon.address) }
              >
                Save
              </Button>
            </div>
        </CardContent>
        </Card>
      )) }
    </div>
  )
};

export default SaloonSetup;
