import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from './authService';

const AuthRoute = ({ render, userType, authed, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  (async () => {
    setIsAuthed(await authService.isAuthenticated(userType));
    setIsLoading(false);
  })();

  if(isLoading) {
    return ( <>Loading...</> );
  } else {

    // If authentication is what we want.
    // Example: we need to render normally if user is authed.
    // This is what stays if the user is indeed authed.
    let toRender = props => render(props);

    // If authentication is the opposite of what we want.
    // Example: we need to render normally if user is authed.
    // This is what happens if the user is not authed.
    if(isAuthed !== authed) {
      if(isAuthed) {
        // If user is authed then redirect to home.
        // These are routes that we don't want authenticated users to access.
        toRender = _ => <Redirect to={ `/${userType}/home` } />
      } else {
        // If user is not authed then redirect to login.
        // These are routes that we don't want unauthenticated users to access.
        toRender = _ => <Redirect to={ `/${userType}/login` } />
      }
    }

    return (
      <Route
        { ...rest }
        render={ toRender }
      />
    );
  }
};

export default AuthRoute;
