import React, { useState, useEffect , useContext } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import authService from '../utils/authService';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import TableContainer from '@material-ui/core/TableContainer';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { formatDuration, effectFetch } from '../utils/helpers';
import moment from 'moment';
import CheckoutDialog from './appointments/CheckoutDialog';
import FilterDrawer from './inventory/FilterDrawer';
import useCurrency from '../utils/useCurrency';
import { Errors } from '../App.js';

const statusColors = {
  confirmed: '#2883d2',
  cancelled: '#f33155',
  pending: '#f5a623',
  completed: '#0bc2b0',
  rescheduled: '#b028d2'
};

const useStyles = makeStyles(_ => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '.75rem',
    '& > button': {
      backgroundColor: 'white',
      padding: '.5rem 1.5rem',
    }
  },
  status: {
    color: 'white',
    borderRadius: '1rem',
    padding: '.25rem',
    width: '100%',
    textAlign: 'center'
  },
  statusWrapper: {
    padding: '0'
  },
  root: {
    margin: '1.5rem'
  },
  select: {
    margin: '1rem 0',
    borderRadius: '4px',
    padding: '.6rem 1.6rem',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: 'white'
    }
  },
}));

const availableStatuses = [
  { id: 0, index: 0, name: 'All' },
  { id: 1, index: 1, name: 'Pending' },
  { id: 2, index: 2, name: 'Rescheduled' },
  { id: 3, index: 3, name: 'Confirmed' },
  { id: 4, index: 4, name: 'Completed' },
  { id: 5, index: 5, name: 'Cancelled' },
];

const Appointments = ({ userType }) => {
  const { errors, setErrors } = useContext(Errors);
  const { formatCurrency } = useCurrency();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [status, setStatus] = useState(availableStatuses[0].name);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [selectedSaloon, setSelectedSaloon] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Fetch appointments
  useEffect(() => {
    const statusQuery = (status === 'All') ? '' : '&statuses=' + status.toLowerCase();
    const saloonQuery = (!selectedSaloon) ? '' : '&saloon=' + selectedSaloon;

    return effectFetch(
      `/api/${userType}/appointments/status?limit=20&page=${page + 1}${statusQuery}${saloonQuery}`,
      json => setAppointments((json.data || [])) ,
      err => console.log(err)
    );
  }, [ userType, status, page, selectedSaloon, updateTrigger ]);

  // Fetch availableSaloons
  useEffect(() => {
    let toReturn = () => null;

    if(userType === 'super-admin') {
      toReturn = effectFetch(
        `/api/super-admin/saloons/all?limit=100`,
        json => {
          if(json.data.length === 0) {
            json.data = [{ name: 'N/A', id: -1 }];
          };
          const avSaloons = json.data.map((a, i) => ({
              name: a.name,
              id: a.saloon_id,
              index: i
            }));

          setAvailableSaloons(avSaloons);

          if(avSaloons.length > 0) {
            setSelectedSaloon(avSaloons[0].id);
          }
        },
        err => console.log(err)
      );
    }

    return toReturn
  }, [ userType ]);

  // Reset page when status, or selectedSaloon is changed
  useEffect(() => setPage(0), [ status, selectedSaloon ]);

  const handleChangePage = (_, newPage) => {
    if(appointments.length === 20 || newPage < page) {
      setPage(newPage);
    }
  }

  const handleClose = _ => setSelectedAppointment(null);

  const handleAction = (action) => {
    authService.authFetch(`/api/barber/appointments/${action}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ appointment: selectedAppointment.appointment_id })
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
    }).catch(err => setErrors([err]));

    handleClose();
  };

  const handleNewTransaction = (obj) => {
    authService.authFetch(`/api/${userType}/transactions/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(data => data.json())
      .then(json => {
        setUpdateTrigger(!updateTrigger);
        handleClose();
      }).catch(err => setErrors([err]));
  };

  return (
    <div className={ classes.root }>
      {
        userType === 'super-admin' &&
          <FilterDrawer
            userType={ userType }
            availableOptions={{ saloon: availableSaloons, status: availableStatuses }}
            handleChange={ filters => {
              if(filters !== null) {
                setSelectedSaloon(filters.saloon);
                setStatus(availableStatuses[filters.status].name);
              }

              setIsDrawerOpen(false);
            }}
            open={ isDrawerOpen }
          />
      }

      <CheckoutDialog
        userType={ userType }
        open={ Boolean(selectedAppointment?.status === 'confirmed') }
        handleClose={ handleClose }
        handleSubmit={ handleNewTransaction }
        appointment={ selectedAppointment }
        formatCurrency={ formatCurrency }
      />

      <Dialog
        open={ Boolean(selectedAppointment && selectedAppointment.status !== 'confirmed') }
        onClose={handleClose}
      >
        <DialogTitle>Reject or Confirm Appointment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pending appointment with { selectedAppointment?.customer } at
            { moment(selectedAppointment?.time).format('H:mm MMMM Do, YYYY') }.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { selectedAppointment?.status === 'confirmed' &&
          <Button
            onClick={ _ => handleAction('complete') }
            style={{ backgroundColor: statusColors.completed }}
          >
            Complete
          </Button>
          }

          { selectedAppointment?.status === 'pending' &&
            <>
              <Button
                onClick={ _ => handleAction('reject') }
                style={{ backgroundColor: statusColors.cancelled }}
              >
                Reject
              </Button>
              <Button
                onClick={ _ => handleAction('confirm') }
                style={{ backgroundColor: statusColors.confirmed }}
              >
                Confirm
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>

      <div className={ classes.toolbar }>
        {
          userType === 'super-admin' &&
            <Button
              onClick={ _ => setIsDrawerOpen(true) }
              align="right"
              variant="contained"
              endIcon={ <FilterListIcon /> }
            >
              Filters
            </Button>
        }
      </div>

        <Card>
          <CardContent>
            <TableContainer component="paper">
              <Table>
                <TableHead>
                  <TableRow>
                    { userType === 'barber' && <TableCell /> }
                    {
                      [
                        'Client',
                        'Service',
                        'Date',
                        'Time',
                        'Duration',
                        'Staff',
                        'Location',
                        'Price',
                        'Status'
                      ].map(a => (
                        <TableCell
                          key={a}
                          className={ classes.tableHeader }
                        >
                          { a }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    appointments.map((a, i) => (
                      <TableRow key={i}>
                        { userType === 'barber' &&
                          <TableCell>
                            <IconButton
                              disabled={ a.status !== 'pending' && a.status !== 'confirmed' }
                              onClick={ _ => setSelectedAppointment(a) }
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        }
                        <TableCell>
                          { a.customer || 'Walk In' }
                        </TableCell>
                        <TableCell>
                          { a.services.map(a => a.name).join(', ') }
                        </TableCell>
                        <TableCell>
                          { moment(a.time).format('D MMM YYYY') }
                        </TableCell>
                        <TableCell>
                          { moment(a.time).format('hh:mm') }
                        </TableCell>
                        <TableCell>
                          { formatDuration(a.duration) }
                        </TableCell>
                        <TableCell>
                          { a.barber }
                        </TableCell>
                        <TableCell>
                          { a.saloon }
                        </TableCell>
                        <TableCell>
                          { formatCurrency(a.gross) }
                        </TableCell>
                        <TableCell  className={ classes.statusWrapper }>
                          <div
                            className={ classes.status }
                            style={{ backgroundColor: statusColors[a.status] }}
                          >
                            { a.status.toUpperCase() }
                          </div>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[ 20 ]}
              component="div"
              count={
                (appointments.length === 20) ? (-1) : (20 * page + appointments.length)
              }
              rowsPerPage={20}
              page={ page }
              onChangePage={ handleChangePage }
              nextIconButtonProps={{ disabled: appointments.length < 20 }}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  )
};

export default Appointments;
