import React, { useState, useEffect, useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { effectFetch } from '../../utils/helpers';
import AppointmentTableRow from './AppointmentTableRow';
import { Errors } from '../../App.js';

const useStyles = makeStyles(() => ({
  card: {
    flexBasis: '45%',
    flexGrow: 1,
    borderRadius: '3px',
    margin: '.75rem',
    height: '560px',
    overflow: 'auto'
  }
}));

const NextAppointmentsCard = ({ userType, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [nextAppointments, setNextAppointments] = useState([]);

  // Fetch data
  useEffect(() => {
    return effectFetch(
      `/api/${userType}/appointments/next?limit=5&page=1`,
      json => setNextAppointments(json.data || []),
      err => console.log(err)
    );
  }, [ userType ]);

  return (
    <Card className={ classes.card }>
      <CardHeader
        title="Today's Next Appointments"
      />
      <CardContent>
        <Table>
          <TableBody>
            {
              nextAppointments.map((a, i) => (
                <AppointmentTableRow
                  key={i}
                  appointment={ a }
                  formatCurrency={ formatCurrency }
                />
              ))
            }

            { nextAppointments.length <= 0 && <h3><hr/>None<hr/></h3> }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
};

export default NextAppointmentsCard;
