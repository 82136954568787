import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { formatDuration, effectFetch } from '../../utils/helpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import authService from '../../utils/authService';
import currencyFormatter from 'currency-formatter';
import { Errors } from '../../App.js';

const paddingForInput = {
  padding: '.75rem 1rem',
};

const wrapperForInput = {
  width: '55%',
  marginBottom: '1rem'
};

const useStyles = makeStyles(_ => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    '& > *': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '.25rem 2rem'
    }
  },
  content: {
    backgroundColor: '#f0f0f2',
  },
  scroll: {
    padding: '0 3rem',
    paddingTop: '6rem'
  },
  primary: {
    backgroundColor: '#1a263a',
    color: 'white',
    borderRadius: '4px',
    margin: '.5rem',
    padding: '.5rem 3rem',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#3c485c'
    },
  },
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
    '& > div + div': {
      borderTop: '1px solid #ccc',
    }
  },
  input: {
    ...wrapperForInput,
    '& div': {
      width: '100%'
    },
    '& div input': paddingForInput
  },
  inputPadding: paddingForInput,
  inputWrapper: wrapperForInput
}));

const availableGenders = [
  'Everyone',
  'Males only',
  'Females only'
];

const availableDurations = new Array(12).fill(0).map((a, i) => (i + 1) * 15);

const NewServiceDialog = ({ service, isDialogOpen, handleSubmit, toBaseCurrency }) => {
  const classes = useStyles();
  const [serviceName, setServiceName] = useState(service?.title ?? '');
  const [serviceDescription, setServiceDescription] = useState(service?.description ?? '');
  const [serviceDuration, setServiceDuration] = useState(service?.duration ?? availableDurations[0]);
  const [serviceExtraDuration, setServiceExtraDuration] = useState(service?.extra_duration ?? availableDurations[0]);
  const [servicePrice, setServicePrice] = useState(service?.price ?? '');
  const [availableFor, setAvailableFor] = useState(service?.available_for ?? availableGenders[0]);
  const [hasExtraTime, setHasExtraTime] = useState(service?.has_extra_time ?? false);
  const [availableTaxes, setAvailableTaxes] = useState([{ tax_id: null, tax_name: 'None', tax_rate: 0 }]);
  const [selectedTax, setSelectedTax] = useState(service?.tax?.id);

  useEffect(() => {
    setServiceName(service?.title ?? '');
    setServiceDescription(service?.description ?? '');
    setServiceDuration(service?.duration ?? availableDurations[0]);
    setServiceExtraDuration(service?.extra_duration ?? availableDurations[0]);
    setServicePrice(service?.price ?? '');
    setAvailableFor(service?.available_for ?? availableGenders[0]);
    setHasExtraTime(service?.has_extra_time ?? false);
    setSelectedTax(service?.tax?.id ?? 0)
  }, [isDialogOpen]);

  useEffect(() => {
    return effectFetch(
      '/api/admin/payments/taxes/all?limit=100',
      json => {
        const taxes = json.data[0]?.taxes || [];
        taxes.unshift({ tax_id: null, tax_name: 'None', tax_rate: 0 });

        setAvailableTaxes(taxes);
        setSelectedTax(0);
      },
      err => console.log(err)
    );
  }, []);

  const handleClose = () => handleSubmit(false);

  const submitHandler = () => handleSubmit(true, {
    title: serviceName,
    description: serviceDescription,
    duration: serviceDuration,
    extraDuration: serviceExtraDuration,
    price: toBaseCurrency(servicePrice),
    availableFor,
    hasExtraTime,
    tax: selectedTax
  });

  return (
    <>
      <Dialog
        fullScreen
        classes={{ paper: classes.content }}
        open={ isDialogOpen }
        onClose={ handleClose }
      >
        <AppBar className={ classes.appBar }>
          <Toolbar>
            <IconButton edge="start" onClick={ handleClose } aria-label="close">
              <CloseIcon />
            </IconButton>
            <h2>
              Add a new Service
            </h2>
            <Button
              className={ classes.primary }
              onClick={ submitHandler }
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>

        <div className={ classes.scroll }>
          <Card className={ classes.card }>
            <CardHeader
              title="Basic Info"
              subheader="Add a service name and choose the treatment type."
            />
            <CardContent>
              <TextField
                label="Service Name"
                value={ serviceName }
                variant="outlined"
                required
                onChange={ e => setServiceName(e.target.value) }
                className={ classes.input }
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                label="Service Description"
                value={ serviceDescription }
                variant="outlined"
                required
                onChange={ e => setServiceDescription(e.target.value) }
                className={ classes.input }
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <InputLabel htmlFor="available-for">Service available for</InputLabel>
              <Select
                native
                value={ availableFor }
                variant="outlined"
                onChange={ e => setAvailableFor(e.target.value) }
                className={ classes.inputWrapper }
                inputProps={{
                  className: classes.inputPadding,
                  name: 'available-for',
                  id: 'available-for',
                }}
              >
                {
                  availableGenders.map((a, i) => (
                    <option key={i} value={ a }>{ a }</option>
                  ))
                }
              </Select>
            </CardContent>
          </Card>

          <Card className={ classes.card }>
            <CardHeader
              title="Pricing and Duration"
              subheader="Add the pricing options and duration of the service."
            />
            <CardContent>
              <InputLabel htmlFor="duration">Duration</InputLabel>
              <Select
                native
                value={ serviceDuration }
                variant="outlined"
                onChange={ e => setServiceDuration(e.target.value) }
                className={ classes.inputWrapper }
                inputProps={{
                  className: classes.inputPadding,
                  name: 'duration',
                  id: 'duration',
                }}
              >
                {
                  availableDurations.map((a, i) => (
                    <option key={i} value={ a }>{ formatDuration(a) }</option>
                  ))
                }
              </Select>

              <TextField
                label="Price"
                type="number"
                value={ servicePrice }
                variant="outlined"
                required
                onChange={ e => setServicePrice(e.target.value) }
                className={ classes.input }
                InputProps={{
                  startAdornment: currencyFormatter.findCurrency(authService.getToken().currency).symbol
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </CardContent>
          </Card>

          <Card className={ classes.card }>
            <CardHeader
              title="Extra Time"
              subheader="Enable extra time after the service."
            />
            <CardContent>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={ hasExtraTime }
                    onChange={ _ => setHasExtraTime(!hasExtraTime) }
                  />
                }
                label="Enable extra time"
              />

              { hasExtraTime &&
                <>
                  <InputLabel htmlFor="extra-duration">Extra Time Duration</InputLabel>
                  <Select
                    native
                    value={ serviceExtraDuration }
                    variant="outlined"
                    onChange={ e => setServiceExtraDuration(e.target.value) }
                    className={ classes.inputWrapper }
                    inputProps={{
                      className: classes.inputPadding,
                      name: 'extra-duration',
                      id: 'extra-duration',
                    }}
                  >
                    {
                      availableDurations.map((a, i) => (
                        <option key={i} value={ a }>{ formatDuration(a) }</option>
                      ))
                    }
                  </Select>
                </>
              }
            </CardContent>
          </Card>

          <Card className={ classes.card }>
            <CardHeader
              title="Sales settings"
              subheader="Set the tax rate"
            />
              <InputLabel htmlFor="tax-rate">Tax (included in price)</InputLabel>
              <Select
                native
                value={ selectedTax }
                variant="outlined"
                onChange={ e => setSelectedTax(e.target.value) }
                className={ classes.inputWrapper }
                inputProps={{
                  className: classes.inputPadding,
                  name: 'tax',
                  id: 'tax',
                }}
              >
                {
                  availableTaxes.map((a, i) => (
                    <option key={i} value={ a.tax_id }>{a.tax_name} ({a.tax_rate}%)</option>
                  ))
                }
              </Select>
            <CardContent>
            </CardContent>
          </Card>
        </div>
      </Dialog>
    </>
  );
}

export default NewServiceDialog;
