import React, { useState } from 'react';
import StaffWorkingHoursTab from './staff/StaffWorkingHoursTab';
import HolidayTab from './staff/HolidayTab';
import ServicesTab from './staff/ServicesTab';
import MembersTab from './staff/MembersTab';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from './staff/TabPanel';

const useStyles = makeStyles(() => ({
  appbar: {
    background: 'white',
    marginBottom: '1.5rem',
    boxShadow: '0 4px 4px rgba(0, 0, 0, .2)',
    color: 'black'
  }
}));

const Staff = ({ userType }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (_, newVal) => {
    setValue(newVal);
  }

  return (
    <>
      <AppBar position="static" className={ classes.appbar }>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Staff Working Hours" />
          <Tab label="Staff Holidays" />
          <Tab label="Closed Dates" />
          <Tab label="Services" />
          { userType !== 'barber' && <Tab label="Staff Members" /> }
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <StaffWorkingHoursTab userType={ userType } />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HolidayTab userType={ userType } onlySaloon={ false } />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HolidayTab userType={ userType } onlySaloon={ true } />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ServicesTab userType={ userType } />
      </TabPanel>
      {
        userType !== 'barber' &&
          <TabPanel value={value} index={4}>
            <MembersTab userType={ userType } />
          </TabPanel>
      }
    </>
  );
};

export default Staff;
