import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import Home from './reportsTab/Home';
import { links } from './reportsTab/reportData';
import ReportPage from './reportsTab/ReportPage';
import InternalStockConsumptionReportPage from './reportsTab/InternalStockConsumptionReportPage';

const ReportsTab = ({ userType, formatCurrency }) => {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        {
          links.map(link => (
            <Route key={ link } path={ `${path}/${link}` }>
              {
                link === 'internal-stock-consumption' ? (
                  <InternalStockConsumptionReportPage
                    userType={ userType }
                    path={ link }
                    formatCurrency={ formatCurrency }
                  />
                ) : (
                  <ReportPage
                    userType={ userType }
                    path={ link }
                    formatCurrency={ formatCurrency }
                  />
                )
              }
            </Route>
          ))
        }

        <Route path='*'>
          <Home userType={ userType }/>
        </Route>
      </Switch>
    </div>
  )
};

export default ReportsTab;
