import React, { useState, useEffect, useContext, useCallback } from 'react';
import XLSX from 'xlsx';
import moment from 'moment';
import DatePicker from '../calendar/DatePicker';
import Export from './Export';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import FilterDrawer from '../inventory/FilterDrawer';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import authService from '../../utils/authService';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InvoiceDialog from './invoicesTab/InvoiceDialog';
import { splitNCapitalize, setFontPDF, effectFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
applyPlugin(jsPDF);

const useStyles = makeStyles(() => ({
  status: {
    color: 'white',
    borderRadius: '1rem',
    padding: '.25rem',
    width: '100%',
    textAlign: 'center',
    maxWidth: '6rem'
  },
  wrapper: {
    padding: '1rem'
  },
  statusWrapper: {
    padding: '0'
  },
  container: {
    padding: '.75rem'
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '.75rem',
    marginTop: 0,
    '& > div + div > button': {
      backgroundColor: 'white',
      padding: '.5rem 1.5rem',
      '& + button': {
        marginLeft: '.5rem',
      }
    }
  },
  textField: {
    backgroundColor: 'white',
    marginLeft: '1rem',
    margin: 0,
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(1)': {
      color: '#368dff',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}));

const statusColors = {
  voided: '#f33155',
  refund: '#f33155',
  completed: '#0bc2b0',
};

const generateExcelOrCSV = (date, invoices, isCSV, formatCurrency) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Invoices",
    CreatedDate: new Date()
  };

  const invoicesAOA = invoices
    .map(a =>
      [
        a.transaction_id,
        a.customer || 'Walk In',
        splitNCapitalize(a.status),
        a.type,
        moment(a.date).format('D MMM YYYY'),
        a.saloon,
        formatCurrency(a.total)
      ]
    );
  invoicesAOA.unshift([
    'Invoice #',
    'Client',
    'Status',
    'Type',
    'Invoice Date',
    'Location',
    'Gross Total',
  ]);

  wb.SheetNames.push("Invoices Report");

  wb.Sheets["Invoices Report"] = XLSX.utils.aoa_to_sheet(invoicesAOA);

  XLSX.writeFile(wb, 'invoices_' + date.format('YYYY-MM-DD') + ((isCSV) ? '.csv' : '.xlsx'));
};

const generatePDF = ({
  date,
  selectedSaloon,
  invoices,
  formatCurrency
}) => {
  const doc = new jsPDF();
  setFontPDF(doc);

  doc.text('Invoices Report', 10, 10);
  doc.setFontSize(7);
  doc.text(
    `${
      date.format('dddd, D MMM YYYY')
    }, ${
      selectedSaloon || 'All locations'
    }, generated ${
      moment().format('DD MMM YYYY [at] HH:mm')
    }`,
    10,
    15
  );

  doc.autoTable({
    startY: 30,
    head: [['Invoice #', 'Client', 'Status', 'Type', 'Invoice Date', 'Location', 'Gross Total']],
    body: invoices.map(a => [
      a.transaction_id.toString(),
      a.customer || 'Walk In',
      a.status,
      a.type,
      moment(a.date).format('D MMM YYYY'),
      a.saloon,
      formatCurrency(a.total)
    ])
  });

  doc.save(`invoices_${date.format('YYYY-MM-DD')}.pdf`);
};

const InvoicesTab = ({ userType, availableSaloons, formatCurrency }) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [date, setDate] = useState(moment());
  const [page, setPage] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(0);
  const [selectedSaloon, setSelectedSaloon] = useState(0);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getInvoiceUrl = (pageIn) => {
    const saloonQuery = (selectedSaloon === 0) ? '' : 'saloon=' + selectedSaloon;
    const search = (searchValue !== '') ? `searchTerm=${searchValue}` : '';
    const dateQuery = `date=${date.format('YYYY-MM-DD')}`;

    return `/api/${userType}/transactions?limit=20&page=${pageIn + 1}&${saloonQuery}&${search}&${dateQuery}`;
  }

  const getAllInvoices = async () => {
    const allInvoices = [];
    let i = 0;

    do {
      const res = await authService.authFetch(getInvoiceUrl(i));
      allInvoices.push(...(await res.json()).data);
      i ++;
    } while(allInvoices.length === i * 20)

    return allInvoices;
  }

  const fetchInvoices = useCallback(() => {
    return effectFetch(
      getInvoiceUrl(page),
      json => setInvoices((json.data || [])),
      err => console.log(err)
    );
  }, [page, selectedSaloon, searchValue, date]);

  // Fetch invoices
  useEffect(fetchInvoices, [ userType, page, updateTrigger, date, selectedSaloon ]);

  // Reset page when selectedSaloon or date is changed
  useEffect(() => setPage(0), [ selectedSaloon, date, searchValue ]);

  const handleChangePage = (_, newPage) => {
    if(invoices.length === 20 || newPage < page) {
      setPage(newPage);
    }
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Enter') {
      fetchInvoices();
    }
  };

  const handleClose = _ => setSelectedInvoice(null);

  return (
    <div className={ classes.wrapper }>
      <FilterDrawer
        userType={ userType }
        availableOptions={{ saloon: availableSaloons }}
        handleChange={ filters => {
          if(filters !== null) {
            setSelectedSaloon(filters.saloon);
          }

          setIsDrawerOpen(false);
        }}
        open={ isDrawerOpen }
      />

      <InvoiceDialog
        handleClose={ handleClose }
        invoice={ selectedInvoice }
        setInvoice={ setSelectedInvoice }
        userType={ userType }
        updateTrigger={ () => setUpdateTrigger(!updateTrigger) }
        formatCurrency={ formatCurrency }
      />

      <div className={ classes.toolbar }>
        <div>
          <DatePicker
            type="1day"
            dates={{ start: date, end: date }}
            onChange={ dates => setDate(dates.start) }
          />

          <TextField
            className={ classes.textField }
            label="Search"
            margin="dense"
            variant="outlined"
            onChange={ e => setSearchValue(e.target.value) }
            onKeyDown={ handleKeyDown }
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="search-icon"
                  onClick={ _ => {
                    setPage(0);
                    fetchInvoices();
                  }}
                  edge="end"
                  style={{ padding: 0 }}
                >
                  <SearchIcon />
                </IconButton>
              )
            }}
          />
        </div>

        <div>
          <Export
            generatePDF={ async _ => generatePDF({ date, selectedSaloon, invoices: (await getAllInvoices()), formatCurrency }) }
            generateExcel={ async _ => generateExcelOrCSV(date, await getAllInvoices(), false, formatCurrency) }
            generateCSV={ async _ => generateExcelOrCSV(date, await getAllInvoices(), true, formatCurrency) }
          />

          { userType === 'super-admin' &&
          <Button
            onClick={ _ => setIsDrawerOpen(true) }
            align="right"
            variant="contained"
            endIcon={ <FilterListIcon /> }
          >
            Filters
          </Button>
          }
        </div>
      </div>

      <div className={ classes.container }>
        <Card>
          <CardContent>
            <TableContainer component="paper">
              <Table>
                <TableHead>
                  <TableRow>
                    {
                      [
                        'Invoice #',
                        'Client',
                        'Status',
                        'Type',
                        'Invoice Date',
                        'Location',
                        'Gross Total',
                      ].map(a => (
                        <TableCell
                          key={a}
                        >
                          { a }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    invoices.map((a, i) => (
                      <TableRow
                        className={ classes.row }
                        key={i}
                        onClick={ () => setSelectedInvoice(a.transaction_id) }
                      >
                        <TableCell>
                          { a.transaction_id }
                        </TableCell>
                        <TableCell>
                          { a.customer || 'Walk In' }
                        </TableCell>
                        <TableCell  className={ classes.statusWrapper }>
                          <div
                            className={ classes.status }
                            style={{ backgroundColor: statusColors[a.status] }}
                          >
                            { a.status.toUpperCase() }
                          </div>
                        </TableCell>
                        <TableCell>
                          { a.type }
                        </TableCell>
                        <TableCell>
                          { moment(a.date).format('D MMM YYYY') }
                        </TableCell>
                        <TableCell>
                          { a.saloon }
                        </TableCell>
                        <TableCell>
                          { formatCurrency(a.total) }
                        </TableCell>
                      </TableRow>
                    ))
                }
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[ 20 ]}
                component="div"
                count={
                  (invoices.length === 20) ? (-1) : (20 * page + invoices.length)
                }
                rowsPerPage={20}
                page={ page }
                onChangePage={ handleChangePage }
                nextIconButtonProps={{ disabled: invoices.length < 20 }}
              />
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default InvoicesTab;
