import React, { useState, useEffect , useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import authService from '../../utils/authService';
import { backupFetch } from '../../utils/helpers';
import { Errors } from '../../App.js';

const useStyles = makeStyles(_ => ({
  card: {
    maxWidth: '50rem',
    padding: '1rem',
    margin: '2rem auto',
    borderRadius: '.5rem',
    '& > div + div': {
      borderTop: '1px solid #ccc',
    },
    '& > div > div': {
      marginBottom: '1.5rem'
    },
    '& button': {
      padding: '.5rem',
      fontSize: '1rem',
      backgroundColor: '#1a263a',
      '&:hover': {
        backgroundColor: '#3c485c'
      },
    }
  },
}));

const availableDays = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];

const PersonalSettings = ({ userType }) => {
  const { errors, setErrors } = useContext(Errors);
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState(0);
  const [availableCurrencies, setAvailableCurrencies] = useState(['EUR']);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  // Get current settings
  useEffect(() => {
    const { weekStart, currency } = authService.getToken();
    setSelectedDay(weekStart);
    setSelectedCurrency(currency);
  }, []);

  // Get available currencies
  useEffect(() => {
    backupFetch([
      `http://data.fixer.io/api/latest?access_key=${process.env.REACT_APP_FIXER_KEY}`,
      `http://api.exchangeratesapi.io/v1/latest?access_key=${process.env.REACT_APP_EXCHANGE_RATES_KEY}`
    ])
      .then(data => {
        const arr = [data.base, ...Object.keys(data.rates)].reduce((pr, cur) => {
          const mainCurr = [ 'EUR', 'USD', 'GBP' ];
          const curIndex = mainCurr.findIndex(a => cur === a);

          if(curIndex === -1) {
            pr.push(cur);
          } else {
            pr.unshift(cur);
          }

          return pr;
        }, []);

        setAvailableCurrencies(arr);
      })
  }, [])

  const handleSubmit = () => {
    authService.authFetch(`/api/${userType === 'super-admin' ? 'admin' : userType}/profile/`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ weekStart: selectedDay, currency: selectedCurrency })
    }).then(data => data.json())
      .then(json => {
        authService.setToken(json.newToken);
        window.location.reload();
      })
      .catch(err => setErrors([err]));
  };

  return (
    <div>
      <Card className={ classes.card }>
        <CardHeader
          title="Personal settings"
        />
        <CardContent>
          <InputLabel htmlFor="day">Start of week</InputLabel>
          <Select
            native
            fullWidth
            value={ selectedDay }
            variant="outlined"
            onChange={ e => setSelectedDay(e.target.value) }
            inputProps={{
              name: 'day',
              id: 'day',
            }}
          >
            {
              availableDays.map((a, i) => (
                <option key={i} value={ i }>{ a }</option>
              ))
            }
          </Select>

          <InputLabel htmlFor="currency">Currency</InputLabel>
          <Select
            native
            fullWidth
            value={ selectedCurrency || '' }
            variant="outlined"
            onChange={ e => setSelectedCurrency(e.target.value) }
            inputProps={{
              name: 'currency',
              id: 'currency',
            }}
          >
            {
              availableCurrencies.map((a, i) => (
                <option key={i} value={ a }>{ a }</option>
              ))
            }
          </Select>

          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={ handleSubmit }
          >
            Save
          </Button>
        </CardContent>
      </Card>
    </div>
  )
};

export default PersonalSettings;
