import React from 'react';
import PaymentTypes from './PaymentTypes';
import Taxes from './Taxes';
import PersonalSettings from './PersonalSettings';
import FeeSettings from './FeeSettings';
import SaloonSetup from './SaloonSetup';

export const cards = [
  {
    title: 'Configurations',
    description: 'General configurations for your saloons',
    links: [
      {
        link: 'saloon-setup',
        hide: userType => (userType !== 'super-admin'),
        page: props => <SaloonSetup { ...props } />
      },
      {
        link: 'payment-types',
        hide: userType => (userType === 'barber'),
        page: props => <PaymentTypes { ...props } />
      },
      {
        link: 'taxes',
        hide: userType => (userType === 'barber'),
        page: props => <Taxes { ...props } />
      },
      {
        link: 'fee-settings',
        hide: userType => (userType === 'barber'),
        page: props => <FeeSettings { ...props } />
      },
      {
        link: 'personal-settings',
        hide: _ => false,
        page: props => <PersonalSettings { ...props } />
      },
    ]
  },
];

export const links = cards.reduce((pr, cur) => {
  return [
    ...pr,
    ...cur.links
  ];
}, [])
