import moment from 'moment';
import arial from './arial';
import authService from './authService';

const MILISECONDS_IN_A_DAY = 86400000;

export const formatDuration = duration => {
  if(duration === 0) {
    return '0min';
  }

  const durationHours = Math.trunc(duration / 60);
  const durationMins = duration % 60;

  const durationStr =
    (durationHours ? (durationHours + 'h ') : '') +
    (durationMins ? (durationMins + 'min') : '');

  return durationStr;
};

export const daysBetween = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return Math.floor((d1 - d2) / MILISECONDS_IN_A_DAY);
};

export const getShortDay = (date) => {
  const weekDays = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

  return weekDays[date.getDay()];
};

export const getDateInXDays = (days) => {
  return new Date(Date.now() + days * MILISECONDS_IN_A_DAY);
}

export const dateToBackEndFriendlyStr = (dateObj) => {
  let date = dateObj.getDate();
  date = date < 10 ? '0' + date : date;
  let month = dateObj.getMonth() + 1;
  month = month < 10 ? '0' + month : month;

  return `${dateObj.getFullYear()}/${month}/${date}`;
};

export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const splitNCapitalize = (str) => {
  return str.split(/[-|_]/).map(a => capitalizeFirst(a)).join(' ');
}

export const duplicateObj = (obj) => JSON.parse(JSON.stringify(obj));

export const changeWeekStart = (weekStart) => {
  moment.updateLocale('en', {
    week: {
      dow: (weekStart + 1) % 7
    }
  });
}

export const setFontPDF = (doc) => {
  doc.addFileToVFS("Arial-Regular.ttf", arial);
  doc.addFont("Arial-Regular.ttf", "Arial", "normal");

  doc.setFont("Arial"); // set font
}

// Uses authFetch and cancels previous fetch each time it is recalled. Should be
// used it useEffect blocks like so:
// useEffect(() => {
//   return effectFetch(...);
// })
export const effectFetch = (url, thenFunc, catchFunc) => {
  const abortController = new AbortController();

  authService.authFetch(url, { signal: abortController.signal })
    .then(data => data.json())
    .then(thenFunc)
    .catch(catchFunc)

  // Cleanup
  return () => abortController.abort();
}

export const backupFetch = async (urls) => {
  const errors = [];

  for(let i of urls) {
    try {
      const res = await fetch(i);
      const data = await res.json();

      if (!data.success)
        throw data.error;

      return data;
    } catch(err) {
      errors.push(err)
    }
  }

  throw errors;
}
