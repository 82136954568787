import React, { useState, useEffect, useContext } from 'react';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import authService from '../../../utils/authService';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { capitalizeFirst, setFontPDF, effectFetch } from '../../../utils/helpers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import RefundDialog from './RefundDialog';
import { Errors } from '../../../App.js';

const VoidDialog = ({ open, handleClose, handleVoid }) => (
  <Dialog open={ open } onClose={ handleClose }>
    <DialogTitle>Void Invoice</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to void this invoice? This action is permanent and cannot be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        style={ primaryButton }
        onClick={ handleVoid }
        variant="contained"
      >
        Void Invoice
      </Button>
    </DialogActions>
  </Dialog>
);

const primaryButton = {
  backgroundColor: '#1a263a',
  color: 'white',
  '&:hover': {
    backgroundColor: '#3c485c'
  },
};

const useStyles = makeStyles(_ => ({
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    '& > *': {
      padding: '.25rem 2rem',
      '& > *:nth-child(2)': {
        position: 'absolute',
        right: 0,
        left: 0,
        textAlign: 'center',
        zIndex: -1
      }
    }
  },
  container: {
    paddingTop: '4.5rem',
    backgroundColor: '#f0f0f2',
    height: 'calc(100vh - 4.5rem)',
    display: 'flex',
    alignItems: 'stretch',
  },
  rightContainer: {
    borderLeft: 'solid 1px #ddd',
    backgroundColor: 'white',
    flex: '4 4',
    display: 'flex',
    flexDirection: 'column',

    // Divs inside
    '& > div': {
      flex: '1 1',
      padding: '0 1.5rem',

      // First div's h3
      '&:first-child > h3': {
        margin: '2rem 0',
      },

      // Second div
      '&:nth-child(2)': {
        flex: '6 6',

        // Status container
        '& > div:first-child': {
          height: '85%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },

      },

      // Last div's buttons
      '&:last-child > button': {
        margin: '1rem 0',
        fontSize: '1rem',
        padding: '.6rem',
        width: '11.75rem',

        // First Button
        '&:first-child': {
          backgroundColor: 'white'
        },

        // Second button
        '&:last-child': {
          marginLeft: '3rem',
          ...primaryButton
        },
      },

      // Borders
      '& + div': {
        borderTop: '1px solid #ddd'
      },
    },
  },
  leftContainer: {
    padding: '3rem',
    flex: '8 8',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',

    // Card
    '& > div > div': {
      maxWidth: '25rem',
      padding: '2rem',
      paddingBottom: 0,
      marginBottom: '3rem',

      // Card Sections
      '& > div': {

        // Card Heading
        '&:first-child': {
          textAlign: 'center',
          borderBottom: '1px solid #eee',
          marginBottom: '1.5rem',
        },

        // Card sections
        '&:last-child > div': {
          padding: '0 0 1.5rem 0',

          // Items
          '&:first-child > div': {
            padding: '.5rem 0',
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',

            // Second item of flex
            '& > div + div': {
              marginLeft: '1rem'
            }
          },

          // Horizontal bars
          '& + div': {
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            borderTop: '1px solid #eee',

            // Items in flex
            '& > div': {
              flexBasis: '50%',
              marginTop: '1.5rem',

              // Even items in flex
              '&:nth-child(2n)': {
                textAlign: 'right'
              }
            }
          }
        }
      }
    },
  },
  grey: {
    color: 'rgba(0, 0, 0, .54)',
  },
  cardContent: {
    padding: 0
  },
  menu: {
    width: '11.75rem'
  },
  link: {
    color: '#368dff',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const getSubtotal = (items) =>
  items.reduce((pr, cur) => pr + ((cur.quantity || 1) * (cur.net || 0)), 0);

const getTaxGroups = (items) =>
  items.map((a) => ({
    taxName: a.taxName,
    taxRate: a.taxRate,
    value: (a.quantity || 1) * (a.gross - a.net)
  })).filter(a => a.taxName)
    .reduce((pr, cur) => {
      const index = pr.findIndex(b => b.taxName === cur.taxName && b.taxRate === cur.taxRate);

      if(index !== -1) {
        pr[index].value += cur.value;
      } else {
        pr.push({ ...cur });
      }

      return pr;
    }, []);

const generatePDF = ({ id, invoiceData, formatCurrency }) => {
  const doc = new jsPDF();
  setFontPDF(doc);

  doc.setFont('helvetica', 'bold');
  doc.text('Invoice ' + id, 105, 30, { align: 'center' });
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(10);
  doc.text(moment(invoiceData.date).format('dddd, DD MMM YYYY, H:mm'), 105, 35, { align: 'center' });
  if(invoiceData.status === 'voided') {
    doc.text('Voided', 105, 40, { align: 'center' });
  }
  doc.setTextColor('#555');

  if(invoiceData.refund_of) {
    doc.text('Refund of original invoice #' + invoiceData.refund_of, 105, 45, { align: 'center' });
  }
  doc.setTextColor('#000');
  doc.text('Client', 15, 50);
  doc.setDrawColor('#999');
  doc.line(10, 52, 196, 52);
  doc.text(invoiceData.customer || 'Walk In', 15, 57);
  doc.line(10, 64, 196, 64);

  let y;
  invoiceData.items.forEach((item, i) => {
    y = 69 + i * 12;
    doc.text((item.quantity ?? 1).toString(), 15, y);

    if(item.service) {
      doc.text(item.service, 20, y);
      doc.text(formatCurrency(item.gross), 191, y, { align: 'right' });
      doc.setFontSize(9);
      doc.setTextColor('#555');
      doc.text('with ' + item.barber, 20, y + 5);
      doc.setFontSize(10);
      doc.setTextColor('#000');
    } else {
      doc.text(item.name, 20, y);
      doc.text(formatCurrency(item.gross * item.quantity), 191, y, { align: 'right' });
      doc.setFontSize(9);
      doc.setTextColor('#555');
      doc.text(item.barcode + ', ' + item.brand, 20, y + 5);
      doc.setFontSize(10);
      doc.setTextColor('#000');
    }
    doc.line(10, y + 7, 196, y + 7);
  });

  y += 12;
  doc.text('Subtotal', 15, y);
  doc.text(formatCurrency(getSubtotal(invoiceData.items)), 191, y, { align: 'right' });
  getTaxGroups(invoiceData.items).forEach(a => {
    y += 4;
    doc.text(`${a.taxName} ${a.taxRate}%`, 15, y);
    doc.text(formatCurrency(a.value), 191, y, { align: 'right' });
  })

  y += 3;
  doc.line(10, y, 196, y);

  y += 5;
  doc.text('Total', 15, y);
  doc.text(formatCurrency(invoiceData.total), 191, y, { align: 'right' });
  y += 3;
  doc.line(10, y, 196, y);

  y += 5;
  doc.text(invoiceData.payment_type, 15, y);
  doc.text(formatCurrency(invoiceData.total), 191, y, { align: 'right' });

  return doc
};

const downloadPDF = ({ id, invoiceData, formatCurrency }) => {
  const doc = generatePDF({ id, invoiceData, formatCurrency });
  doc.save(`invoice_${id}.pdf`);
};

const printPDF = ({ id, invoiceData, formatCurrency }) => {
  const doc = generatePDF({ id, invoiceData, formatCurrency });
  doc.autoPrint();
  doc.output('dataurlnewwindow');
};


const InvoiceDialog = ({ handleClose, invoice, setInvoice, userType, updateTrigger, formatCurrency }) => {
  const { errors, setErrors } = useContext(Errors);
  const classes = useStyles();
  const [invoiceData, setInvoiceData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVoidDialogOpen, setIsVoidDialogOpen] = useState(false);
  const [isRefundDialogOpen, setIsRefundDialogOpen] = useState(false);

  useEffect(() => {
    if(!invoice) {
      return;
    }

    return effectFetch(
      `/api/${userType}/transactions/single?id=${invoice}`,
      json => {
        setInvoiceData(json.data);
      },
      err => console.log(err)
    );
  }, [ invoice, userType ]);

  const handleVoid = () => {
    authService.authFetch(`/api/${userType}/transactions/void`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: invoice })
    }).then(_ => {
      setIsVoidDialogOpen(false);
      handleClose();
      updateTrigger();
    }).catch(err => setErrors([err]));
  };

  const handleRefund = (obj) => {
    authService.authFetch(`/api/${userType}/transactions/new`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    }).then(data => data.json())
      .then(json => {
        updateTrigger();
        setIsRefundDialogOpen(false);
        setInvoice(json.data.id);
      }).catch(err => setErrors([err]));
  };

  const handleDialogClose = () => setAnchorEl(null);

  const hasProducts = Boolean(invoiceData?.items.find(a => !a.service));
  let rightContainerMessage;

  if(invoiceData) {
    rightContainerMessage = (
      <div>
        {
          {
            completed: 'Full payment received on ',
            refund: 'Refund created on ',
            voided: 'Voided on ',
          }[invoiceData.status]
            + moment(invoiceData.date).format('dddd, D MMM YYYY')
            + ' at ' + invoiceData.saloon
        }
        { (invoiceData.status === 'completed' && invoiceData.associated_refund) &&
          <>
            {
              ', Refunded '
                + moment(invoiceData.associated_refund.date).format('dddd, D MMM YYYY')
                + ' with '
            }
            <a
              className={ classes.link}
              onClick={ _ => setInvoice(invoiceData.associated_refund.id) }
            >
              #{ invoiceData.associated_refund.id }
            </a>
          </>
        }
      </div>
    );
  }

  return (
    <Dialog
      fullScreen
      open={ Boolean(invoice) }
      onClose={ handleClose }
    >
      <VoidDialog
        open={ isVoidDialogOpen }
        handleClose={ () => setIsVoidDialogOpen(false) }
        handleVoid={ handleVoid }
      />

      <RefundDialog
        userType={ userType }
        open={ isRefundDialogOpen }
        handleClose={ () => setIsRefundDialogOpen(false) }
        handleSubmit={ handleRefund }
        invoice={ invoiceData }
        formatCurrency={ formatCurrency }
      />

      <AppBar className={ classes.appBar }>
        <Toolbar>
          <IconButton edge="start" onClick={ handleClose }>
            <CloseIcon />
          </IconButton>

          <h2>
            View Invoice
          </h2>
        </Toolbar>
      </AppBar>

      <div className={ classes.container }>
        <div className={ classes.leftContainer }>
          <div>
            <Card>
              <CardHeader
                title={ `Invoice #${invoiceData?.transaction_id}` }
                subheader={
                  <>
                    <p>{ moment(invoiceData?.date).format('dddd, D MMM YYYY') }</p>
                    { invoiceData?.refund_of &&
                      <p>
                        { 'Refund of original invoice ' }
                        <a
                          className={ classes.link }
                          onClick={_=>setInvoice(invoiceData?.refund_of) }
                        >
                          #{ invoiceData?.refund_of }
                        </a>
                      </p>
                    }
                  </>
                }
              />
              <CardContent className={ classes.cardContent }>
                { invoiceData &&
                <>

                  <div>
                    {
                      invoiceData.items.map((item) => (
                        <div>
                          <div>
                            <div className={ classes.grey }>
                              { (item.quantity || 1) + ' ' }
                              item{ (item.quantity || 1) !== 1 && 's'}
                            </div>
                            <div>{ item.name || item.service }</div>
                            { item.time ? (
                              <div className={ classes.grey }>
                                { moment(item.time).format('HH:mm DD MMM YYYY') } with
                                { ' ' + item.barber }
                              </div>
                            ) : (
                              <div className={ classes.grey }>
                                { item.barcode }, { item.brand }, { item.saloon }
                              </div>
                            )
                            }
                          </div>
                          <div>
                            { formatCurrency(item.gross * (item.quantity || 1)) }
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div>
                    <div>
                      <div>Subtotal</div>
                    </div>
                    <div>
                      { formatCurrency(getSubtotal(invoiceData.items)) }
                    </div>
                    {
                      getTaxGroups(invoiceData.items).map(a => (
                        <>
                          <div>
                            <div>{a.taxName} {a.taxRate}%</div>
                          </div>
                          <div>{ formatCurrency(a.value) }</div>
                        </>
                      ))
                    }
                  </div>

                  <div>
                    <div>
                      <div>Total</div>
                    </div>
                    <div>
                      { formatCurrency(invoiceData.total) }
                    </div>
                  </div>

                  <div>
                    <div>
                      <div>{ invoiceData.payment_type }</div>
                      <div className={ classes.grey }>
                        {
                          moment(invoiceData.date).format('dddd, D MMM YYYY [at] HH:mm')
                        }
                      </div>
                    </div>
                    <div>
                      { formatCurrency(invoiceData.total) }
                    </div>
                  </div>
                </>
                }
              </CardContent>
            </Card></div></div>

        <div className={ classes.rightContainer }>
          <div>
            <h3>{ invoiceData?.customer_name || 'Walk In' }</h3>
          </div>

          <div>
            <div>
              <h2>{ capitalizeFirst(invoiceData?.status || '') }</h2>
              { rightContainerMessage }
            </div>

          </div>
          <div>
            <Button
              variant="contained"
              endIcon={
                Boolean(anchorEl) ?
                  <KeyboardArrowUpIcon/> :
                  <KeyboardArrowDownIcon/>
              }
              onClick={ e => setAnchorEl(e.currentTarget) }
            >
              More Options
            </Button>

            <Menu
              classes={{ paper: classes.menu }}
              getContentAnchorEl={null}
              anchorEl={ anchorEl }
              open={ Boolean(anchorEl) }
              onClose={ handleDialogClose }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={ _ => {
                  downloadPDF({ id: invoice, invoiceData, formatCurrency});
                }}
              >
                Download
              </MenuItem>
              <MenuItem
                onClick={ _ => {
                  printPDF({ id: invoice, invoiceData, formatCurrency });
                }}
              >
                Print
              </MenuItem>
              {
                (
                  invoiceData?.status === 'completed' &&
                  !invoiceData?.associated_refund &&
                  hasProducts
                ) &&
                  <MenuItem
                    style={{ color: '#f74969' }}
                    onClick={ () => {
                      setIsRefundDialogOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    Refund
                  </MenuItem>
              }
              {
                (
                  invoiceData?.status === 'completed' &&
                  !invoiceData?.associated_refund
                ) &&
                  <MenuItem
                    style={{ color: '#f74969' }}
                    onClick={ () => {
                      setIsVoidDialogOpen(true);
                      setAnchorEl(null);
                    }}
                  >
                    Void
                  </MenuItem>
              }
            </Menu>
            <Button
              variant="contained"
              onClick={ handleClose }
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
};

export default InvoiceDialog;
