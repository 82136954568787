import React, { useEffect, useState, useContext, useCallback } from 'react';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import authService from '../../utils/authService';
import { formatDuration, effectFetch } from '../../utils/helpers';
import useCurrency from '../../utils/useCurrency';
import Checkbox from '@material-ui/core/Checkbox';
import { Errors, Success } from '../../App.js';

const useStyles = makeStyles(() => ({
  table: {
    margin: '1rem auto 3rem auto',
    maxWidth: '1024px',
    border: '1px solid #e2e6ea'
  },
  tableRow: {
    background: 'white',
    borderLeft: '5px #a5dff8 solid',
    cursor: 'pointer'
  },
  cell: {
    fontSize: '1rem',
    fontWeight: '500'
  },
  toolbar: {
    margin: '0 auto',
    marginTop: '2rem',
    maxWidth: '1024px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div > div': {
      backgroundColor: 'white',
    },
    '& > div > div + div': {
      marginLeft: '1rem'
    },
    '& button': {
      marginLeft: '1rem',
      backgroundColor: '#1a263a',
      color: 'white',
      borderRadius: '4px',
      padding: '.5rem 1.5rem',
      '&:hover': {
        backgroundColor: '#3c485c'
      },
    }
  },
}));

const Services = ({ userType }) => {
  const { setErrors } = useContext(Errors);
  const { setSuccess } = useContext(Success);
  const { formatCurrency } = useCurrency();
  const classes = useStyles();

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [availableSaloons, setAvailableSaloons] = useState([]);
  const [availableBarbers, setAvailableBarbers] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);

  const [selectedSaloon, setSelectedSaloon] = useState();
  const [selectedBarber, setSelectedBarber] = useState();

  const [barberServices, setBarberServices] = useState([]);

  // Fetch availableSaloons
  useEffect(() => {
    if(userType !== 'super-admin') {
      return;
    }

    return effectFetch(
      `/api/super-admin/saloons/all?limit=100`,
      json => {
        const avSaloons = (json.data || [])
          .map((a, i) => ({
            name: a.name,
            id: a.saloon_id,
            index: i
          }));

        setAvailableSaloons(avSaloons);
        setSelectedSaloon(avSaloons[0]);
      },
      err => console.log(err)
    );
  }, [ userType ]);

  // Fetch availableBarbers
  useEffect(() => {
    if(userType === 'barber') {
      return;
    }

    return effectFetch(
      `/api/${userType}/barbers/all?${selectedSaloon?.id && 'saloon=' + selectedSaloon?.id}&limit=100`,
      json => {
        const avBarbers = (json.data || []).map((a, i) => ({
          name: a.name,
          id: a.id,
          index: i
        }));

        setAvailableBarbers(avBarbers);
        setSelectedBarber(avBarbers[0]);
      },
      err => console.log(err)
    );
  }, [ selectedSaloon, userType ]);

  const fetchBarberServices = useCallback(() => {
    const barberQuery = (selectedBarber?.id) ? `barber=${selectedBarber?.id}&` : '';
    const saloonQuery = (selectedSaloon?.id) ? `saloon=${selectedSaloon?.id}&`  : '';

    const url = {
      barber: '/api/barber/services/self?',
      admin: '/api/admin/services?' + barberQuery,
      'super-admin': '/api/super-admin/services?' + barberQuery + saloonQuery
    }[userType] + 'limit=100';

    return effectFetch(
      url,
      json => setBarberServices((json.data || [])),
      err => console.log(err)
    );
  }, [selectedSaloon, selectedBarber]);

  // Fetch available services
  useEffect(() => {
    if(!selectedSaloon && userType === 'super-admin') return;

    const saloonQuery = (selectedSaloon?.id !== 0) ? `saloon=${selectedSaloon?.id}`  : '';

    const url = {
      barber: '/api/barber/services/saloon?',
      admin: '/api/admin/services?',
      'super-admin': '/api/super-admin/services?' + saloonQuery + '&'
    }[userType] + 'limit=100';

    return effectFetch(
      url,
      json => setAvailableServices((json.data || [])),
      err => console.log(err)
    );
  }, [selectedSaloon]);

  // Fetch barber services
  useEffect(fetchBarberServices, [ selectedBarber, updateTrigger]);

  const submitHandler = () => {
    const url = {
      barber: '/api/barber/profile/services',
      admin: '/api/admin/barbers/services',
      'super-admin': '/api/super-admin/barbers/services'
    }[userType];

    authService.authFetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          barber: selectedBarber?.id,
          saloon: selectedSaloon?.id,
          services: barberServices.map(a => a.service_id)
      })
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
      setSuccess({ message: "Services updated" });
    }).catch(err => setErrors([err]));
  };

  return (
    <div>
      <div className={ classes.toolbar }>
        <div>
          { (userType === 'super-admin' && availableSaloons.length > 0) && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                native
                value={ selectedSaloon?.index }
                onChange={ e => setSelectedSaloon(availableSaloons[e.target.value]) }
                inputProps={{
                  name: 'saloon',
                  id: 'saloon',
                }}
              >
                {
                  availableSaloons.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}

          { (userType !== 'barber' && availableBarbers.length > 0) && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                disableUnderline
                native
                value={ selectedBarber?.index }
                onChange={ e => setSelectedBarber(availableBarbers[e.target.value]) }
                inputProps={{
                  name: 'barber',
                  id: 'barber',
                }}
              >
                {
                  availableBarbers.map((a, i) => (
                    <option key={i} value={ i }>{ a.name }</option>
                  ))
                }
              </Select>
            </>
          )}

          { availableServices.length > 0 &&
            <Button
              onClick={ submitHandler }
              variant="contained"
            >
              Save
            </Button>
        }
        </div>
      </div>

      <Table className={ classes.table }>
        <TableBody>
          {
            availableServices.map((a, i) => (
              <TableRow
                key={i}
                className={ classes.tableRow }i
              >
                <TableCell className={ classes.cell }>
                  { a.title }
                </TableCell>
                <TableCell className={ classes.cell } style={{ color: '#939dad' }}>
                  { formatDuration(a.duration + a.extra_duration) }
                </TableCell>
                <TableCell className={ classes.cell }>
                  { formatCurrency(a.price) }
                </TableCell>
                <TableCell>
                  {
                    (() => {
                      const index = barberServices.findIndex(b => b.service_id === a.service_id);

                      return (
                        <Checkbox
                          checked={ index !== -1 }
                          style={{ color: 'black' }}
                          onChange={
                            _ => index !== -1 ?
                              setBarberServices(pr => [ ...pr.slice(0, index), ...pr.slice(index + 1) ]) :
                              setBarberServices(pr => [ ...pr, a ])
                          }
                        />
                      );
                    })()
                  }
                </TableCell>
              </TableRow>
            ))
          }

          { availableServices.length <= 0 && <h3>None</h3> }
        </TableBody>
      </Table>
    </div>
  )
};

export default Services;
