import React from 'react';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  if(value !== index) {
    return null;
  } else {
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { children }
      </div>
    );
  }
}

export default TabPanel;
