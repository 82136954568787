import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaloonSelection from './SaloonSelection';
import ChangeStockForm from './ChangeStockForm';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(() => ({
  drawer: {
    width: '100%',
    margin: '1rem 2rem',
    maxWidth: '35rem',
    '& ul > li': {
      margin: 0
    },
  }
}));

const ChangeStockDialog = ({
  saloonData,
  addOrRemoveStock,
  closeHandler,
  product,
  userType,
  updateHandler,
  toBaseCurrency
}) => {
  const classes = useStyles();
  const [selectedSaloon, setSelectedSaloon] = useState(null);

  const actionStr = (addOrRemoveStock === 'add') ? 'Increase' : 'Decrease';

  const handleClose = () => {
    closeHandler();
    setTimeout(_ => setSelectedSaloon(null), 100);
  };

  return (
    <Dialog
      classes={{ paper: classes.drawer }}
      open={ Boolean(addOrRemoveStock) }
      onClose={ handleClose }
    >
      <DialogTitle
        id="form-dialog-title"
      >
        { actionStr } Stock
      </DialogTitle>

      {
        // If a saloon has been selected show the form for change of stock else
        // show the saloon selection.
        Boolean(selectedSaloon) ? (
          <ChangeStockForm
            userType={ userType }
            actionStr={ actionStr }
            selectedSaloon={ selectedSaloon }
            saloonData={ saloonData }
            product={ product }
            handleClose={ handleClose }
            updateHandler={ updateHandler }
            toBaseCurrency={ toBaseCurrency }
          />
        ) : (
          <SaloonSelection
            saloonData={ saloonData }
            setSelectedSaloon={ setSelectedSaloon }
          />
        )
      }
    </Dialog>
  )
};

export default ChangeStockDialog;
