import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import authService from '../../../utils/authService';
import { effectFetch } from '../../../utils/helpers';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Errors } from '../../../App.js';

const statusColors = {
  confirmed: '#2883d2',
  rejected: '#f33155',
  pending: '#f5a623',
};

const useStyles = makeStyles(() => ({
  status: {
    color: 'white',
    borderRadius: '1rem',
    padding: '.25rem',
    width: '100%',
    textAlign: 'center'
  },
  table: {
    marginTop: '1rem',
  }
}));

const limit = 20;

const MyTable = ({
  userType,
  status,
  saloon,
  updateTrigger,
  setUpdateTrigger,
  onlySaloon
}) => {
  const { setErrors } = useContext(Errors);
  const classes = useStyles();
  const [holidays, setHolidays] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState();
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [ status, saloon, updateTrigger ]);

  // Fetch data
  useEffect(() => {
    const query = [
      (saloon.id !== null) ? `saloon=${saloon.id}` : '',
      (status !== 'All') ? `status=${status.toLowerCase()}` : '',
      `onlySaloon=${onlySaloon}`,
      `limit=${limit}`,
      `page=${page + 1}`
    ].join('&');

    return effectFetch(
      `/api/${userType}/holidays?${query}`,
      json => setHolidays(json.data || []),
      err => console.log(err)
    );
  }, [ userType, status, saloon, page, updateTrigger ]);

  // Reset page number
  useEffect(() => setPage(0), [ status, saloon, updateTrigger ]);

  const handleChangePage = (_, newPage) => {
    if(holidays.length === limit || newPage < page) {
      setPage(newPage);
    }
  };

  const handleClose = () => {
    setSelectedHoliday(null);
  }

  const handleAction = action => {
    authService.authFetch(`/api/${userType}/holidays/${action}ed`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ holiday: selectedHoliday.holiday_id })
    }).then(_ => {
      setUpdateTrigger(!updateTrigger);
    }).catch(err => setErrors([err]));

    handleClose();
  };

  return (
    <Card className={ classes.table }>
      <Dialog
        open={ Boolean(selectedHoliday) }
        onClose={handleClose}
      >
        <DialogTitle>Reject or Confirm Holiday</DialogTitle>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#f33155' }}
            onClick={ _ => handleAction('reject') }
          >
            Reject
          </Button>
          <Button
            style={{ backgroundColor: '#2883d2' }}
            onClick={ _ => handleAction('confirm') }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <CardContent>
        <TableContainer component="paper">
          <Table>
            <TableHead>
              <TableRow>
                { (userType !== 'barber' && !onlySaloon) &&
                  <TableCell />
                }
                {
                  [
                    // Only have barber column if not onlySaloon
                    ...(onlySaloon ? [] : ['Barber']),
                    'Date Range',
                    'Duration',
                    'Saloon',
                    'Description',
                    'Status',
                  ].map(a => (
                    <TableCell
                      key={a}
                    >
                      { a }
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                holidays?.map((a, i) => (
                  <TableRow key={i}>
                    { (userType !== 'barber' && !onlySaloon) &&
                      <TableCell>
                        <IconButton
                          disabled={ a.status !== 'pending' }
                          onClick={ _ => setSelectedHoliday(a) }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    }
                    { !onlySaloon &&
                      <TableCell>
                        { a.barber }
                      </TableCell>
                    }
                    <TableCell>
                      { moment(a.start_date).format('ddd, D MMMM YYYY') } - { moment(a.end_date).format('ddd, D MMMM YYYY') }
                    </TableCell>
                    <TableCell>
                      { moment(a.end_date).diff(moment(a.start_date), 'days') + 1 } Days
                    </TableCell>
                    <TableCell>
                      { a.saloon_name }
                    </TableCell>
                    <TableCell>
                      { a.description }
                    </TableCell>
                    <TableCell>
                      <div
                        className={ classes.status }
                        style={{ backgroundColor: statusColors[a.status] }}
                      >
                        { a.status.toUpperCase() }
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[ limit ]}
            component="div"
            count={
              (holidays.length === limit) ? (-1) : (limit * page + holidays.length)
            }
            rowsPerPage={limit}
            page={ page }
            onChangePage={ handleChangePage }
            nextIconButtonProps={{ disabled: holidays.length < limit }}
          />
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default MyTable;
