import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

const Autocomplete = ({ options, handleAddOption, value, onChange, ...props }) => {
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(value?.name);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if(input.trim() === '') {
      setSuggestions([]);
    } else {
      setSuggestions([
        ...options.filter(option =>
          option.name.toLowerCase().includes(input.toLowerCase().trim())
        ),
        { name: `Add "${input}"` }
      ]);
    }
  }, [ input, options ]);

  // When new option is added to all options, trigger onChange with the correct
  // option from the options array. This is done so we can also have the id of
  // the option.
  useEffect(_ => {
    if(value?.id === undefined) {
      onChange(options.filter(a => a.name === input)[0]);
    }
  }, [ options ]);

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion.name);
    onChange(suggestion);
    setOpen(false);
  };

  const handleAddSuggestion = async _ => {
    setOpen(false);
    const suggestionID = await handleAddOption(input);
    onChange({ name: input, id: suggestionID });
  }

  return (
    <div onBlur={ _ => setTimeout(_ => setOpen(false), 100) }>
      <TextField
        variant="outlined"
        ref={ inputEl }
        value={ input }
        onChange={ e => setInput(e.target.value) }
        onFocus={ _ => setOpen(true) }
        { ...props }
      />
      <Popper
        anchorEl={ inputEl.current }
        open={ open }
        style={{ zIndex: 100 }}
        placement="bottom-start"
        disablePortal
      >
        <Paper>
          <MenuList>
            {
              suggestions.map((suggestion, i) =>
                <MenuItem
                  key={ i }
                  value={ suggestion.name }
                  onClick={
                    // If suggestion is the last one in the list aka (Add "xxx")
                    (i === suggestions.length - 1) ?
                      _ => handleAddSuggestion() :
                      _ => handleSuggestionClick(suggestion)
                  }
                >
                  { suggestion.name }
                </MenuItem>
              )
            }
          </MenuList>
        </Paper>
      </Popper>
    </div>
  );
}

export default Autocomplete;
